import {OntologyTreeElementId, OntologyTreeResponse} from './interface';

export function getTreeItem(treeData: OntologyTreeResponse, id: OntologyTreeElementId) {
    return treeData.elements.find((el) => JSON.stringify(el.id) === JSON.stringify(id))!;
}

export function getSortTreeItemsFunc(treeData: OntologyTreeResponse, currentDoc: DocInfo) {
    return function sortTreeItems(id1: OntologyTreeElementId, id2: OntologyTreeElementId) {
        const isEl1InCurrentDoc = id1.documentAccession === currentDoc.accession;
        const isEl2InCurrentDoc = id2.documentAccession === currentDoc.accession;

        if (isEl1InCurrentDoc && !isEl2InCurrentDoc) {
            return -1;
        }

        if (isEl2InCurrentDoc && !isEl1InCurrentDoc) {
            return 1;
        }

        const element1 = getTreeItem(treeData, id1);
        const element2 = getTreeItem(treeData, id2);

        return element1.name.toLowerCase() > element2.name.toLowerCase() ? 1 : -1;
    };
}
