import {useMutation} from 'react-query';

import {UserIdentity, UserIdentityBasic} from '../../../../../../typings/user';
import {apiClient} from '../../../../../utils/api-client';

import {QueryPermission, useGetQueryPermissionsCache} from './use-get-query-permitted-users';
import {showErrorNotification} from '../../../../../providers/notifications-center/notifications-store';

interface MutationParams {
    queryId: string;
    user: UserIdentityBasic;
    requestMode: 'share' | 'unshare';
}

interface MutationContext {
    previousPermissions: QueryPermission[];
}

function shareQueryRequest({queryId, user, requestMode}: MutationParams) {
    return apiClient.post({
        path: `queries-service/api/queries/${queryId}/${
            requestMode === 'share' ? 'share' : 'unshare'
        }`,
        parameters: {
            userEmail: user.email
        }
    });
}

export function useShareQueryMutation(queryId: string, sharedBy: UserIdentity) {
    const getQueryPermittedUsersCache = useGetQueryPermissionsCache(queryId);

    return useMutation<unknown, Error, MutationParams, MutationContext>(shareQueryRequest, {
        onMutate: async (params: MutationParams) => {
            const previousPermissions = await getQueryPermittedUsersCache.onChangeUserPermission(
                params.user,
                sharedBy,
                params.requestMode
            );

            return {previousPermissions};
        },
        onError: (error, params, context) => {
            showErrorNotification()(error);
            if (context) {
                getQueryPermittedUsersCache.setPermissions(context.previousPermissions);
            }
        }
    });
}
