import {
    Button,
    Controls,
    ControlsItem,
    Dialog,
    DialogProps,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Divider,
    Input,
    Tooltip,
    TooltipHandler,
    Typography,
    WithSeparator,
    Notification
} from '@genestack/ui';
import * as React from 'react';

import {LocalBackdrop} from '../../components/local-backdrop';
import {MaxLengthInput} from '../../components/max-length-input';
import {showNotification} from '../../providers/notifications-center';
import {useAddUserMutation} from '../../hooks/manage-users/use-add-user-mutation';
import {useUserListQuery} from '../../hooks/manage-users/use-user-list-query';

interface Props extends DialogProps {
    onUpdateLastAddedUserEmail: (email: string | null) => void;
    onClose: () => void;
}

const NAME_MAX_LENGTH = 255;

const emailRegex =
    // tslint:disable-next-line max-line-length
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

const emailIsValid = (email: string) => emailRegex.test(email);

/** Form for adding a new user */
// tslint:disable cyclomatic-complexity
export const AddUserDialog = ({onUpdateLastAddedUserEmail, open, onClose, ...rest}: Props) => {
    const [nameValue, setNameValue] = React.useState('');
    const [emailValue, setEmailValue] = React.useState('');

    const [emailInputTouched, setEmailInputTouched] = React.useState(false);
    const [emailInputBlurred, setEmailInputBlurred] = React.useState(false);

    const addUserMutation = useAddUserMutation();
    const usersListQuery = useUserListQuery();

    const leftToFill = !(nameValue.trim() && emailValue);

    // Validation
    const userExists = Boolean(
        emailValue && usersListQuery.data?.some((user) => user.email === emailValue)
    );
    const emailValidity = emailIsValid(emailValue);
    const emailInputInvalid =
        userExists || (emailInputTouched && emailInputBlurred && !emailValidity);

    const handleEmailInputBlur = () => {
        setEmailInputBlurred(true);
    };

    const handleEmailValueChange = (value: string) => {
        setEmailInputTouched(true);
        setEmailValue(value);
    };

    const handleDialogClosed = () => {
        setNameValue('');
        setEmailValue('');
        setEmailInputTouched(false);
        setEmailInputBlurred(false);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const user = await addUserMutation.mutateAsync({
            email: emailValue,
            name: nameValue
        });

        if (user) {
            showNotification(
                <Notification>
                    <Typography>{nameValue} added</Typography>
                </Notification>
            );

            onUpdateLastAddedUserEmail(emailValue);
            onClose();
        }
    };

    return (
        <Dialog
            {...rest}
            open={open}
            size="small"
            onClose={onClose}
            onClosed={handleDialogClosed}
            overlayProps={{
                disableEscListener: true,
                disableClickListener: true
            }}
            as="form"
            onSubmit={handleSubmit}
        >
            <DialogHeader>
                <Typography variant="title">New user</Typography>
            </DialogHeader>
            <DialogBody>
                <Typography as="label" htmlFor="new-user-name">
                    Name
                </Typography>
                <MaxLengthInput
                    id="new-user-name"
                    onValueChange={setNameValue}
                    value={nameValue}
                    fullWidth
                    maxLength={NAME_MAX_LENGTH}
                    autoFocus
                    tooltipMessage="User name cannot be longer"
                />
                <Divider variant="transparent" gap={2} />
                <Typography as="label" htmlFor="new-user-email">
                    Email
                </Typography>
                <TooltipHandler
                    tooltip={
                        <Tooltip placement="right" open={emailInputInvalid}>
                            {userExists ? 'The user already exists' : 'Enter valid email address'}
                        </Tooltip>
                    }
                >
                    <Input
                        id="new-user-email"
                        onValueChange={handleEmailValueChange}
                        value={emailValue}
                        fullWidth
                        invalid={emailInputInvalid}
                        onBlur={handleEmailInputBlur}
                    />
                </TooltipHandler>
                <Divider variant="transparent" gap={2} />
            </DialogBody>
            <DialogFooter>
                <Controls>
                    <ControlsItem>
                        <TooltipHandler
                            tooltip={
                                leftToFill ? (
                                    <Tooltip placement="bottom">
                                        <WithSeparator separator={<br />}>
                                            Left to fill:
                                            {nameValue === '' ? '• Name' : null}
                                            {emailValue === '' ? '• Email' : null}
                                        </WithSeparator>
                                    </Tooltip>
                                ) : null
                            }
                        >
                            <Button
                                intent="accent"
                                type="submit"
                                disabled={emailInputInvalid || leftToFill || userExists}
                            >
                                Add
                            </Button>
                        </TooltipHandler>
                    </ControlsItem>
                    <ControlsItem>
                        <Button onClick={onClose}>Cancel</Button>
                    </ControlsItem>
                </Controls>
            </DialogFooter>
            <LocalBackdrop open={addUserMutation.isLoading} />
        </Dialog>
    );
};
