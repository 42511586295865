import React from 'react';

import {useGlobalStateVariable, Variables} from '../../../providers/global-state';

import {useFlexibleLayout} from './flexible-layout';
import {PermittedStorageKeys} from './flexible-layout/interface';

interface Props {
    sidebarSizeStorageKey: PermittedStorageKeys;
    isHasRightPanel?: boolean;
    sidebarExpandedStorageKey?: keyof Variables;
    sidebarClosedStorageKey?: keyof Variables;
}

const EXPANDED_PANEL_ADDITIONAL_WIDTH = 400;

export function usePageLayout({
    isHasRightPanel,
    sidebarSizeStorageKey,
    sidebarExpandedStorageKey,
    sidebarClosedStorageKey
}: Props) {
    const [isExpandedSidebar, setIsExpandedSidebar] = useGlobalStateVariable(
        sidebarExpandedStorageKey,
        false
    );
    const isShowExpandedSidebar = !!sidebarExpandedStorageKey && isExpandedSidebar;

    const [isSidebarClosed, setIsSidebarClosed] = useGlobalStateVariable(
        sidebarClosedStorageKey,
        false
    );
    const closeSidebar = () => setIsSidebarClosed(true);
    const openSidebar = () => setIsSidebarClosed(false);

    const flexibleLayoutProps = useFlexibleLayout<HTMLDivElement, HTMLDivElement>(
        sidebarSizeStorageKey
    );

    const toggleIsExpandedSidebar = () => {
        const shouldBeExpanded = !isExpandedSidebar;
        setIsExpandedSidebar(shouldBeExpanded);

        if (shouldBeExpanded) {
            flexibleLayoutProps.changeFirstPanelSizeInPx(EXPANDED_PANEL_ADDITIONAL_WIDTH);
        } else {
            flexibleLayoutProps.changeFirstPanelSizeInPx(-EXPANDED_PANEL_ADDITIONAL_WIDTH);
        }
    };

    React.useEffect(() => {
        if (isHasRightPanel && isExpandedSidebar) {
            setIsExpandedSidebar(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHasRightPanel]);

    return {
        toggleIsExpandedSidebar,
        closeSidebar,
        openSidebar,
        flexiblePanel: flexibleLayoutProps,
        isHasRightPanel,
        isExpandedSidebar: !!isShowExpandedSidebar,
        isSidebarClosed
    };
}

export type LayoutContextValue = ReturnType<typeof usePageLayout>;
