import React from 'react';
import {ScrollView, Spinner, Typography} from '@genestack/ui';
import classNames from 'classnames';
import {UseQueryResult} from 'react-query';
import {GraphFactPanelEntry} from './graph-fact-panel-entry';
import styles from '../path-result-panel/result-chains-panel.module.css';
import {CollapseButton} from '../collapse-button';
import {BusyIndicator} from '../../../busy-indicator';
import {MergedFactPanelEntry, SimpleFactPanelEntry} from '../../interface';
import {SidebarHeader} from '../../../layout/sidebar/sidebar-header/sidebar-header';

interface Props {
    selectedNodes: number[];
    closePanel: () => void;
    isPanelCollapsed: boolean;
    toggleIsPanelCollapsed: () => void;
    contentRequest: UseQueryResult<Array<SimpleFactPanelEntry | MergedFactPanelEntry>>;
    globalVersionId: number;
    maxPathLength?: number;
}

export function GraphResultPanelView(props: Props) {
    return (
        <React.Fragment>
            <SidebarHeader
                title={
                    <Typography variant="section" className={styles.headerLabel}>
                        {props.selectedNodes.length} node
                        {props.selectedNodes.length > 1 ? 's' : ''} selected
                        {props.isPanelCollapsed && props.contentRequest.isLoading && <Spinner />}
                    </Typography>
                }
                additionalControl={
                    <CollapseButton
                        isCollapsed={props.isPanelCollapsed}
                        toggleCollapsed={props.toggleIsPanelCollapsed}
                    />
                }
                onClose={props.closePanel}
            />

            {(function () {
                if (props.contentRequest.isError) {
                    return (
                        <div className={styles.root} style={{padding: 16}}>
                            Something went wrong...
                        </div>
                    );
                }

                if (
                    props.contentRequest.isLoading ||
                    !props.contentRequest.data ||
                    !props.maxPathLength
                ) {
                    if (props.isPanelCollapsed) {
                        return undefined;
                    }

                    return (
                        <div className={styles.root}>
                            <BusyIndicator />
                        </div>
                    );
                }

                return (
                    <ScrollView className={styles.root}>
                        <div
                            className={classNames({
                                [styles.collapsedFactset]: props.isPanelCollapsed
                            })}
                        >
                            {props.contentRequest.data.map((entry, index) => (
                                <GraphFactPanelEntry
                                    key={entry.graphNodeId}
                                    entry={entry}
                                    nodeIndex={index}
                                    globalVersionId={props.globalVersionId}
                                />
                            ))}
                        </div>
                    </ScrollView>
                );
            })()}
        </React.Fragment>
    );
}
