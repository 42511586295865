import {ScrollView} from '@genestack/ui';
import classNames from 'classnames';
import React, {PropsWithChildren} from 'react';

import styles from './sidebar.module.css';

interface Props {
    header?: React.ReactNode;
    component?: React.ElementType;
    footer?: React.ReactNode;
    className?: string;
    border?: 'left' | 'right';
    applyColor?: boolean;
}

export const Sidebar = ({
    className,
    header,
    children,
    footer,
    border,
    applyColor = true,
    component: Component = ScrollView,
    ...rest
}: PropsWithChildren<Props>) => (
    <div
        className={classNames(styles.root, className, {
            [styles.borderLeft]: border === 'left',
            [styles.borderRight]: border === 'right',
            [styles.sidebarColor]: applyColor
        })}
        {...rest}
    >
        {header}
        <Component className={styles.content}>{children}</Component>
        {footer}
    </div>
);
