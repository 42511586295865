import {useMutation} from 'react-query';
import {apiClient} from '../../../../../../utils/api-client';
import {GraphPresentation} from '../interface';
import {useGetPresentationsCache} from './use-get-presentations';
import {showErrorNotification} from '../../../../../../providers/notifications-center/notifications-store';

interface MutationParams {
    queryId: number;
    presentationId: number;
    name: string;
}

function renamePresentationRequest({queryId, presentationId, name}: MutationParams) {
    return apiClient.patch<GraphPresentation>({
        path: `queries-service/api/queries/${queryId}/graph-presentations/${presentationId}/rename`,
        parameters: name
    });
}

export function useRenamePresentation() {
    const getPresentationsCache = useGetPresentationsCache();

    return useMutation({
        mutationFn: renamePresentationRequest,
        onMutate: ({queryId, presentationId, name}: MutationParams) => {
            return getPresentationsCache.renamePresentation(queryId, presentationId, name);
        },
        onError: (err, params, context) => {
            showErrorNotification('Request error, try again')(err);
            getPresentationsCache.setPresentations(params.queryId, context!);
        }
    });
}
