import React from 'react';
import {OntologyTreeItem, OntologyTreeItemProps} from './ontology-tree-item';
import {getSortTreeItemsFunc} from './helpers';

export function OntologyTreeInitialItem(props: OntologyTreeItemProps) {
    const parentToChildrenMap = React.useMemo(() => {
        return props.ontologyTreeData.parentWithChildren.find(
            (mapEl) => JSON.stringify(mapEl.parentId) === JSON.stringify(props.id)
        )!;
    }, [props.ontologyTreeData, props.id]);

    const initialChildren = React.useMemo(() => {
        const childrenArr = parentToChildrenMap!.initialChildrenIds;
        childrenArr.sort(getSortTreeItemsFunc(props.ontologyTreeData, props.currentDocInfo));
        return childrenArr;
    }, [props.ontologyTreeData, parentToChildrenMap, props.currentDocInfo]);

    return (
        <OntologyTreeItem
            {...props}
            initialChildren={initialChildren}
            additionalChildrenNum={parentToChildrenMap.remaining}
            isAdditionalElement={false}
        />
    );
}
