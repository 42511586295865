import {Button, ListItem, Typography} from '@genestack/ui';
import * as React from 'react';

import {UserDeactivatedBadge} from '../../../../components/user-badge/user-deactivated-badge/user-deactivated-badge';

import styles from './selected-user.module.css';
import {UserInfo} from './user-info';

interface Props {
    fullName: string;
    email: string;
    isDeactivated: boolean;
    sharedBy: string;
    onRemoveClick?: (/* email: string */) => void;
}

/** Item in the list of users who already have access to the query */
export const SelectedUser = ({fullName, email, onRemoveClick, isDeactivated, sharedBy}: Props) => {
    return (
        <ListItem className={styles.selectedUser} interactive>
            <UserInfo fullName={fullName} email={email}>
                {isDeactivated && <UserDeactivatedBadge className={styles.isDeactivatedBadge} />}

                {onRemoveClick && (
                    <Button
                        intent="alarm"
                        size="small"
                        onClick={onRemoveClick}
                        className={styles.removeButton}
                    >
                        Remove
                    </Button>
                )}
            </UserInfo>

            <Typography variant="caption" intent="quiet">
                Shared by: {sharedBy}
            </Typography>
        </ListItem>
    );
};
