import {useMutation} from 'react-query';
import {GraphPresentationDetails} from '../interface';
import {apiClient} from '../../../../../../utils/api-client';
import {useGetPresentationsDetailsCache} from './use-get-presentation-details';
import {showErrorNotification} from '../../../../../../providers/notifications-center/notifications-store';

interface MutationParams {
    queryId: number;
    presentationId: number;
    details: GraphPresentationDetails;
}

function savePresentationDetailsRequest({queryId, presentationId, details}: MutationParams) {
    return apiClient.patch<GraphPresentationDetails>({
        path: `queries-service/api/queries/${queryId}/graph-presentations/${presentationId}`,
        parameters: {
            ...details
        }
    });
}

export function useSavePresentationDetails(
    clearLocalGraphSettings: () => void,
    setLocalGraphSettings: (settings: GraphPresentationDetails) => void
) {
    const presentationDetailsCache = useGetPresentationsDetailsCache();

    return useMutation<GraphPresentationDetails, Error, MutationParams, GraphPresentationDetails>({
        mutationFn: savePresentationDetailsRequest,
        onMutate: (params: MutationParams) => {
            const previousDetails = presentationDetailsCache.setDetails(
                params.queryId,
                params.presentationId,
                params.details
            );
            /* hack is used here bc for some reason updating pres details cache takes longer
            than updating local graph settings when done synchronously, which leads to faulty
            graph data being passed to graph rendering component (see JBKB-3455)
            */
            setTimeout(clearLocalGraphSettings);
            return previousDetails!;
        },
        onError: (error, params, context) => {
            showErrorNotification('Request error, try again later.')(error);
            presentationDetailsCache.setDetails(params.queryId, params.presentationId, context!);
            setLocalGraphSettings(params.details);
        }
    });
}
