import * as d3 from 'd3';
import * as React from 'react';

import {ScrollView} from '@genestack/ui/dist';
import {useCalculateEdgePaths} from './use-calculate-edge-paths';

import {GraphSvgDefs} from './graph-svg-defs';
import {renderEdges} from './render-edges';
import {renderNodes} from './render-nodes';
import {getGraphSVGSize} from './svg-coordinates-helpers';
import {BrowserNotSupportedView} from '../common-components/browser-not-supported-view';
import {GraphLoadingComponent} from '../common-components/graph-loading-component';
import styles from '../../../application/query-workflow/query-layout/query-layout.module.css';
import {GenestackGraphGrid} from './genestack-graph-grid';

interface Props {
    genestackGraphGrid: GenestackGraphGrid;
    displayNodeIds?: boolean;
    selectedNodes: number[];
    selectNodes: (nodeId: number[], isSelectMultiple: boolean) => void;
    hoveredNodes: number[];
    setHoveredNodes: (nodeIds: number[]) => void;
    hoveredEdges: number[];
    setHoveredEdges: (edgeIds: number[]) => void;
    onGraphProcessed?: (processingTime: number) => void;
    onGraphRendered?: (renderingTime: number) => void;
}

export const GenestackGraphView = (props: Props) => {
    const {
        genestackGraphGrid,
        displayNodeIds,
        selectedNodes,
        selectNodes,
        onGraphProcessed,
        onGraphRendered,
        hoveredNodes,
        hoveredEdges,
        setHoveredEdges,
        setHoveredNodes
    } = props;
    const {edgesGridPaths, isBrowserUnsupported} = useCalculateEdgePaths(
        genestackGraphGrid,
        onGraphProcessed
    );

    const {width, height, margin} = React.useMemo(() => {
        const {size} = genestackGraphGrid;

        return getGraphSVGSize(size);
    }, [genestackGraphGrid]);

    React.useEffect(() => {
        if (!edgesGridPaths) {
            return undefined;
        }

        const canvasElement = d3.select<SVGGElement, unknown>('#query-results-graph-canvas');
        const graphContainer = d3.select('#query-results-graph');

        const renderingStartTime = new Date().valueOf();

        renderNodes({
            canvasElement,
            genestackGraphGrid,
            graphContainer,
            selectedNodes,
            selectNodes,
            hoveredNodes,
            setHoveredNodes,
            displayNodeIds
        });

        renderEdges({
            canvasElement,
            genestackGraphGrid,
            graphContainer,
            selectedNodes,
            selectNodes,
            edgesGridPaths,
            hoveredEdges,
            setHoveredEdges
        });

        if (onGraphRendered) {
            onGraphRendered(new Date().valueOf() - renderingStartTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        genestackGraphGrid,
        hoveredNodes,
        hoveredEdges,
        setHoveredEdges,
        setHoveredNodes,
        selectedNodes,
        selectNodes,
        edgesGridPaths
    ]);

    if (isBrowserUnsupported) {
        return <BrowserNotSupportedView />;
    }

    if (!edgesGridPaths) {
        return <GraphLoadingComponent />;
    }

    return (
        <ScrollView className={styles.resultChains}>
            <GraphSvgDefs />
            <div id="query-results-graph">
                <svg width={width} height={height}>
                    <g
                        id="query-results-graph-canvas"
                        transform={`translate(${margin}, ${margin})`}
                    />
                </svg>
            </div>
        </ScrollView>
    );
};
