// tslint:disable: max-file-line-count
import {ScrollView} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {
    BioKBEditorEvent,
    NodeEditor,
    EditorEvents,
    createEditor,
    FindUsagesEditorEvent,
    BTBCategoryEvent,
    BTBCategoryEvents,
    BTBCategoryInfo,
    DocumentSource,
    ErrorEvent,
    FactSelectionEvent
} from '../../../webeditor/biokb-editor-adapters';
import {showNotification} from '../../providers/notifications-center';

import styles from './editor.module.css';
import {NodeNotFoundNotification} from './node-not-found-notification';
import {showErrorNotification} from '../../providers/notifications-center/notifications-store';

interface Props {
    className?: string;
    documentSource?: DocumentSource;
    nodeId?: string;
    onLoadingStateChange?: (isLoading: boolean) => void;
    onNavigate?: (accession: string | null, nodeId: string | null) => void;
    onFindUsages?: (event: FindUsagesEditorEvent) => void;
    onAddCategory?: (categoryInfo: BTBCategoryInfo) => void;
    onRemoveCategory?: (categoryInfo: BTBCategoryInfo) => void;
    onFactSelectionChanged?: (factIds: string[]) => void;
    selectedFacts?: string[];
}

interface State {
    isLoading: boolean;
}

function descriptorEquals(source?: DocumentSource, prevSource?: DocumentSource) {
    if (!source && !prevSource) {
        return true;
    }
    if (!source || !prevSource) {
        return false;
    }

    return source.descriptor.equals(prevSource.descriptor);
}

const isDebug = process.env.NODE_ENV === 'production';

/** A wrapper component for BioKB Editor */
export class BelEditorWrapper extends React.PureComponent<Props, State> {
    // eslint-disable-next-line react/state-in-constructor
    public state: State = {isLoading: true};

    private mounted = false;
    private rootRef = React.createRef<HTMLDivElement>();
    private editorInstance?: NodeEditor;

    private unsubscribeHandlers: Array<() => void> = [];

    public componentDidMount() {
        this.setup();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const {documentSource, nodeId, selectedFacts} = this.props;

        if (!this.editorInstance) {
            return;
        }

        if (!descriptorEquals(prevProps.documentSource, documentSource)) {
            this.setLoadingState(true);
            this.openDocument();
        } else if (!this.state.isLoading && (prevProps.nodeId !== nodeId || prevState.isLoading)) {
            this.setFocusOnNode(nodeId);
        }

        if (
            selectedFacts &&
            !selectedFacts.length &&
            prevProps.selectedFacts &&
            prevProps.selectedFacts.length
        ) {
            this.editorInstance.deselectAllFacts();
        }
    }

    public componentWillUnmount() {
        this.editorInstance?.dispose();
        this.destroy();
    }

    private async setup() {
        this.mounted = true;

        if (!this.rootRef.current) {
            return;
        }

        this.setLoadingState(true);
        this.editorInstance = createEditor(this.rootRef.current);
        const {onAddCategory, onRemoveCategory} = this.props;

        this.unsubscribeHandlers = [
            this.editorInstance.subscribe(EditorEvents.NAVIGATE, this.handleNavigate),
            this.editorInstance.subscribe(EditorEvents.FIND_USAGES, this.handleFindUsages),
            this.editorInstance.subscribe(EditorEvents.ERROR_EVENT, BelEditorWrapper.onError),
            ...(this.props.onFactSelectionChanged
                ? [
                      this.editorInstance.subscribe(
                          EditorEvents.FACT_SELECTION_EVENT,
                          this.handleFactSelected
                      )
                  ]
                : [])
        ];

        if (onAddCategory || onRemoveCategory) {
            this.unsubscribeHandlers.push(
                this.editorInstance.subscribe(
                    EditorEvents.BTB_CATEGORY_EVENT,
                    this.handleCategoryChange
                )
            );
        }

        return this.openDocument();
    }

    public static onError = (err: ErrorEvent) => {
        showErrorNotification(err.message)(new Error(err.description));
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    public onPublished = (accession: string) => {
        this.editorInstance?.published(accession);
    };

    public render() {
        const {documentSource, className} = this.props;
        const isHidden = !documentSource;

        return (
            <ScrollView
                className={classNames(styles.editor, {[styles.editorHidden]: isHidden}, className)}
            >
                {/* giving webeditor its own root node so it wouldn't override our styles */}
                <div ref={this.rootRef} />
            </ScrollView>
        );
    }

    private handleFactSelected = (event: FactSelectionEvent) => {
        this.props.onFactSelectionChanged!(event.factIds);
    };

    private handleNavigate = (event: BioKBEditorEvent) => {
        this.props.onNavigate?.(event.accession, event.nodeId);
    };

    private handleFindUsages = (event: FindUsagesEditorEvent) => {
        this.props.onFindUsages?.(event);
    };

    private handleCategoryChange = (event: BTBCategoryEvent) => {
        switch (event.kind) {
            case BTBCategoryEvents.ADD:
                return this.props.onAddCategory?.(event.categoryInfo);
            case BTBCategoryEvents.REMOVE:
                return this.props.onRemoveCategory?.(event.categoryInfo);
            default:
        }
    };

    private setFocusOnNode(node?: string) {
        if (!this.editorInstance || !node || this.state.isLoading) {
            return;
        }

        try {
            this.editorInstance.select(node);
        } catch (err) {
            showNotification(<NodeNotFoundNotification />);
        }

        // waiting for the next tick because `select` isn't synchronous
        // (webeditor performs changes in DOM)
        window.setTimeout(() => {
            /** Enable keyboard control in the editor, but only when node is specified */
            const target = this.rootRef.current && this.rootRef.current.querySelector('textarea');

            target?.scrollIntoView({block: 'center'});
        });
    }

    private setLoadingState(isLoading: boolean) {
        if (!this.mounted) {
            return;
        }

        this.setState({isLoading}, () => {
            if (isDebug) {
                // eslint-disable-next-line no-console
                console.info(`Editor loading state changed to ${this.state.isLoading}`);
            }
            this.props.onLoadingStateChange?.(this.state.isLoading);
        });
    }

    private destroy() {
        for (const unsubscribeHandler of this.unsubscribeHandlers) {
            unsubscribeHandler();
        }

        if (this.rootRef.current) {
            this.rootRef.current.innerHTML = '';
        }

        this.mounted = false;
    }

    private async openDocument() {
        const {documentSource} = this.props;

        if (documentSource) {
            await this.editorInstance?.open(documentSource);
            this.setLoadingState(false);
        }
    }
}
