import React from 'react';

// this is a hack I added because the API for working with selected nodes in cytoscape isn't ideal
// I haven't found a way to get all selected nodes, and boxselect events fire one by one, and every
// event object includes info only on one of the selected nodes. This hook is used to get all the
// nodes selected in a box
export function useHandleBoxSelection(
    setSelectedNodes: (nodeIds: number[], isMultipleSelection: boolean) => void
) {
    const [counter, setCounter] = React.useState(0);

    const nodesToSelectRef = React.useRef<number[]>([]);

    React.useEffect(() => {
        if (nodesToSelectRef.current.length) {
            setSelectedNodes(nodesToSelectRef.current, true);
            nodesToSelectRef.current = [];
        }
    }, [counter, setSelectedNodes]);

    return React.useCallback((nodeId: number) => {
        nodesToSelectRef.current = [...nodesToSelectRef.current, nodeId];
        setCounter((count) => count + 1);
    }, []);
}
