import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {isQueryRunningOrPending} from '../../utils';

import {useQueryListCache} from './update-query-list-cache';

const PAGE_LIMIT = 100;

// BE supports cursor pagination for recent queries, but currently we don't use it on the client
export interface QueryCursorByFinished {
    finishedAt: number | null;
    id: number;
}

function getRecentQueriesRequest() {
    return apiClient
        .post<PageResponse<RecentQuery, QueryCursorByFinished>>({
            path: 'queries-service/api/queries/recent',
            parameters: {limit: PAGE_LIMIT}
        })
        .then(({data}) => data);
}

export function useRecentQueries() {
    return useQuery('getRecentQueriesRequest', getRecentQueriesRequest);
}

export const useRunningQueries = () => {
    const recentQueries = useRecentQueries();

    return {
        isLoading: recentQueries.isLoading,
        data: recentQueries.data?.filter(isQueryRunningOrPending) ?? []
    };
};

export function useRecentQueriesCache() {
    return useQueryListCache<RecentQuery>('getRecentQueriesRequest');
}
