import {ScrollView} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {PaginationAsyncValue} from '../../../../../../components/pagination/pagination-async-value';
import {useLocationParams} from '../../../../../../hooks/use-location';
import {useConsumeDocInfo} from '../../../../../../providers/doc-info-store';
import {DocumentLink} from '../../document-link';
import {CategoryTreeElement} from '../categories/adapt-tree';
import {AppliedFiltersSummary} from '../categories/applied-filters-summary';
import {Categories} from '../categories/categories';
import {SearchResultsBody} from '../search-results-body';

import styles from './document-results-layout.module.css';
import {SidebarLoadingState} from '../../../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {SEARCH_DOCS_PAGE_SIZE} from '../search-docs-infinite-query';

interface Props {
    paginationState: PaginationAsyncValue<ViewModeDocInfo>;
    // eslint-disable-next-line react/no-unused-prop-types
    onLoadNextPage: () => void;
    expanded: boolean;
    onChangeFilters: (
        toAddVisual: CategoryTreeElement[],
        toRemoveVisual: CategoryTreeElement[],
        toAddApplied: string[],
        toRemoveApplied: string[]
    ) => void;
    visualFilters: CategoryTreeElement[];
    appliedFilters: string[];
    versionId: number;
    searchText?: string;
    categories?: string[];
    onResetFilters: () => void;
    loadingState: SidebarLoadingState;
    setLoadingState: (state: SidebarLoadingState) => void;
    getDocumentLink: (documentId: string) => string;
}

export function DocumentResultsLayout(props: Props) {
    const {documentId: selectedDocumentId} = useLocationParams();

    useConsumeDocInfo(props.paginationState.items ?? []);

    const totalCount = props.paginationState.total ?? 0;

    const renderDocumentLink = React.useCallback(
        (docInfo: ViewModeDocInfo) => {
            const element = (
                <DocumentLink
                    documentId={docInfo.accession}
                    active={docInfo.accession === selectedDocumentId}
                    getDocumentLink={props.getDocumentLink}
                    docInfo={docInfo}
                />
            );

            return [docInfo.accession, element] as [React.Key, React.ReactElement];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedDocumentId]
    );

    return (
        <div className={styles.root}>
            {props.expanded ? (
                <ScrollView className={styles.left}>
                    <Categories
                        versionId={props.versionId}
                        categories={props.categories}
                        stringSearch={props.searchText}
                        visualFilters={props.visualFilters}
                        appliedFilters={props.appliedFilters}
                        onChangeFilters={props.onChangeFilters}
                    />
                </ScrollView>
            ) : null}

            <ScrollView className={classNames(styles.right, {[styles.expanded]: props.expanded})}>
                {props.visualFilters.length ? (
                    <AppliedFiltersSummary
                        visualFilters={props.visualFilters}
                        onChangeFilters={props.onChangeFilters}
                        isLoading={props.loadingState.isLoading}
                        count={totalCount}
                        onResetFilters={props.onResetFilters}
                    />
                ) : null}

                <SearchResultsBody
                    pageSize={SEARCH_DOCS_PAGE_SIZE}
                    paginationState={props.paginationState}
                    renderItem={renderDocumentLink}
                    onLoadNextPage={props.paginationState.loadNextPage}
                    loadingState={props.loadingState}
                    setLoadingState={props.setLoadingState}
                />
            </ScrollView>
        </div>
    );
}
