import React from 'react';
import {ScrollView} from '@genestack/ui';
import {ContinuantsGroupFoldingItem} from './continuants-group-folding-item';
import {RightPanelListItem} from '../right-panel-list-item';
import {useFoldingItemsState} from '../../../../../../hooks/use-folding-items-state';
import {GroupedContinuantsMap} from '../interface';
import {EmptyList} from '../../../../../../components/empty-list/empty-list';

interface Props {
    selectedGraphNodes: number[];
    setHoveredNodes: (nodeIds: number[]) => void;
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
    foldingStateProps: ReturnType<typeof useFoldingItemsState>;
    groupedContinuantsMap: GroupedContinuantsMap;
}

export function ContinuantsStatistics(props: Props) {
    if (!Object.keys(props.groupedContinuantsMap).length) {
        return <EmptyList message="Nothing found" />;
    }

    return (
        <ScrollView>
            {Object.keys(props.groupedContinuantsMap).map((continuantType) => (
                <ContinuantsGroupFoldingItem
                    key={continuantType}
                    label={continuantType}
                    continuants={props.groupedContinuantsMap[continuantType]}
                    foldingStateProps={props.foldingStateProps}
                    foldingStateKey={continuantType}
                    padding={1}
                >
                    {props.groupedContinuantsMap[continuantType].map((continuantStatRecord) => (
                        <RightPanelListItem
                            label={continuantStatRecord.label}
                            count={continuantStatRecord.occurrencesCount}
                            key={`${continuantStatRecord.documentAccession}${continuantStatRecord.elementNodeId}`}
                            active={
                                // if graph is empty graphViewNodeIds length is 0
                                !!props.selectedGraphNodes.length &&
                                continuantStatRecord.graphViewNodeIds.every((id) =>
                                    props.selectedGraphNodes.includes(id)
                                )
                            }
                            setHoveredNodes={props.setHoveredNodes}
                            selectGraphNodes={props.selectGraphNodes}
                            graphViewNodeIds={continuantStatRecord.graphViewNodeIds}
                            padding={2}
                        />
                    ))}
                </ContinuantsGroupFoldingItem>
            ))}
        </ScrollView>
    );
}
