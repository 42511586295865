import {Divider, Menu, MenuCaption, MenuItem, MenuProps} from '@genestack/ui';
import React from 'react';
import {Link} from 'react-router-dom';

import {DocumentTypeLabel} from '../../../../../components/document-type-label';
import {useCopyLinkToThisPage} from '../../../../../hooks/copy-link/use-copy-link-to-this-page';
import {useUserEditingPermissions} from '../../../../../hooks/user/use-has-permission';
import {useToggleFavorite} from '../../../../../providers/doc-info-store';
import {isReadOnlyOntology} from '../../../../../utils/ontology';

interface Props extends MenuProps {
    documentId: string;
    docInfo?: ViewModeDocInfo;
    menuCaption?: React.ReactNode;
    menuItems?: React.ReactNode;
}

/** Document menu for view mode */
export const DocumentMenu = ({
    documentId,
    docInfo,
    menuCaption,
    menuItems,
    ...restProps
}: Props) => {
    const toggleFavorite = useToggleFavorite(documentId);
    const copyLink = useCopyLinkToThisPage();
    const {editingPermitted} = useUserEditingPermissions();

    const editModeProps = {
        as: Link,
        to: `/documents/edit/${documentId}`
    };

    return (
        <Menu {...restProps}>
            {docInfo && menuCaption ? (
                <MenuCaption>
                    <DocumentTypeLabel type={docInfo.type} variant="full" />
                    {menuCaption}
                </MenuCaption>
            ) : null}

            {menuItems}

            {docInfo ? (
                <MenuItem onClick={toggleFavorite}>
                    {docInfo.userFavorite ? 'Remove from' : 'Add to'} Bookmarks
                </MenuItem>
            ) : null}

            {editingPermitted && (
                <MenuItem {...editModeProps} disabled={isReadOnlyOntology(docInfo)}>
                    Open in edit mode
                </MenuItem>
            )}
            <Divider key="divider" />

            <MenuItem onClick={copyLink}>Copy link</MenuItem>
        </Menu>
    );
};
