import {useInfiniteQuery, useQueryClient, InfiniteData} from 'react-query';
import {QueryFilters} from 'react-query/types/core/utils';
import {UseInfiniteQueryOptions} from 'react-query/types/react/types';

import {StringSearch} from '../../explore-view/hooks/requests/use-categories-query';
import {apiClient} from '../../../../utils/api-client';
import {CategoriesSearch, getCategoriesSearch} from '../../../../utils/get-categories-search';
import {
    addDocumentToCache,
    updateDocumentInCache,
    removeDocumentFromCache
} from '../../../../components/pagination/infinite-query-items';
import {DocumentStatus} from '../api';

import {getPageSize} from './categories/use-categories-infinite-query';

const PAGE_SIZE = 200;

interface SearchDocumentsRequest {
    types?: DocType[];
    statuses?: DocumentStatus[];
    stringSearch?: StringSearch;
    categoriesSearch?: CategoriesSearch;
    onlyUserDocuments?: boolean;
    limit: number;
    cursor: DocumentCursor | null;
}

/** Requisite for useEditModeDocsInfiniteQuery hook */
export interface Props {
    queryKey: string;
    searchText?: string;
    statuses?: DocumentStatus[];
    categories?: string[];
    docType?: DocType;
    pageSize?: 'variable' | number;
    onlyUserDocuments?: boolean;
    options?: Pick<UseInfiniteQueryOptions<PageResponse<DocInfo>>, 'enabled'>;
}

function searchDocumentRequest(parameters: SearchDocumentsRequest) {
    return apiClient.post<PageResponse<DocInfo>>({
        path: 'editor-documents-service/api/documents/search',
        parameters
    });
}

/** useInfiniteQuery for searching documents in Edit mode */
export function useEditModeDocsInfiniteQuery({options = {}, pageSize = PAGE_SIZE, ...rest}: Props) {
    return useInfiniteQuery(
        [
            'searchEditMode',
            rest.queryKey,
            rest.docType,
            rest.categories,
            rest.searchText,
            rest.statuses,
            rest.onlyUserDocuments,
            pageSize,
            useEditModeDocsInfiniteQuery.name
        ],
        async ({
            pageParam = {
                cursor: null,
                limit: pageSize === 'variable' ? getPageSize() : pageSize
            }
        }) => {
            return searchDocumentRequest({
                stringSearch: rest.searchText ? {strict: false, value: rest.searchText} : undefined,
                statuses: rest.statuses,
                categoriesSearch: getCategoriesSearch(rest.categories),
                onlyUserDocuments: rest.onlyUserDocuments,
                types: rest.docType ? [rest.docType] : undefined,
                cursor: pageParam.cursor,
                limit: pageParam.limit
            });
        },

        {
            ...options,
            getNextPageParam: ({nextCursor, remaining, total}) => {
                const hasNextPage = remaining > 0;

                if (hasNextPage) {
                    return {
                        cursor: nextCursor,
                        limit: pageSize === 'variable' ? getPageSize(total - remaining) : pageSize
                    };
                }
            },
            keepPreviousData: true
        }
    );
}

/** Hook to update search cache for documents in Edit Mode */
export function useEditModeDocsInfiniteQueryCache(baseQueryKey: string, addDocsToTheEnd?: boolean) {
    const queryClient = useQueryClient();
    const queryFilters: QueryFilters = {
        queryKey: ['searchEditMode', baseQueryKey],
        exact: false
    };

    function invalidate() {
        return queryClient.invalidateQueries({...queryFilters, refetchInactive: true});
    }

    function addDocument(document: DocInfo) {
        queryClient.setQueriesData<InfiniteData<PageResponse<DocInfo>> | undefined>(
            queryFilters,
            (currentState) =>
                currentState && addDocumentToCache(currentState, document, addDocsToTheEnd)
        );
    }

    function updateDocument(updatedData: Partial<DocInfo> & {accession: string}) {
        queryClient.setQueriesData<InfiniteData<PageResponse<DocInfo>> | undefined>(
            queryFilters,
            (currentState) => currentState && updateDocumentInCache(currentState, updatedData)
        );
    }

    function deleteDocument(accession: string) {
        queryClient.setQueriesData<InfiniteData<PageResponse<DocInfo>> | undefined>(
            queryFilters,
            (currentState) => currentState && removeDocumentFromCache(currentState, accession)
        );
    }

    function clearCache() {
        queryClient.removeQueries(queryFilters);
    }

    return {invalidate, addDocument, updateDocument, deleteDocument, clearCache};
}
