import {
    Button,
    Controls,
    ControlsItem,
    KeyboardArrowBottomIcon,
    Link,
    Menu,
    MenuHandler,
    MenuItem,
    Switch,
    Typography
} from '@genestack/ui';
import React from 'react';

import {
    GraphViewType,
    ResultMaxLengthOption,
    GraphType
} from '../../../../../components/graph/interface';
import styles from './query-results-header.module.css';

import {MaxPathLengthMenuItem, unlimitedLengthOptionLabel} from './max-path-length-menu-item';
import {QueryGraphLayoutAlgorithm} from '../../../../../components/graph/cytoscape-graph/interface';
import {GraphLegend} from '../../../../../components/graph/graph-legend/graph-legend';
import {MaxPathLengthOptionResult} from '../results-graph/requests/use-query-result-max-length-options';
import {PresentationMenu} from './presentation-menu';
import {GraphPresentationPropsLoaded} from '../results-graph/use-graph-presentation-props';
import {useGraphSettings} from '../results-graph/use-graph-settings';
import {usePresentationEditedProps} from '../results-graph/use-presentation-edited-props';
import {useGraphPresentationActions} from '../results-graph/use-graph-presentation-actions';
import {useIsCantDisplayGraph} from '../results-graph/use-is-cant-display-graph';

interface Props {
    queryId: number;
    maxLengthOptions: MaxPathLengthOptionResult;
    layoutAlgorithm: QueryGraphLayoutAlgorithm;
    selectLayoutAlgorithm: (layout: QueryGraphLayoutAlgorithm) => void;
    canSelectViewTypes: boolean;
    isCustomLayout: boolean;
    graphSettingsProps: ReturnType<typeof useGraphSettings>;
    graphPresentationProps: GraphPresentationPropsLoaded;
    presentationEditedProps: ReturnType<typeof usePresentationEditedProps>;
    presentationActionsProps: ReturnType<typeof useGraphPresentationActions>;
    clearUnsavedPresentationDetails: () => void;
    isCalculatingLayout: boolean;
    isCantDisplayGraphProps: ReturnType<typeof useIsCantDisplayGraph>;
}

export const QueryResultsHeader = (props: Props) => {
    const {
        selectedViewType,
        setGraphType,
        toggleSelectedViewType,
        graphType,
        maxPathLength,
        setMaxPathLength
    } = props.graphSettingsProps;
    const limitsMenu = (limitInfos: ResultMaxLengthOption[]) => (
        <Menu onValueSelect={setMaxPathLength}>
            {limitInfos.map((option, index) => {
                return (
                    <MaxPathLengthMenuItem
                        option={option}
                        isLastOption={index === limitInfos.length - 1}
                        isSelected={option.pathLength === maxPathLength}
                        key={option.pathLength}
                        graphType={graphType}
                    />
                );
            })}
        </Menu>
    );

    const layoutMenu = () => (
        <Menu onValueSelect={props.selectLayoutAlgorithm}>
            {props.isCustomLayout && (
                <MenuItem key="custom" value="custom" active>
                    {props.layoutAlgorithm} (custom)
                </MenuItem>
            )}

            {Object.values(QueryGraphLayoutAlgorithm).map((layoutOption) => (
                <MenuItem key={layoutOption} value={layoutOption}>
                    {layoutOption}
                </MenuItem>
            ))}
        </Menu>
    );

    const graphTypeMenu = () => (
        <Menu onValueSelect={setGraphType}>
            {Object.values(GraphType).map((viewOption) => (
                <MenuItem key={viewOption} value={viewOption}>
                    {viewOption}
                </MenuItem>
            ))}
        </Menu>
    );

    const maxPathLengthOptions = props.maxLengthOptions[selectedViewType];
    const isFullGraphView =
        maxPathLengthOptions?.length &&
        maxPathLengthOptions[maxPathLengthOptions.length - 1].pathLength === maxPathLength;

    const {isResultIncomplete, isCantDisplayGraph} = props.isCantDisplayGraphProps;

    const maxPathLengthBtnLabel = (function () {
        if (isFullGraphView) {
            return unlimitedLengthOptionLabel;
        }

        return [
            'Max. path length',
            isCantDisplayGraph ? undefined : `- ${maxPathLength} edges`
        ].join('');
    })();

    return (
        <div className={styles.root}>
            <Controls flexWrap="wrap">
                <ControlsItem grow>
                    {!isCantDisplayGraph && (
                        <PresentationMenu
                            presentationProps={props.graphPresentationProps}
                            presentationEditedProps={props.presentationEditedProps}
                            clearUnsavedPresentationDetails={props.clearUnsavedPresentationDetails}
                            queryId={props.queryId}
                            presentationActionsProps={props.presentationActionsProps}
                            isCalculatingLayout={props.isCalculatingLayout}
                        />
                    )}
                </ControlsItem>

                {props.canSelectViewTypes && (
                    <ControlsItem>
                        <Typography as="label">
                            <Controls>
                                <ControlsItem>
                                    <Switch
                                        checked={selectedViewType === GraphViewType.MERGED}
                                        onCheckedChange={toggleSelectedViewType}
                                        disabled={isResultIncomplete}
                                    />
                                </ControlsItem>
                                <ControlsItem as={Link}>Collapse matched</ControlsItem>
                            </Controls>
                        </Typography>
                    </ControlsItem>
                )}

                <ControlsItem>
                    <MenuHandler menu={graphTypeMenu()}>
                        <Button
                            size="small"
                            iconEnd={<KeyboardArrowBottomIcon />}
                            disabled={isResultIncomplete}
                        >
                            {graphType}
                        </Button>
                    </MenuHandler>
                </ControlsItem>

                {graphType !== GraphType.biokb && (
                    <ControlsItem>
                        <MenuHandler menu={layoutMenu()}>
                            <Button
                                size="small"
                                iconEnd={<KeyboardArrowBottomIcon />}
                                disabled={isCantDisplayGraph}
                            >
                                {props.layoutAlgorithm}
                                {props.isCustomLayout && '(custom)'}
                            </Button>
                        </MenuHandler>
                    </ControlsItem>
                )}

                {maxPathLengthOptions && (
                    <ControlsItem>
                        <MenuHandler menu={limitsMenu(maxPathLengthOptions)}>
                            <Button
                                size="small"
                                iconEnd={<KeyboardArrowBottomIcon />}
                                disabled={isResultIncomplete}
                            >
                                {maxPathLengthBtnLabel}
                            </Button>
                        </MenuHandler>
                    </ControlsItem>
                )}

                <GraphLegend disabled={isCantDisplayGraph} />
            </Controls>
        </div>
    );
};
