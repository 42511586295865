import {GridCoordinates} from '../interface';
import {GridMap} from './interface';
import {GenestackGraphGrid} from './genestack-graph-grid';

const getCoordinatesComparator =
    (coordinates: GridCoordinates) => (coordinates2: GridCoordinates) =>
        coordinates.x === coordinates2.x && coordinates.y === coordinates2.y;

export function hasGapInPoint(
    edgeId: number,
    gridMap: GridMap,
    edgesGridPaths: {[key: string]: GridCoordinates[]},
    pointIndexInEdge: number
) {
    const cellCoordinates = edgesGridPaths[edgeId][pointIndexInEdge];
    const cellContent = gridMap[cellCoordinates.x][cellCoordinates.y];
    const edgesInCell = GenestackGraphGrid.getAllEdgesFromCell(
        gridMap[cellCoordinates.x][cellCoordinates.y]
    );
    if (GenestackGraphGrid.isCellHasNode(cellContent) || edgesInCell.length < 2) {
        return false;
    }

    // one line in intersection must not be solid,
    // it is the first line in the list (the shortest one)
    const currentEdgeIsFirstInCell = edgesInCell.findIndex((edge) => edge.id === edgeId) === 0;

    if (currentEdgeIsFirstInCell) {
        return false;
    }

    for (let i = 0; i < edgesInCell.length - 1; i++) {
        const edge2 = edgesInCell[i];
        if (edge2.id === edgeId) {
            // eslint-disable-next-line no-continue
            continue;
        }

        const isLastingIntersection = (function () {
            const pointIndexInEdge2 = edgesGridPaths[edge2.id].findIndex(
                getCoordinatesComparator(cellCoordinates)
            );

            const edge1NextPoint = edgesGridPaths[edgeId][pointIndexInEdge + 1];
            const edge1PrevPoint = edgesGridPaths[edgeId][pointIndexInEdge - 1];
            const edge2NextPoint = edgesGridPaths[edge2.id][pointIndexInEdge2 + 1];
            const edge2PrevPoint = edgesGridPaths[edge2.id][pointIndexInEdge2 - 1];
            return (
                getCoordinatesComparator(edge1NextPoint)(edge2NextPoint) ||
                getCoordinatesComparator(edge1PrevPoint)(edge2PrevPoint) ||
                getCoordinatesComparator(edge1NextPoint)(edge2PrevPoint) ||
                getCoordinatesComparator(edge1PrevPoint)(edge2NextPoint)
            );
        })();

        if (!isLastingIntersection) {
            return true;
        }
    }

    return false;
}

/*
   x1 x2
y1 e1 e2
y2 e2 e1   (where e1 and e2 are edges 1 and 2)
*/

/** Not every intersection of edges on Grid Map
 * happens in the centers of the cell (example above) */
/** This function handles such situations and determines
 * if there is an intersection between two points */
export function hasGapInSegment(
    edgeId: number,
    cellCoordinates1: GridCoordinates,
    cellCoordinates2: GridCoordinates,
    gridMap: GridMap,
    edgesGridPaths: {[key: string]: GridCoordinates[]}
) {
    const isDiagonalSegment =
        cellCoordinates1.x - cellCoordinates2.x !== 0 &&
        cellCoordinates1.y - cellCoordinates2.y !== 0;

    if (!isDiagonalSegment) {
        return false;
    }

    const [neighborPoint1, neighborPoint2] = [
        {x: cellCoordinates1.x, y: cellCoordinates2.y},
        {x: cellCoordinates2.x, y: cellCoordinates1.y}
    ];

    function getCommonEdgesFromTwoCells(cell1: GridCoordinates, cell2: GridCoordinates) {
        const neighborPoint1Edges = GenestackGraphGrid.getAllEdgesFromCell(
            gridMap[cell1.x][cell1.y]
        );
        const neighborPoint2Edges = GenestackGraphGrid.getAllEdgesFromCell(
            gridMap[cell2.x][cell2.y]
        );

        return neighborPoint1Edges.filter((edge1) =>
            neighborPoint2Edges.find((edge2) => edge1.id === edge2.id)
        );
    }

    const commonEdgesInNeighborCells = getCommonEdgesFromTwoCells(neighborPoint1, neighborPoint2);
    const commonEdgesInOriginalCells = getCommonEdgesFromTwoCells(
        cellCoordinates1,
        cellCoordinates2
    );

    if (commonEdgesInNeighborCells.length === 1 && commonEdgesInOriginalCells.length === 1) {
        const thisEdgeLength = edgesGridPaths[commonEdgesInOriginalCells[0].id].length;
        const intersectingEdgeLength = edgesGridPaths[commonEdgesInNeighborCells[0].id].length;

        if (thisEdgeLength !== intersectingEdgeLength) {
            return (
                edgesGridPaths[commonEdgesInOriginalCells[0].id].length >
                edgesGridPaths[commonEdgesInNeighborCells[0].id].length
            );
        }
    }

    if (commonEdgesInNeighborCells.length !== commonEdgesInOriginalCells.length) {
        return commonEdgesInOriginalCells.length < commonEdgesInNeighborCells.length;
    }

    return commonEdgesInNeighborCells[0].id > commonEdgesInOriginalCells[0].id;
}
