import classNames from 'classnames';
import React, {CSSProperties} from 'react';
import {LocalErrorBoundary} from '../../error-boundary';
import {Separator, BaseResizablePanel, ResizableLayoutOverlay} from '../resizable-layout';
import {ZeroState} from '../../zero-state';

import styles from './page-layout.module.css';
import {usePageLayout} from './use-page-layout';

interface Props {
    rightPanel?: React.ReactNode;

    activityBar: React.ReactNode;
    sidebar: React.ReactNode;

    layoutSettings: ReturnType<typeof usePageLayout>;

    children: React.ReactNode;
}

export function PageLayout({activityBar, sidebar, rightPanel, layoutSettings, children}: Props) {
    const {flexiblePanel, isHasRightPanel, isSidebarClosed} = layoutSettings;

    const leftPanelVariables = {
        '--panel-width': `${flexiblePanel.firstPanelShare * 100}%`,
        '--panel-min-width': `${flexiblePanel.minPermittedWidth}px`,
        '--panel-max-width': `calc(100% - ${flexiblePanel.minPermittedWidth}px)`
    } as CSSProperties;

    const rightPanelVariables = {
        '--panel-width': `${(1 - flexiblePanel.firstPanelShare) * 100}%`,
        '--panel-min-width': `${flexiblePanel.minPermittedWidth}px`,
        '--panel-max-width': `min(${(1 - flexiblePanel.firstPanelShare) * 100}%, calc(100% - ${
            flexiblePanel.minPermittedWidth
        }px))`
    } as CSSProperties;

    return (
        <div className={styles.root}>
            <ZeroState />

            <ResizableLayoutOverlay alignment="horizontal" isDragging={flexiblePanel.isDragging} />

            <div
                className={classNames(styles.activitybar, {
                    [styles.asidePanelVisibility]: isHasRightPanel
                })}
            >
                {activityBar}
            </div>

            <div
                ref={flexiblePanel.wrapperRef}
                className={classNames(styles.layout, {
                    [styles.asidePanelVisibility]: isHasRightPanel
                })}
            >
                <BaseResizablePanel
                    className={classNames(styles.sidebar, {
                        [styles.collapsedPanel]: isHasRightPanel || isSidebarClosed,
                        [styles.defaultPanel]: !isSidebarClosed && !isHasRightPanel
                    })}
                    isDragging={flexiblePanel.isDragging}
                    style={leftPanelVariables}
                >
                    {!isSidebarClosed && (
                        <LocalErrorBoundary variant="text">{sidebar}</LocalErrorBoundary>
                    )}

                    {!isSidebarClosed && (
                        <Separator
                            ref={flexiblePanel.separatorRef}
                            isDragging={flexiblePanel.isDragging}
                            variant="right"
                        />
                    )}
                </BaseResizablePanel>

                <BaseResizablePanel
                    component="div"
                    isDragging={flexiblePanel.isDragging}
                    className={classNames(styles.main, {
                        [styles.fullWidthPanel]: isSidebarClosed,
                        [styles.withRightPanel]: isHasRightPanel,
                        [styles.defaultPanel]: !isSidebarClosed && !isHasRightPanel
                    })}
                    style={rightPanelVariables}
                >
                    <LocalErrorBoundary>{children}</LocalErrorBoundary>
                </BaseResizablePanel>

                {/* aside panel */}
                {rightPanel && (
                    <div
                        className={classNames(styles.asidePanel, {
                            [styles.asidePanelVisibility]: isHasRightPanel
                        })}
                    >
                        {rightPanel}
                    </div>
                )}
            </div>
        </div>
    );
}
