import {Divider} from '@genestack/ui';
import React from 'react';

import {PaginatedList} from '../paginated-list';
import {PaginationAsyncValue} from '../pagination-async-value';
import {Preloader} from '../../preloader/preloader';

export interface PaginationPresentationProps<TItem, TArgs extends unknown[] = []> {
    pageSize: number | number[];
    items?: TItem[];
    paginationState: PaginationAsyncValue<TItem, TArgs>;
    renderItem: (docInfo: TItem, ndx: number) => [React.Key, React.ReactElement];
    onLoadNextPage?: () => void;
}

export const PaginationPresentation = <TItem extends object, TArgs extends unknown[] = []>({
    pageSize,
    items,
    paginationState,
    renderItem,
    onLoadNextPage
}: PaginationPresentationProps<TItem, TArgs>) => {
    const isLoadingResults = paginationState.isLoading && !paginationState.isLoadingNextPage;

    return (
        <Preloader show={isLoadingResults}>
            <PaginatedList
                pageSize={pageSize}
                items={items || paginationState.items || []}
                isLoadingNextPage={paginationState.isLoading || paginationState.isLoadingNextPage}
                hasNextPage={paginationState.hasNextPage}
                renderItem={renderItem}
                onLoadNext={onLoadNextPage}
            />
            <Preloader
                show={paginationState.isLoadingNextPage}
                /* eslint-disable-next-line no-magic-numbers */
                count={Math.min(10, paginationState.remaining ?? 10)}
            />

            <Divider variant="transparent" gap={4} />
        </Preloader>
    );
};
