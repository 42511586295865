import React from 'react';

// at one point I've decided to take advantage of React component structure to
// ensure the availability of data in useQueryGraphProps hook (I don't render the component with
// the hook until data needed for it is loaded, ie graph presentations, that way I don't have to
// use many checks on data availability in this hook, which makes the code MUCH simpler and cleaner)
// this approach however created problems when I needed data from this hook on top of component tree
// I created usePropagatedValues hook to propagate such values on top of the component tree. I know
// it's a hack and I don't like it myself, but I honestly couldn't come up with a better solution
export function usePropagatedValues() {
    const [maxPathLength, setMaxPathLength] = React.useState<number>();

    return {
        maxPathLength,
        propagateMaxPathLength: setMaxPathLength
    };
}
