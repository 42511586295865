import {WithSeparator, Divider, Notification, Typography} from '@genestack/ui';
import React from 'react';

import {
    editedOntologyBrowserSource,
    exploredOntologyBrowserSource,
    FindUsagesEditorEvent
} from '../../../../../../webeditor/biokb-editor-adapters';
import {BelEditorWrapper} from '../../../../../components/editor';
import {showNotification} from '../../../../../providers/notifications-center';
import {DSLPreloader} from '../../../../../components/preloader/dsl-preloader';
import {WindowTitle} from '../../../../../components/window-title';
import {EditorBackdrop} from '../../../edit-view/editor/editor-backdrop';
import {TitleSlotView} from '../../../explore-view/title-slot-view';
import {useOntologyElementQuery} from '../ontology-query';

import {NodeViewTree} from './node-view-tree';
import styles from './node-view.module.css';

interface Props {
    mode: 'explore' | 'edit';
    documentInfo: DocInfo | ViewModeDocInfo;
    versionId?: number | null;
    nodeId: string;
    onNavigate: (accession: string | null, nodeId: string | null) => void;
    onFindUsages: (event: FindUsagesEditorEvent) => void;
}

function ontologyBrowserSource(
    accession: string,
    nodeId: string,
    versionId?: number | null,
    nodeContent?: string
) {
    if (nodeContent) {
        return versionId
            ? exploredOntologyBrowserSource(accession, versionId, nodeId, nodeContent)
            : editedOntologyBrowserSource(accession, nodeId, nodeContent);
    }
}

/** UI component to render a particular node and a category tree */
export function NodeView(props: Props) {
    const [isLoadingEditor, setIsLoadingEditor] = React.useState(false);

    const nodeQuery = useOntologyElementQuery({
        documentId: props.documentInfo.id,
        versionId: props.versionId,
        nodeId: props.nodeId,
        accession: props.documentInfo.accession,
        viewer: props.mode === 'explore'
    });

    const handleNavigate = React.useCallback((nodeId: string) => {
        props.onNavigate(null, nodeId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        // TODO Move to query hook
        if (nodeQuery.error) {
            showNotification(
                <Notification countdown="none">
                    <Typography intent="warning">
                        Requested node is not present in the document
                    </Typography>
                </Notification>
            );
        }
    }, [nodeQuery.error]);

    if (!nodeQuery.data) {
        return (
            <WindowTitle text={document.title}>
                <DSLPreloader show />
            </WindowTitle>
        );
    }

    return (
        <WindowTitle text={`${props.documentInfo.name} / ${nodeQuery.data.name}`}>
            <TitleSlotView.Plug>
                <em>{nodeQuery.data.name}</em>
            </TitleSlotView.Plug>

            <div className={styles.editor}>
                <BelEditorWrapper
                    className={styles.editor}
                    documentSource={ontologyBrowserSource(
                        props.documentInfo.accession,
                        props.nodeId,
                        props.versionId,
                        nodeQuery.data?.content
                    )}
                    onNavigate={props.onNavigate}
                    onFindUsages={props.onFindUsages}
                    onLoadingStateChange={setIsLoadingEditor}
                />

                <EditorBackdrop isDocumentLoading={nodeQuery.isFetching || isLoadingEditor} />
            </div>

            <Divider variant="transparent" gap={2} />

            <WithSeparator separator={<Divider variant="transparent" gap={2} />}>
                <div className={styles.root}>
                    {nodeQuery.data.tree.roots.map((item) => (
                        <NodeViewTree
                            key={item.elementId}
                            docAccession={props.documentInfo.accession}
                            data={item}
                            onNavigate={handleNavigate}
                        />
                    ))}
                </div>
            </WithSeparator>
        </WindowTitle>
    );
}
