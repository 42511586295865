import {useQuery} from 'react-query';
import {GraphViewKind} from '../../../../../../components/graph/interface';
import {apiClient} from '../../../../../../utils/api-client';
import {StartEndNodesResponse} from '../interface';

function getStartEndNodesRequest(
    queryId: number,
    viewKind: GraphViewKind,
    maxPathLength: number,
    invisibleNodes: number[]
) {
    return apiClient.post<StartEndNodesResponse>({
        path: `queries-service/api/queries/${queryId}/connection-graph/${viewKind}/terminal-nodes`,
        parameters: {
            pathLengthLimit: maxPathLength,
            hiddenViewNodeIds: invisibleNodes
        }
    });
}

export function useGetStartEndNodes(
    queryId: number,
    viewKind: GraphViewKind,
    maxPathLength: number,
    invisibleNodes: number[]
) {
    return useQuery({
        queryKey: [
            'query-graph-start-end-nodes',
            queryId,
            viewKind,
            maxPathLength,
            ...invisibleNodes
        ],
        queryFn: () => getStartEndNodesRequest(queryId, viewKind, maxPathLength, invisibleNodes)
    });
}
