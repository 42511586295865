import {EdgeSingular, NodeSingular, Stylesheet} from 'cytoscape';
import {QueryNodeType, RelationPresentation} from '../interface';
import {formatNodeLabel} from './utils';

const startNodeSvg = new URL('./start-node-indicator.svg', import.meta.url);
const endNodeSvg = new URL('./end-node-indicator.svg', import.meta.url);

function getNodeProps(node: NodeSingular) {
    const {nodeType} = node.data();

    if (nodeType === QueryNodeType.START) {
        return {
            backgroundImage: startNodeSvg.href,
            backgroundOffsetY: -55.5,
            defaultLabel: 'start'
        };
    }

    if (nodeType === QueryNodeType.END) {
        return {
            backgroundImage: endNodeSvg.href,
            backgroundOffsetY: 55.5,
            defaultLabel: 'end'
        };
    }

    return {
        backgroundImage: undefined,
        backgroundOffsetY: 0,
        defaultLabel: 'middle'
    };
}

export function getConstantStyles(): Stylesheet[] {
    return [
        {
            selector: 'node',
            style: {
                shape: 'round-rectangle',
                width: 169,
                height: 105,
                // @ts-ignore
                label: (ele: NodeSingular) =>
                    ele.data().label
                        ? formatNodeLabel(ele.data().label)
                        : getNodeProps(ele).defaultLabel,
                'text-wrap': 'wrap',
                // arbitrarily big value so that only newlines created by formatNodeLabel count
                'text-max-width': '1000px',
                // @ts-ignore
                'background-image': (ele: NodeSingular) => getNodeProps(ele).backgroundImage,
                'background-clip': 'none',
                'bounds-expansion': 4,
                // @ts-ignore
                'background-offset-y': (ele: NodeSingular) => getNodeProps(ele).backgroundOffsetY,
                'text-valign': 'center',
                'outline-color': '#1C3C7D',
                'outline-style': 'solid',
                'outline-opacity': 1,
                'outline-offset': 6
            }
        },
        {
            selector: 'edge',
            style: {
                'target-arrow-shape': 'triangle',
                'target-arrow-fill': 'filled',
                'curve-style': 'bezier',
                'arrow-scale': 2,
                'line-style': (edge: EdgeSingular) => {
                    const {presentation} = edge.data();

                    if (presentation === RelationPresentation.MATCHED) {
                        return 'dashed';
                    }

                    return 'solid';
                }
            }
        }
    ];
}
