import React from 'react';

import {DividerProps, PageContent, Typography} from '@genestack/ui';
import {useHistory} from 'react-router-dom';
import {FavoriteDocumentsSidebar} from './favorites-sidebar';
import {RecentDocumentsSidebar} from './recents-sidebar';
import {SearchDocumentsSidebar} from './search-sidebar';
import {LayoutContextValue} from '../../../../components/layout/page-layout/use-page-layout';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';
import {useSearchSidebarContext} from '../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {ViewModeTab} from '../../../../components/layout/page-layout/layout-typings';
import {InvalidDocumentsSidebar} from './invalid-documents-sidebar';
import styles from './sidebar.module.css';
import {addGlobalVersionToUrl} from '../../../../hooks/use-search-params';
import {useLocationParams} from '../../../../hooks/use-location';
import {VersionSelect} from '../../../../hooks/global-version/version-select/version-select';

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
    layoutSettings: LayoutContextValue;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    globalVersions: GlobalVersionData[];
    selectedGlobalVersion: GlobalVersionData;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

const FOOTER_START_GAP: DividerProps = {startGap: 1, endGap: 3, variant: 'stroke'};
const FOOTER_END_GAP: DividerProps = {startGap: 2, endGap: 1};

export const ViewModeDocumentsSidebar = (props: Props) => {
    const {push} = useHistory();
    const {documentId} = useLocationParams();

    const redirectToVersion = (globalVersionId: number) => {
        push(
            addGlobalVersionToUrl(
                `/documents/explore${documentId ? `/${documentId}` : ''}`,
                globalVersionId
            )
        );
    };

    const footer = (
        <PageContent startDividerProps={FOOTER_START_GAP} endDividerProps={FOOTER_END_GAP}>
            <div className={styles.globalVersion}>
                <Typography as="span" variant="section" className={styles.globalVersionLabel}>
                    BioKB global version:
                </Typography>

                <VersionSelect
                    value={props.selectedGlobalVersion.id}
                    onChangeValue={redirectToVersion}
                    globalVersions={props.globalVersions}
                />
            </div>
        </PageContent>
    );

    switch (props.tabSettings.activityBarTab) {
        // eslint-disable-next-line default-case-last
        default:
        case ViewModeTab.RECENT:
            return (
                <RecentDocumentsSidebar
                    getDocumentLink={props.getDocumentLink}
                    footer={footer}
                    closeSidebar={props.layoutSettings.closeSidebar}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                />
            );
        case ViewModeTab.SEARCH:
            return (
                <SearchDocumentsSidebar
                    layoutSettings={props.layoutSettings}
                    searchSidebarContext={props.searchSidebarContext}
                    findUsagesProps={props.findUsagesProps}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                    getDocumentLink={props.getDocumentLink}
                    footer={footer}
                    closeSidebar={props.layoutSettings.closeSidebar}
                />
            );
        case ViewModeTab.FAVORITES:
            return (
                <FavoriteDocumentsSidebar
                    getDocumentLink={props.getDocumentLink}
                    footer={footer}
                    closeSidebar={props.layoutSettings.closeSidebar}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                />
            );
        case ViewModeTab.INVALID:
            return (
                <InvalidDocumentsSidebar
                    getDocumentLink={props.getDocumentLink}
                    selectedGlobalVersion={props.selectedGlobalVersion}
                    footer={footer}
                    closeSidebar={props.layoutSettings.closeSidebar}
                />
            );
    }
};
