import {RelationPresentation} from '../interface';

import {GraphEntityStatus} from '../cytoscape-graph/interface';

export const nodeColorDefault = '#1C3C7D';
export const nodeColorHover = '#0044CC';

/** Get props of edge by arrow type */
export const edgePropsByType = {
    [RelationPresentation.ASSERTED]: {
        [GraphEntityStatus.default]: {
            color: '#059CDD',
            markerUrl: 'query-results-arrow'
        },
        [GraphEntityStatus.hovered]: {
            color: '#000000',
            markerUrl: 'query-results-arrow-hover'
        },
        [GraphEntityStatus.selected]: {
            color: '#000000',
            markerUrl: 'query-results-arrow-selected'
        }
    },
    [RelationPresentation.REACTION]: {
        [GraphEntityStatus.default]: {
            color: '#94D03C',
            markerUrl: 'query-results-reaction-arrow'
        },
        [GraphEntityStatus.hovered]: {
            color: '#000000',
            markerUrl: 'query-results-reaction-arrow-hover'
        },
        [GraphEntityStatus.selected]: {
            color: '#000000',
            markerUrl: 'query-results-reaction-arrow-selected'
        }
    },
    [RelationPresentation.MATCHED]: {
        [GraphEntityStatus.default]: {
            color: '#FC49A7',
            markerUrl: 'query-results-indirect-arrow'
        },
        [GraphEntityStatus.hovered]: {
            color: '#000000',
            markerUrl: 'query-results-indirect-arrow-hover'
        },
        [GraphEntityStatus.selected]: {
            color: '#000000',
            markerUrl: 'query-results-indirect-arrow-selected'
        }
    }
};
