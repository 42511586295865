import usePrevious from 'react-use/lib/usePrevious';
import * as React from 'react';
import {GraphEdge} from '../interface';

interface Props {
    selectedNodes: number[];
    hoveredNodes: number[];
    hiddenNodes: number[];
    edges: GraphEdge[];
    isHiddenNodesVisible: boolean;
}

function useStateDiff(current: number[], previous?: number[]): [number[], number[], Set<number>] {
    const addedElements = React.useMemo(() => {
        if (!previous) {
            return [];
        }
        const previousSet = new Set(previous);
        return current.filter((nodeId) => !previousSet.has(nodeId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previous?.toString(), current.toString()]);

    const currentSet = React.useMemo(() => new Set(current), [current]);

    const removedElements = React.useMemo(
        () => (!previous ? [] : previous.filter((nodeId) => !currentSet.has(nodeId))),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [previous?.toString(), Array.from(currentSet).toString()]
    );

    return [addedElements, removedElements, currentSet];
}

export function useGraphStateDiff({
    selectedNodes,
    hoveredNodes,
    hiddenNodes,
    edges,
    isHiddenNodesVisible
}: Props) {
    const selectedNodesPrevious = usePrevious(selectedNodes) || [];
    const [newSelectedNodes, newUnselectedNodes, selectedNodesCurrentSet] = useStateDiff(
        selectedNodes,
        selectedNodesPrevious
    );

    const hoveredNodesPrevious = usePrevious(hoveredNodes) || [];
    const [newHoveredNodes, newUnhoveredNodes] = useStateDiff(hoveredNodes, hoveredNodesPrevious);

    const partiallyHiddenNodes = React.useMemo(
        () => (isHiddenNodesVisible ? hiddenNodes : []),
        [hiddenNodes, isHiddenNodesVisible]
    );
    const partiallyHiddenNodesPrev = usePrevious(partiallyHiddenNodes);
    const [newPartiallyHiddenNodes, newPartiallyUnhiddenNodes, partiallyHiddenNodesCurrentSet] =
        useStateDiff(partiallyHiddenNodes, partiallyHiddenNodesPrev);
    const getIsNodePartiallyHidden = React.useCallback(
        (edgeId: number) => partiallyHiddenNodesCurrentSet.has(edgeId),
        [partiallyHiddenNodesCurrentSet]
    );

    const fullyHiddenNodes = React.useMemo(
        () => (isHiddenNodesVisible ? [] : hiddenNodes),
        [hiddenNodes, isHiddenNodesVisible]
    );
    const fullyHiddenNodesPrev = usePrevious(fullyHiddenNodes);
    const [newFullyHiddenNodes, newFullyUnhiddenNodes, fullyHiddenNodesCurrentSet] = useStateDiff(
        fullyHiddenNodes,
        fullyHiddenNodesPrev
    );
    const getIsNodeFullyHidden = React.useCallback(
        (nodeId: number) => fullyHiddenNodesCurrentSet.has(nodeId),
        [fullyHiddenNodesCurrentSet]
    );

    const selectedEdges = React.useMemo(() => {
        return edges
            .filter(
                (edge) =>
                    selectedNodesCurrentSet.has(edge.startViewNodeId) &&
                    selectedNodesCurrentSet.has(edge.endViewNodeId)
            )
            .map((edge) => edge.id);
    }, [edges, selectedNodesCurrentSet]);
    const selectedEdgesPrevious = usePrevious(selectedEdges) || [];
    const [newSelectedEdges, newUnselectedEdges] = useStateDiff(
        selectedEdges,
        selectedEdgesPrevious
    );

    const partiallyHiddenEdges = React.useMemo(() => {
        return edges
            .filter(
                (edge) =>
                    partiallyHiddenNodesCurrentSet.has(edge.startViewNodeId) ||
                    partiallyHiddenNodesCurrentSet.has(edge.endViewNodeId)
            )
            .map((edge) => edge.id);
    }, [edges, partiallyHiddenNodesCurrentSet]);
    const partiallyHiddenEdgesPrevious = usePrevious(partiallyHiddenEdges);
    const [newPartiallyHiddenEdges, newPartiallyUnhiddenEdges, partiallyHiddenEdgesSet] =
        useStateDiff(partiallyHiddenEdges, partiallyHiddenEdgesPrevious);
    const getIsEdgePartiallyHidden = React.useCallback(
        (edgeId: number) => partiallyHiddenEdgesSet.has(edgeId),
        [partiallyHiddenEdgesSet]
    );

    const fullyHiddenEdges = React.useMemo(() => {
        return edges
            .filter(
                (edge) =>
                    fullyHiddenNodesCurrentSet.has(edge.startViewNodeId) ||
                    fullyHiddenNodesCurrentSet.has(edge.endViewNodeId)
            )
            .map((edge) => edge.id);
    }, [edges, fullyHiddenNodesCurrentSet]);
    const fullyHiddenEdgesPrevious = usePrevious(fullyHiddenEdges);
    const [newFullyHiddenEdges, newFullyUnhiddenEdges, fullyHiddenEdgesSet] = useStateDiff(
        fullyHiddenEdges,
        fullyHiddenEdgesPrevious
    );
    const getIsEdgeFullyHidden = React.useCallback(
        (edgeId: number) => fullyHiddenEdgesSet.has(edgeId),
        [fullyHiddenEdgesSet]
    );

    return {
        newSelectedNodes,
        newHoveredNodes,
        newUnselectedNodes,
        newUnhoveredNodes,
        newSelectedEdges,
        newUnselectedEdges,
        newPartiallyHiddenNodes,
        newPartiallyUnhiddenNodes,
        newFullyHiddenNodes,
        newFullyUnhiddenNodes,
        newPartiallyHiddenEdges,
        newPartiallyUnhiddenEdges,
        newFullyHiddenEdges,
        newFullyUnhiddenEdges,
        getIsNodePartiallyHidden,
        getIsNodeFullyHidden,
        getIsEdgePartiallyHidden,
        getIsEdgeFullyHidden
    };
}
