import React from 'react';
import {Spinner} from '@genestack/ui';
import style from './neighborhood-graph.module.css';
import {useRequestNeighborhoodGraph} from './hooks/use-request-neighborhood-graph';
import {NeighborhoodGraphLoaded} from './neighborhood-graph-loaded';
import {showErrorNotification} from '../../../../../components/notifications-center/notifications-store';
import {NeighborhoodGraphHeader} from './neighborhood-graph-header';
import {GraphDisplayErr} from '../../../../../components/graph/cytoscape-graph/graph-display-err';
import {useNeighborhoodGraphSettings} from './hooks/use-neighborhood-graph-settings';
import {useGraphSelectionProps} from '../../../../../components/graph/use-graph-selection-props';
import {useDocLayoutSettings} from '../../hooks/use-doc-layout-settings';

interface Props {
    documentId: string;
    globalVersionId: number;
    // eslint-disable-next-line react/no-unused-prop-types
    selectedFacts: string[];
    closeGraph: () => void;
    graphSelectionProps: ReturnType<typeof useGraphSelectionProps>;
    layoutSettings: ReturnType<typeof useDocLayoutSettings>;
    graphSettings: ReturnType<typeof useNeighborhoodGraphSettings>;
}

export function NeighborhoodGraph(props: Props) {
    const {
        graphSettings: {
            maxDistance,
            selectedViewType,
            toggleSelectedViewType,
            setMaxDistance,
            direction,
            setDirection
        },
        graphSelectionProps: {onNodesLoaded}
    } = props;

    const neighborhoodGraph = useRequestNeighborhoodGraph({
        documentId: props.documentId,
        factIds: props.selectedFacts,
        globalVersionId: props.globalVersionId,
        maxDistance,
        onError: showErrorNotification(
            'Error loading data, please refresh page or try again later'
        ),
        direction,
        selectedViewType
    });

    // tanstack query doesn't seem to have an option to fire a callback on every query data change,
    // only on data loaded from server, so I have to use this hack here, otherwise
    // this code wouldn't run on data loaded from cache
    const onNodesLoadedRef = React.useRef(onNodesLoaded);
    onNodesLoadedRef.current = onNodesLoaded;
    React.useEffect(() => {
        if (neighborhoodGraph.data) {
            onNodesLoadedRef.current(neighborhoodGraph.data.nodes);
        }
    }, [neighborhoodGraph.data]);

    return (
        <div className={style.neighborhoodGraph}>
            <NeighborhoodGraphHeader
                closeGraph={props.closeGraph}
                isCollapsedPanel={props.layoutSettings.isGraphPanelCollapsed}
                toggleIsCollapsedPanel={props.layoutSettings.toggleIsGraphPanelCollapsed}
                selectedViewType={selectedViewType}
                toggleSelectedViewType={toggleSelectedViewType}
                maxDistance={maxDistance}
                setMaxDistance={setMaxDistance}
                direction={direction}
                setDirection={setDirection}
            />
            {(function () {
                if (!neighborhoodGraph.data) {
                    return (
                        <div className={style.neighborhoodGraphLoader}>
                            <Spinner size={48} />
                        </div>
                    );
                }

                if (!neighborhoodGraph.data.edges.length) {
                    return (
                        <GraphDisplayErr label="The neighborhood graph is not displayed because it has no edges." />
                    );
                }

                return (
                    <NeighborhoodGraphLoaded
                        nodes={neighborhoodGraph.data.nodes}
                        edges={neighborhoodGraph.data.edges}
                        selectedFacts={props.selectedFacts}
                        graphSettings={props.graphSettings}
                        documentId={props.documentId}
                        globalVersionId={props.globalVersionId}
                        graphSelectionProps={props.graphSelectionProps}
                        layoutSettings={props.layoutSettings}
                    />
                );
            })()}
        </div>
    );
}
