import * as d3 from 'd3';

import {GridCoordinates, StructuredGraphEdge} from '../interface';

import {edgePropsByType} from './constants';
import {GraphEntityStatus} from '../cytoscape-graph/interface';
import {getEdgeStrokeDasharray, getEdgeSVGPath} from './svg-coordinates-helpers';
import {isSelectMultipleMouseEvent} from '../../../utils/get-client-os';
import {GenestackGraphGrid} from './genestack-graph-grid';

interface Props {
    canvasElement: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
    graphContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>;
    genestackGraphGrid: GenestackGraphGrid;
    hoveredEdges: number[];
    setHoveredEdges: (edgeIds: number[]) => void;
    selectedNodes: number[];
    edgesGridPaths: {[key: string]: GridCoordinates[]};
    selectNodes: (nodeId: number[], isSelectMultiple: boolean) => void;
}

function getEdgeStatus(edge: StructuredGraphEdge, selectedNodes: number[], hoveredEdges: number[]) {
    if (selectedNodes.includes(edge.sourceNode.id) && selectedNodes.includes(edge.targetNode.id)) {
        return GraphEntityStatus.selected;
    }

    if (hoveredEdges.includes(edge.id)) {
        return GraphEntityStatus.hovered;
    }

    return GraphEntityStatus.default;
}

/** Render edges for a graph */
export function renderEdges(props: Props) {
    const {
        genestackGraphGrid,
        hoveredEdges,
        setHoveredEdges,
        canvasElement,
        graphContainer,
        selectedNodes,
        selectNodes,
        edgesGridPaths
    } = props;
    const allPolylines = canvasElement
        .selectAll<SVGPolylineElement, StructuredGraphEdge>('polyline')
        .data(genestackGraphGrid.structuredGraph.allEdges)
        .join('polyline')
        .sort((edge1, edge2) => {
            const statusWeights = {
                [GraphEntityStatus.default]: 0,
                [GraphEntityStatus.hovered]: 1,
                [GraphEntityStatus.selected]: 2
            };
            const edge1Status = getEdgeStatus(edge1, selectedNodes, hoveredEdges);
            const edge2Status = getEdgeStatus(edge2, selectedNodes, hoveredEdges);

            return statusWeights[edge2Status] - statusWeights[edge1Status];
        });

    allPolylines.each(function (edge) {
        const status = getEdgeStatus(edge, selectedNodes, hoveredEdges);

        let selection: d3.Selection<SVGPolylineElement, StructuredGraphEdge, d3.BaseType, unknown> =
            d3.select(this);
        const {color, markerUrl} = edgePropsByType[edge.presentation][status];

        // redraw the line, so it appears on top of every other, in case it overlaps
        if (status !== GraphEntityStatus.default) {
            selection.remove();
            selection = canvasElement.append('polyline').data([edge]);
        }

        selection
            .attr('points', () => {
                const edgeGridPath = edgesGridPaths[edge.id];

                return getEdgeSVGPath(edgeGridPath);
            })
            .attr('fill', 'none')
            .attr('marker-end', `url(#${markerUrl})`)
            .attr('stroke-width', 2)
            .attr('stroke', color)
            .style('stroke-dasharray', () => {
                const edgeGridPath = edgesGridPaths[edge.id];
                const svgPath = getEdgeSVGPath(edgeGridPath);

                if (status !== GraphEntityStatus.default) {
                    return '';
                }

                return getEdgeStrokeDasharray(
                    svgPath,
                    edge.id,
                    edgesGridPaths,
                    genestackGraphGrid.getGridMap(edgesGridPaths)
                );
            })
            .style('cursor', 'pointer');

        if (status === GraphEntityStatus.default) {
            selection.on('mouseover', function () {
                setHoveredEdges([edge.id]);
            });
        }

        if (status === GraphEntityStatus.hovered) {
            selection.on('mousedown', function (event) {
                const isSelectMultiple = isSelectMultipleMouseEvent(event);

                selection.on('mouseover', null);
                graphContainer.on('mousemove', null);
                selectNodes([edge.sourceNode.id, edge.targetNode.id], isSelectMultiple);
                setHoveredEdges([]);
            });

            graphContainer.on('mousemove', (event: MouseEvent) => {
                const eventTargetIsAnEdge = !!(event.target as SVGPolylineElement).points;

                if (!eventTargetIsAnEdge) {
                    graphContainer.on('mousemove', null);
                    setHoveredEdges([]);
                }
            });
        }

        if (status === GraphEntityStatus.selected) {
            selection.on('mousedown', (event) => {
                const isSelectMultiple = isSelectMultipleMouseEvent(event);

                selectNodes([edge.sourceNode.id, edge.targetNode.id], isSelectMultiple);
            });
        }
    });
}
