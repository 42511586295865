import React from 'react';

import {GridCoordinates} from '../interface';
import {CalculateEdgePathsWorkerInitData} from '../../../workers/types';
import {getStaticFileUrl} from '../../../utils/get-static-file-url';
import {GenestackGraphGrid} from './genestack-graph-grid';

interface EdgesGridPathInfo {
    genestackGraphGrid: GenestackGraphGrid;
    paths: {[key: string]: GridCoordinates[]};
}

export function useCalculateEdgePaths(
    genestackGraphGrid: GenestackGraphGrid,
    onCalculated?: (calculationTime: number) => void
) {
    const [edgesGridPathsInfo, setEdgesGridPathsInfo] = React.useState<EdgesGridPathInfo>();
    const [isBrowserUnsupported, setIsBrowserUnsupported] = React.useState<boolean>();

    const workerRef = React.useRef<Worker>();

    React.useEffect(() => {
        if (!window.Worker) {
            setIsBrowserUnsupported(true);

            return undefined;
        }

        workerRef.current = new window.Worker(
            getStaticFileUrl('js/calculate-edge-paths-worker.js')
        );

        const edgesSortedByLength = genestackGraphGrid.edgesSortedByLength(
            genestackGraphGrid.structuredGraph.allEdges
        );

        const calculationStartedTimestamp = new Date().valueOf();
        workerRef.current.postMessage({
            edges: edgesSortedByLength,
            gridMap: genestackGraphGrid.getGridMap({}),
            nodesGridCoordinates: genestackGraphGrid.nodesGridCoordinates
        } as CalculateEdgePathsWorkerInitData);

        workerRef.current.onmessage = (event) => {
            setEdgesGridPathsInfo({paths: event.data, genestackGraphGrid});
            workerRef.current?.terminate();
            workerRef.current = undefined;
            if (onCalculated) {
                onCalculated(new Date().valueOf() - calculationStartedTimestamp);
            }
        };

        return () => {
            workerRef.current?.terminate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [genestackGraphGrid]);

    return {
        edgesGridPaths:
            genestackGraphGrid === edgesGridPathsInfo?.genestackGraphGrid
                ? edgesGridPathsInfo.paths
                : undefined,
        isBrowserUnsupported
    };
}
