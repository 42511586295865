import cytoscape, {Core, Position} from 'cytoscape';
import * as React from 'react';
import popper from 'cytoscape-popper';
import styles from './cytoscape-graph-view.module.css';

import {GraphLayout, GraphNode, GraphEdge} from '../../../interface';
import {useDynamicStyles} from '../use-dynamic-styles';
import {graphElementId, useCytoscapeInitialization} from '../use-cytoscape-initialization';
import {ControlPanel} from './control-panel';
import {useHiddenNodesActions} from '../../graph-logic/hidden-nodes-actions/use-hidden-nodes-actions';
import {useGraphStateDiff} from '../use-graph-state-diff';

interface Props {
    nodePositions: GraphLayout;
    handleNodeMoved: (value: GraphLayout) => void;
    nodes: GraphNode[];
    edges: GraphEdge[];
    selectNodes: (nodeId: number[], isSelectMultiple: boolean) => void;
    selectedNodes: number[];
    hoveredNodes: number[];
    hiddenNodes: number[];
    setHoveredNodes: (nodeIds: number[]) => void;
    zoom: number;
    setZoom: (val: number) => void;
    pan: Position;
    setPan: (val: Position) => void;
    isPresentationEdited?: boolean;
    hiddenNodesActions?: ReturnType<typeof useHiddenNodesActions>;
}

cytoscape.use(popper);

export const CytoscapeGraphView = (props: Props) => {
    const {
        nodes,
        edges,
        selectNodes,
        selectedNodes,
        hoveredNodes,
        hiddenNodes,
        setHoveredNodes,
        nodePositions,
        handleNodeMoved,
        zoom,
        setZoom,
        pan,
        setPan,
        isPresentationEdited,
        hiddenNodesActions
    } = props;
    const containerElementRef = React.useRef<HTMLDivElement>(null);
    const csRef = React.useRef<Core>(null);

    const focusGraph = React.useCallback(() => csRef.current?.fit(), []);

    const graphStateDiffProps = useGraphStateDiff({
        selectedNodes,
        hoveredNodes,
        hiddenNodes,
        edges,
        isHiddenNodesVisible: hiddenNodesActions ? hiddenNodesActions.isHiddenNodesVisible : true
    });

    useCytoscapeInitialization({
        csRef,
        nodes,
        edges,
        nodePositions,
        handleNodeMoved,
        zoom,
        setZoom,
        pan,
        setPan,
        setHoveredNodes,
        selectNodes,
        isPresentationEdited,
        getIsNodePartiallyHidden: graphStateDiffProps.getIsNodePartiallyHidden,
        getIsNodeFullyHidden: graphStateDiffProps.getIsNodeFullyHidden,
        getIsEdgePartiallyHidden: graphStateDiffProps.getIsEdgePartiallyHidden,
        getIsEdgeFullyHidden: graphStateDiffProps.getIsEdgeFullyHidden,
        isHiddenNodesVisible: hiddenNodesActions ? hiddenNodesActions.isHiddenNodesVisible : true
    });
    useDynamicStyles({
        csRef,
        containerElementRef,
        graphStateDiffProps,
        nodes,
        edges,
        nodePositions
    });

    return (
        <div className={styles.graphContainer}>
            <div id={graphElementId} className={styles.graphView} ref={containerElementRef} />
            <ControlPanel focusGraph={focusGraph} hiddenNodesActions={hiddenNodesActions} />
        </div>
    );
};
