import React from 'react';

import {DeletedQueriesSidebar} from './deleted-queries-sidebar';
import {FavoriteQueriesSidebar} from './favorite-queries-sidebar';
import {QueryDraftsSidebar} from './query-drafts-sidebar';
import {QueryHistorySidebar} from './query-history-sidebar';
import {RunningQueriesSidebar} from './running-queries-sidebar';
import {SharedQueriesSidebar} from './shared-queries-sidebar';
import {LayoutContextValue} from '../../../components/layout/page-layout/use-page-layout';
import {useSidebarTabs} from '../../../hooks/use-sidebar-tabs';

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
    layoutSettings: LayoutContextValue;
}

export const QueriesSidebar = (props: Props) => {
    switch (props.tabSettings.activityBarTab) {
        // eslint-disable-next-line default-case-last
        default:
        case 'drafts':
            return <QueryDraftsSidebar closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'running':
            return <RunningQueriesSidebar closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'favorites':
            return <FavoriteQueriesSidebar closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'recent':
            return <QueryHistorySidebar closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'shared':
            return <SharedQueriesSidebar closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'bin':
            return <DeletedQueriesSidebar closeSidebar={props.layoutSettings.closeSidebar} />;
    }
};
