import {ListItem, ListItemProps} from '@genestack/ui';
import React from 'react';

import {RelativeLink} from '../../../../components/relative-link';

import styles from './document-nodes.module.css';

export interface DocumentNode {
    id: string;
    label: string;
}

interface Props {
    docAccession: string;
    documentNode: DocumentNode;
    nodeProps?: ListItemProps;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export const DocumentNodeItem = ({
    docAccession,
    documentNode,
    getDocumentLink,
    nodeProps = {}
}: Props) => {
    const illegalProps = React.useMemo(() => {
        return {
            as: RelativeLink,
            to: getDocumentLink(docAccession, documentNode.id),
            ...nodeProps
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentNode.id, docAccession, getDocumentLink]);

    return (
        <ListItem
            titleContentProps={{className: styles.nodeTitle}}
            contentProps={{className: styles.nodeContent}}
            interactive
            title={documentNode.label}
            {...illegalProps}
            onClick={nodeProps.onClick}
        >
            {documentNode.label}
        </ListItem>
    );
};
