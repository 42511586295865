import {QueryNodeType, StructuredGraphNode} from '../interface';
import {StructuredGraph} from '../biokb-graph/structured-graph';

// get a recursive function that works in one of two directions and can determine
// if path to start or end nodes (depending on direction) from the argument node
// lies only through the removed node
function getFuncIsConnectedOnlyThroughRemoved(
    removedNodeId: number,
    searchConnectionTo: QueryNodeType.START | QueryNodeType.END
) {
    const cacheMap: {[key: number]: boolean} = {};

    return function isConnectedOnlyThroughRemoved(
        node: StructuredGraphNode,
        // this map is to prevent the func entering infinite cycle when the graph has cycles
        processedNodesMap: {[key: number]: boolean}
    ): boolean {
        const cacheValue = cacheMap[node.id];
        if (cacheValue !== undefined) {
            return cacheValue;
        }

        cacheMap[node.id] = (function () {
            if (node.id === removedNodeId) {
                return true;
            }

            if (node.nodeType === searchConnectionTo) {
                return false;
            }

            const nextStepNodes =
                searchConnectionTo === QueryNodeType.START
                    ? node.incomingEdges.map(({sourceNode}) => sourceNode)
                    : node.outgoingEdges.map(({targetNode}) => targetNode);

            return nextStepNodes.every(
                (nextStepNode) =>
                    processedNodesMap[nextStepNode.id] ||
                    isConnectedOnlyThroughRemoved(nextStepNode, {
                        ...processedNodesMap,
                        [nextStepNode.id]: true
                    })
            );
        })();

        return cacheMap[node.id];
    };
}

// returns all nodes that become dead-end (not part of a path from a start node to an end node)
// once removedNode is removed
export function getDeadEndNodes(structuredGraph: StructuredGraph, removedNodeId: number) {
    const deadEndNodes = new Set<number>();
    const processedNodesQueue: StructuredGraphNode[] = [structuredGraph.getNode(removedNodeId)!];
    // this map is to prevent the func from entering infinite cycle when the graph has cycles
    let processedNodesMap: {[key: number]: true} = {};

    const checkIfOnlyDescentsFromRemoved = getFuncIsConnectedOnlyThroughRemoved(
        removedNodeId,
        QueryNodeType.START
    );
    const checkIfOnlyCanLeadToRemoved = getFuncIsConnectedOnlyThroughRemoved(
        removedNodeId,
        QueryNodeType.END
    );

    // traverse the graph forward to find all nodes that descend only from the removed node...
    // meaning they have no other way to reach back to any start node except through the...
    // removed node
    do {
        const currentNode = processedNodesQueue.pop()!;
        processedNodesMap[currentNode.id] = true;

        if (
            currentNode.id === removedNodeId ||
            checkIfOnlyDescentsFromRemoved(currentNode, {[currentNode.id]: true})
        ) {
            const nextNodes = currentNode.outgoingEdges
                .map((edge) => edge.targetNode)
                // eslint-disable-next-line no-loop-func
                .filter(({id}) => !processedNodesMap[id]);
            processedNodesQueue.push(...nextNodes);
            deadEndNodes.add(currentNode.id);
        }
    } while (processedNodesQueue.length > 0);

    // get queue and map in an initial state
    processedNodesQueue.push(structuredGraph.getNode(removedNodeId)!);
    processedNodesMap = {};

    // traverse the graph backward to find all nodes that lead only to the removed node
    // meaning they have no other way to reach any end node except through the
    // removed node
    do {
        const currentNode = processedNodesQueue.pop()!;
        processedNodesMap[currentNode.id] = true;

        if (
            currentNode.id === removedNodeId ||
            checkIfOnlyCanLeadToRemoved(currentNode, {[currentNode.id]: true})
        ) {
            const prevNodes = currentNode.incomingEdges
                .map((edge) => edge.sourceNode)
                .filter(({id}) => !processedNodesMap[id]);
            processedNodesQueue.push(...prevNodes);
            deadEndNodes.add(currentNode.id);
        }
    } while (processedNodesQueue.length > 0);

    return Array.from(deadEndNodes).filter((node) => node !== removedNodeId);
}
