import {Typography, ScrollView} from '@genestack/ui';
import React from 'react';

import {PaginationPresentation} from '../../../../../components/pagination/pagination-presentation/pagination-presentation';
import {PaginationAsyncValue} from '../../../../../components/pagination/pagination-async-value';
import {SidebarResultsHeader} from '../../../common/search-results-header';
import {useLocationParams} from '../../../../../hooks/use-location';
import {pluralize} from '../../../../../utils/pluralize';
import {DocumentLink} from '../document-link';
import {useSearchDocuments} from './hooks/use-search-documents';
import {DocSearchData} from '../../../common/sidebar-search-field/interface';
import {DocumentStatusIcon} from '../../../common/doc-status-icon';

interface Props {
    searchData: DocSearchData;
}

function getSearchLabel(paginationState: PaginationAsyncValue<DocInfo, [string]>): string {
    if (!paginationState.items) {
        return '';
    }

    const count = paginationState.total ?? 0;

    return count === 0
        ? 'No documents matched your search'
        : `Found ${count} ${pluralize('document', count)}`;
}

export function SidebarSearchResults({searchData}: Props) {
    const {documentId} = useLocationParams();

    const {paginationState, pageSize, onLoadNextPage} = useSearchDocuments({
        searchText: searchData.value
    });

    const renderDocumentLink = React.useCallback(
        (doc: DocInfo) => {
            const element = (
                <DocumentLink
                    doc={doc}
                    active={doc.accession === documentId}
                    DocStatusIconComponent={DocumentStatusIcon}
                />
            );

            return [doc.accession, element] as [React.Key, React.ReactElement];
        },
        [documentId]
    );

    return (
        <>
            <SidebarResultsHeader
                isLoading={paginationState.isLoading || paginationState.isLoadingNextPage}
            >
                <Typography variant="section">{getSearchLabel(paginationState)}</Typography>
            </SidebarResultsHeader>

            <ScrollView>
                <PaginationPresentation
                    pageSize={pageSize}
                    paginationState={paginationState}
                    renderItem={renderDocumentLink}
                    onLoadNextPage={onLoadNextPage}
                />
            </ScrollView>
        </>
    );
}
