import {PageContent, Typography, Divider} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../components/preloader/preloader';
import {Sidebar} from '../../../../components/layout/sidebar';
import {useRecentsQuery} from '../hooks/requests/use-recents-query';
import {useLocationParams} from '../../../../hooks/use-location';
import {useConsumeDocInfo} from '../../../../providers/doc-info-store';

import {DocumentLink} from './document-link';
import {ExploreDocumentsSidebarProps} from './interface';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';

function RecentsResults(props: {
    items: ViewModeDocInfo[];
    selectedDocumentId?: string;
    getDocumentLink: (documentId: string) => string;
}) {
    useConsumeDocInfo(props.items);

    return (
        <>
            {props.items.map((docInfo) => (
                <DocumentLink
                    key={docInfo.accession}
                    documentId={docInfo.accession}
                    active={docInfo.accession === props.selectedDocumentId}
                    getDocumentLink={props.getDocumentLink}
                    docInfo={docInfo}
                />
            ))}
        </>
    );
}

export const RecentDocumentsSidebar = (props: ExploreDocumentsSidebarProps) => {
    const {documentId: selectedDocumentId} = useLocationParams();
    const recentDocsQuery = useRecentsQuery(props.selectedGlobalVersion.id);

    const header = <SidebarHeader title="Recent" onClose={props.closeSidebar} />;

    return (
        <Sidebar header={header} footer={props.footer} border="right">
            <Preloader show={recentDocsQuery.isFetching}>
                {!recentDocsQuery.isSuccess || !recentDocsQuery.data?.data.length ? (
                    <PageContent noStartDivider noEndDivider>
                        <Typography intent="quiet">Your browsing history appears here</Typography>
                    </PageContent>
                ) : (
                    <>
                        <RecentsResults
                            items={recentDocsQuery.data.data}
                            selectedDocumentId={selectedDocumentId}
                            getDocumentLink={props.getDocumentLink}
                        />
                        <Divider
                            variant="transparent"
                            gap={4} // tslint:disable-line: no-magic-numbers
                        />
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
};
