import React from 'react';
import {PageContent, Typography} from '@genestack/ui';
import {Sidebar} from '../../../../components/layout/sidebar';
import {
    SEARCH_DOCS_PAGE_SIZE,
    useSearchDocumentsInfiniteQuery
} from './search-results/search-docs-infinite-query';
import {extractInfiniteQueryItems} from '../../../../components/pagination/infinite-query-items';
import {Preloader} from '../../../../components/preloader/preloader';
import {SearchResultsBody} from './search-results/search-results-body';
import {DocumentLink} from './document-link';
import {useSearchSidebarLoadingState} from '../hooks/use-search-sidebar-loading-state';
import {ExploreDocumentsSidebarProps} from './interface';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';

interface Props extends ExploreDocumentsSidebarProps {
    selectedDocId?: number;
}

const emptyLabels = {
    VALIDATING: 'Validation in process.',
    VALID: 'No invalid documents in this Global Version.',
    NOT_VALIDATED: 'Global version was not validated.',
    FAILED: 'Global version was not validated.',
    INVALID: ''
};

export function InvalidDocumentsSidebar(props: Props) {
    const invalidDocsQuery = useSearchDocumentsInfiniteQuery({
        versionId: props.selectedGlobalVersion.id,
        validationStatus: 'INVALID'
    });

    const items = extractInfiniteQueryItems(
        invalidDocsQuery,
        (result: PageResponse<ViewModeDocInfo>) => result.data
    );

    const header = <SidebarHeader title="Invalid documents" onClose={props.closeSidebar} />;

    const renderDocumentLink = (docInfo: ViewModeDocInfo) => {
        const element = (
            <DocumentLink
                documentId={docInfo.accession}
                active={docInfo.accession === String(props.selectedDocId)}
                getDocumentLink={props.getDocumentLink}
                docInfo={docInfo}
            />
        );

        return [docInfo.accession, element] as [React.Key, React.ReactElement];
    };

    const {loadingState, setLoadingState} = useSearchSidebarLoadingState();

    return (
        <Sidebar header={header} footer={props.footer} border="right">
            <Preloader show={invalidDocsQuery.isLoading}>
                {!invalidDocsQuery.isSuccess || !items?.length ? (
                    <PageContent noStartDivider noEndDivider>
                        <Typography intent="quiet">
                            {emptyLabels[props.selectedGlobalVersion.validationStatus]}
                        </Typography>
                    </PageContent>
                ) : (
                    <SearchResultsBody
                        pageSize={SEARCH_DOCS_PAGE_SIZE}
                        paginationState={{
                            items,
                            total: invalidDocsQuery.data?.pages[0]?.total,
                            isLoading: invalidDocsQuery.isFetching,
                            hasNextPage: invalidDocsQuery.hasNextPage,
                            loadNextPage: invalidDocsQuery.fetchNextPage,
                            isLoadingNextPage: invalidDocsQuery.isFetchingNextPage,
                            reset: invalidDocsQuery.remove
                        }}
                        renderItem={renderDocumentLink}
                        loadingState={loadingState}
                        setLoadingState={setLoadingState}
                        onLoadNextPage={invalidDocsQuery.fetchNextPage}
                    />
                )}
            </Preloader>
        </Sidebar>
    );
}
