import React from 'react';

import {Sidebar} from '../../../../components/layout/sidebar';
import {useRecentDocsQuery} from '../hooks/use-recent-docs-query';

import {PortionedDocsGroupContent} from './portioned-docs-group-content';
import {DocumentStatusIconWithDeleted} from '../../common/doc-status-icon/document-status-icon';
import {EditViewSidebarProps} from './interface';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';

export const RecentDocuments = (props: EditViewSidebarProps) => {
    const recentDocs = useRecentDocsQuery();

    const sidebarHeader = <SidebarHeader title="Recent document" onClose={props.closeSidebar} />;

    return (
        <Sidebar header={sidebarHeader} border="right">
            <PortionedDocsGroupContent
                isInitialLoading={recentDocs.isLoading}
                docs={recentDocs.data}
                onClickLoadMoreButton={recentDocs.refetch}
                DocStatusIconComponent={DocumentStatusIconWithDeleted}
            />
        </Sidebar>
    );
};
