import React from 'react';

import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';
import {useRequestQueryResultsSubgraph} from './requests/use-request-query-results-subgraph';

import {GraphNode, GraphType, GraphViewKind} from '../../../../../components/graph/interface';
import {StructuredGraph} from '../../../../../components/graph/cytoscape-graph/graph-logic/structured-graph';
import {useLayoutAndCameraProps} from './use-layout-and-camera-props';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {usePresentationEditedProps} from './use-presentation-edited-props';
import {useGraphSettings} from './use-graph-settings';
import {useUnsavedChangesActions} from './use-unsaved-changes-actions';
import {useHiddenNodes} from './use-hidden-nodes';
import {useHiddenNodesActions} from '../../../../../components/graph/cytoscape-graph/graph-logic/hidden-nodes-actions/use-hidden-nodes-actions';
import {useGraphSelectionProps} from '../../../../../components/graph/use-graph-selection-props';
import {GenestackGraphGrid} from '../../../../../components/graph/biokb-graph/genestack-graph-grid';
import {usePropagatedValues} from './use-propagated-values';
import {usePropagateValues} from './use-propagate-values';

interface Props {
    selectedQuery: CompletedQuery;
    maxPathLengthOptions: MaxPathLengthOptionResult;
    viewKindsOptions: GraphViewKind[];
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
    selectionProps: ReturnType<typeof useGraphSelectionProps>;
    propagatedValuesProps: ReturnType<typeof usePropagatedValues>;
}

export function useQueryGraphProps({
    selectedQuery,
    maxPathLengthOptions,
    viewKindsOptions,
    selectedPresentation,
    selectedPresentationDetails,
    selectionProps,
    propagatedValuesProps
}: Props) {
    const presentationEditedProps = usePresentationEditedProps({
        queryId: selectedQuery.id,
        presentation: selectedPresentation
    });

    const graphSettings = useGraphSettings({
        queryId: selectedQuery.id,
        maxPathLengthOptions,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited: presentationEditedProps.handlePresentationEdited
    });
    const {selectedViewKind, maxPathLength, graphType} = graphSettings;

    const queryGraphRequest = useRequestQueryResultsSubgraph(
        selectedViewKind,
        selectedQuery.id,
        maxPathLength
    );

    const structuredGraph = React.useMemo(() => {
        return queryGraphRequest.data ? new StructuredGraph(queryGraphRequest.data) : undefined;
    }, [queryGraphRequest.data]);

    const genestackGraphGrid = React.useMemo(() => {
        return structuredGraph && graphType === GraphType.biokb
            ? new GenestackGraphGrid(structuredGraph)
            : undefined;
    }, [structuredGraph, graphType]);

    const hiddenNodesProps = useHiddenNodes({
        queryId: selectedQuery.id,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited: presentationEditedProps.handlePresentationEdited,
        selectedNodes: selectionProps.selectedNodes,
        setSelectedNodes: selectionProps.setSelectedNodes,
        structuredGraph
    });

    const layoutAndCameraProps = useLayoutAndCameraProps({
        queryId: selectedQuery.id,
        maxPathLength: Number(maxPathLength),
        isDefaultMPLength: graphSettings.isDefaultMPLength,
        selectedViewKind,
        isDefaultSelViewKind: graphSettings.isDefaultViewKind,
        nodes: queryGraphRequest.data?.nodes,
        edges: queryGraphRequest.data?.edges,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited: presentationEditedProps.handlePresentationEdited,
        isPresentationEdited: presentationEditedProps.isPresentationEdited,
        isHiddenNodesVisible: hiddenNodesProps.isHiddenNodesVisible,
        isHiddenNode: hiddenNodesProps.isHiddenNode,
        isHiddenEdge: hiddenNodesProps.isHiddenEdge
    });

    const hiddenNodesActions = useHiddenNodesActions({
        hiddenNodes: hiddenNodesProps.hiddenNodes,
        selectedNodes: selectionProps.selectedNodes,
        setSelectedNodes: selectionProps.setSelectedNodes,
        setHiddenNodes: hiddenNodesProps.setHiddenNodes,
        structuredGraph,
        isHiddenNodesVisible: hiddenNodesProps.isHiddenNodesVisible,
        toggleIsHiddenNodesVisible: hiddenNodesProps.toggleIsHiddenNodesVisible,
        visibleNodesPositions: layoutAndCameraProps.visibleNodesPositions,
        invisibleNodesPositions: layoutAndCameraProps.invisbleNodesPositions,
        setVisibleNodesPositions: layoutAndCameraProps.setVisibleNodesPositions,
        setInvisibleNodesPositions: layoutAndCameraProps.setInvisibleNodesPositions
    });

    // set unsaved changes func is bugged, see 'test set unsaved changes' test
    const {clearUnsavedChanges, setUnsavedChanges} = useUnsavedChangesActions({
        presentationEditedProps,
        graphSettings,
        layoutAndCameraProps,
        hiddenNodesProps,
        structuredGraph
    });

    const previousLoadedNodes = usePreviousDistinct(
        queryGraphRequest.data?.nodes,
        (prev, next) => !next
    );
    const previousLoadedNodesRef = React.useRef(previousLoadedNodes);
    previousLoadedNodesRef.current = previousLoadedNodes;
    // tanstack query doesn't seem to have an option to fire a callback on every query data change,
    // only on data loaded from server, so I have to use this hack here, otherwise
    // this code wouldn't run on data loaded from cache
    const onNodesLoaded = (nodes: GraphNode[]) => {
        selectionProps.onNodesLoaded(nodes);
        layoutAndCameraProps.onNodesLoaded(nodes, previousLoadedNodesRef.current);
    };
    const onNodesLoadedRef = React.useRef(onNodesLoaded);
    onNodesLoadedRef.current = onNodesLoaded;
    React.useEffect(() => {
        if (queryGraphRequest.data) {
            onNodesLoadedRef.current(queryGraphRequest.data.nodes);
        }
    }, [queryGraphRequest.data]);

    usePropagateValues({
        propagatedValuesProps,
        maxPathLength: graphSettings.maxPathLength
    });

    return {
        queryGraphRequest,
        structuredGraph,
        genestackGraphGrid,
        layoutAndCameraProps,
        graphSettings,
        canSelectViewKind: viewKindsOptions.includes(GraphViewKind.MERGED),
        presentationEditedProps,
        clearUnsavedChanges,
        setUnsavedChanges,
        hiddenNodesProps,
        hiddenNodesActions
    };
}
