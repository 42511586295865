import React from 'react';
import {GraphNode, GraphEdge} from '../interface';
import {QueryGraphLayoutAlgorithm} from './interface';
import {calculateLayout} from './calculate-layout';

interface Props {
    setIsBrowserUnsupported: (val: boolean) => void;
    layoutAlgorithm: QueryGraphLayoutAlgorithm;
    nodePositions: string;
    nodes?: GraphNode[];
    edges?: GraphEdge[];
    setNodePositions: (pos: string) => void;
    debug?: boolean;
    isHiddenNodesVisible?: boolean;
    isHiddenNode?: (nodeId: number) => boolean;
    isHiddenEdge?: (edgeId: number) => boolean;
}

/* This hook is mostly used to provide cancellation for previous layout calculation
 when the new calculation is starting */
export function useCalculateLayout({
    setNodePositions,
    setIsBrowserUnsupported,
    layoutAlgorithm,
    nodes,
    edges,
    nodePositions,
    isHiddenNodesVisible,
    isHiddenNode,
    isHiddenEdge
}: Props) {
    const [calculatedCounter, setCalculatedCounter] = React.useState(0);

    const calculateLayoutRef = React.useRef<() => ReturnType<typeof calculateLayout>>();
    calculateLayoutRef.current = () =>
        nodes && edges && !nodePositions
            ? calculateLayout({
                  layoutAlgorithm,
                  nodes: nodes.filter(
                      (node) => !isHiddenNode || isHiddenNodesVisible || !isHiddenNode(node.id)
                  ),
                  edges: edges.filter(
                      (edge) => !isHiddenEdge || isHiddenNodesVisible || !isHiddenEdge(edge.id)
                  ),
                  setNodePositions
              })
            : {terminateCalculation: () => null};

    React.useEffect(() => {
        if (!window.Worker) {
            setIsBrowserUnsupported(true);

            return;
        }

        return calculateLayoutRef.current!().terminateCalculation;
    }, [calculatedCounter, setIsBrowserUnsupported]);

    return React.useCallback(() => setCalculatedCounter((val) => val + 1), []);
}
