import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

import {useQueryListCache} from './update-query-list-cache';

const PAGE_LIMIT = 100;

function getDraftQueriesRequest() {
    return apiClient
        .post<PageResponse<DraftQuery, number>>({
            path: 'queries-service/api/queries/drafts',
            parameters: {limit: PAGE_LIMIT}
        })
        .then((res) => res.data);
}

export function useDraftQueries() {
    return useQuery('getDraftQueriesRequest', getDraftQueriesRequest);
}

export function useDraftQueriesCache() {
    return useQueryListCache<DraftQuery>('getDraftQueriesRequest');
}
