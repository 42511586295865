import * as d3 from 'd3';

import {QueryNodeType, StructuredGraphNode} from '../interface';

import {
    nodeEndPropsByStatus,
    nodeIntermediatePropsByStatus,
    nodeStartPropsByStatus
} from './graph-svg-defs';
import {GraphEntityStatus} from '../cytoscape-graph/interface';
import {getNodeSVGCoordinates} from './svg-coordinates-helpers';
import {isSelectMultipleMouseEvent} from '../../../utils/get-client-os';
import {GenestackGraphGrid} from './genestack-graph-grid';

const nodeHref = {
    [GraphEntityStatus.default]: {
        [QueryNodeType.INTERMEDIATE]: nodeIntermediatePropsByStatus[GraphEntityStatus.default].id,
        [QueryNodeType.START]: nodeStartPropsByStatus[GraphEntityStatus.default].id,
        [QueryNodeType.END]: nodeEndPropsByStatus[GraphEntityStatus.default].id
    },
    [GraphEntityStatus.selected]: {
        [QueryNodeType.INTERMEDIATE]: nodeIntermediatePropsByStatus[GraphEntityStatus.selected].id,
        [QueryNodeType.START]: nodeStartPropsByStatus[GraphEntityStatus.selected].id,
        [QueryNodeType.END]: nodeEndPropsByStatus[GraphEntityStatus.selected].id
    },
    [GraphEntityStatus.hovered]: {
        [QueryNodeType.INTERMEDIATE]: nodeIntermediatePropsByStatus[GraphEntityStatus.hovered].id,
        [QueryNodeType.START]: nodeStartPropsByStatus[GraphEntityStatus.hovered].id,
        [QueryNodeType.END]: nodeEndPropsByStatus[GraphEntityStatus.hovered].id
    }
};

interface Props {
    canvasElement: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
    genestackGraphGrid: GenestackGraphGrid;
    graphContainer: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>;
    selectedNodes: number[];
    displayNodeIds?: boolean;
    selectNodes: (nodeId: number[], isSelectMultiple: boolean) => void;
    hoveredNodes: number[];
    setHoveredNodes: (nodeIds: number[]) => void;
}

function getNodeStatus(nodeId: number, selectedNodes: number[], hoveredNodes: number[]) {
    if (selectedNodes.includes(nodeId)) {
        return GraphEntityStatus.selected;
    }

    if (hoveredNodes.includes(nodeId)) {
        return GraphEntityStatus.hovered;
    }

    return GraphEntityStatus.default;
}

/** Render nodes for a graph */
export function renderNodes(props: Props) {
    const {
        canvasElement,
        graphContainer,
        genestackGraphGrid,
        selectNodes,
        selectedNodes,
        displayNodeIds,
        hoveredNodes,
        setHoveredNodes
    } = props;

    const nodesElements = canvasElement
        .selectAll('.node')
        .data(genestackGraphGrid.structuredGraph.allNodes)
        .join('svg')
        .attr('class', 'node')
        .attr(
            'x',
            (data) => getNodeSVGCoordinates(genestackGraphGrid.nodesGridCoordinates[data.id]).x
        )
        .attr(
            'y',
            (data) => getNodeSVGCoordinates(genestackGraphGrid.nodesGridCoordinates[data.id]).y
        )
        .attr('overflow', 'auto')
        .style('cursor', 'pointer');

    nodesElements.each(function (node) {
        const currentNodeSelection = d3.select(this);
        const status = getNodeStatus(node.id, selectedNodes, hoveredNodes);

        currentNodeSelection
            .selectAll<SVGUseElement, StructuredGraphNode>('use')
            .data<StructuredGraphNode>(() => [node])
            .join('use')
            .attr('href', () => {
                return `#${nodeHref[status][node.nodeType]}`;
            });

        if (displayNodeIds) {
            currentNodeSelection.append('text').text(() => node.id);
        }

        if (status === GraphEntityStatus.default) {
            currentNodeSelection.on('mouseover', function () {
                setHoveredNodes([node.id]);
            });
        }

        if (status === GraphEntityStatus.hovered) {
            graphContainer.on('mousemove', (event: MouseEvent) => {
                const eventTargetIsNode =
                    (event.target as SVGUseElement).href?.baseVal ===
                    `#${nodeHref[GraphEntityStatus.hovered][node.nodeType]}`;

                if (!eventTargetIsNode) {
                    graphContainer.on('mousemove', null);
                    setHoveredNodes([]);
                }
            });

            currentNodeSelection.on('mousedown', (event) => {
                const isSelectMultiple = isSelectMultipleMouseEvent(event);

                graphContainer.on('mousemove', null);
                currentNodeSelection.on('mouseover', null);
                selectNodes([node.id], isSelectMultiple);
            });
        }

        if (status === GraphEntityStatus.selected) {
            currentNodeSelection.on('mousedown', (event) => {
                const isSelectMultiple = isSelectMultipleMouseEvent(event);

                selectNodes([node.id], isSelectMultiple);
            });
        }
    });
}
