import React from 'react';
import {useHistory} from 'react-router-dom';

import {showErrorNotification} from '../../../providers/notifications-center/notifications-store';
import {PageLayout} from '../../../components/layout/page-layout';
import {WindowTitle} from '../../../components/window-title';
import {useLocationParams} from '../../../hooks/use-location';
import {useDocRevisionsContext} from '../../../providers/version';
import {getDocPageTitle} from '../../../utils/get-doc-page-title';
import {DocRevisionsPanel} from '../common/version-history/doc-revisions-panel';

import {Editor} from './editor/editor';
import {useCreateDocumentMutation} from './hooks/use-create-document-mutation';
import {useDocMetadataQuery} from './hooks/use-doc-metadata-query';
import {EditModeDocumentsSidebar} from './sidebar';
import {usePageLayout} from '../../../components/layout/page-layout/use-page-layout';
import {useSidebarTabs} from '../../../hooks/use-sidebar-tabs';
import {EditModeTab} from '../../../components/layout/page-layout/layout-typings';
import {useSearchSidebarContext} from '../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useHandleFindUsages} from '../common/sidebar-search-field/hooks/use-handle-find-usages';
import {GlobalVersionData} from '../../../hooks/global-version/use-global-version-query';
import {EditModeActivityBar} from './activity-bar/activity-bar';

interface Props {
    globalVersions: GlobalVersionData[] | null;
}

export function EditView(props: Props) {
    const params = useLocationParams();
    const tabSettings = useSidebarTabs({
        layout: 'edit',
        activityTabStorageKey: 'documents.edit.lastTab',
        defaultActivityBarTab: EditModeTab.RECENT
    });
    const layoutSettings = usePageLayout({
        isHasRightPanel: params.mode === 'revisions',
        sidebarSizeStorageKey: 'documents.edit.layoutSize',
        sidebarClosedStorageKey: 'documents.edit.sidebarClosed'
    });
    const searchSidebarContext = useSearchSidebarContext({
        searchDataGlobalStateVar: 'documents.edit.searchData'
    });

    const findUsagesProps = useHandleFindUsages({
        tabSettings,
        searchSidebarContext,
        searchTab: EditModeTab.SEARCH,
        openSidebar: layoutSettings.openSidebar
    });

    const {replace, push} = useHistory();

    const versionModel = useDocRevisionsContext();
    const documentQuery = useDocMetadataQuery(params.documentId);
    const createDocument = useCreateDocumentMutation();

    const handleCreateDocument = async (type: DocType = 'BTB') => {
        try {
            const draftDocument = await createDocument.mutateAsync({type});

            push(`/documents/edit/${draftDocument.accession}`);
        } catch (error) {
            showErrorNotification('Error: Not possible to create a draft')(error);
        }
    };

    React.useEffect(() => {
        if (params.type) {
            handleCreateDocument(params.type);
            replace('/documents/edit');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.type]);

    const closePanelLink = `/documents/edit/${params.documentId}`;

    const getSelectedRevisionLink = React.useCallback(
        (revision: string) => `/documents/edit/${params.documentId}/revisions/${revision}`,
        [params.documentId]
    );

    const getDocumentLink = React.useCallback(
        (documentId: string, nodeId?: string) =>
            `/${documentId}${nodeId ? `/@${encodeURIComponent(nodeId)}` : ''}`,
        []
    );

    return (
        <WindowTitle
            text={getDocPageTitle(
                versionModel.selectedRevision?.documentName ?? documentQuery.data?.name
            )}
        >
            <PageLayout
                rightPanel={
                    <DocRevisionsPanel
                        docRevisionsProps={versionModel}
                        closePanelLink={closePanelLink}
                        getSelectedRevisionLink={getSelectedRevisionLink}
                        globalVersions={props.globalVersions}
                    />
                }
                sidebar={
                    <EditModeDocumentsSidebar
                        tabSettings={tabSettings}
                        searchSidebarContext={searchSidebarContext}
                        findUsagesProps={findUsagesProps}
                        revisionId={params.revisionId}
                        getDocumentLink={getDocumentLink}
                        layoutSettings={layoutSettings}
                    />
                }
                layoutSettings={layoutSettings}
                activityBar={
                    <EditModeActivityBar
                        isDocCreating={createDocument.isLoading}
                        createNew={handleCreateDocument}
                        tabSettings={tabSettings}
                        layoutSettings={layoutSettings}
                    />
                }
            >
                {params.documentId ? (
                    <Editor accession={params.documentId} findUsagesProps={findUsagesProps} />
                ) : null}
            </PageLayout>
        </WindowTitle>
    );
}
