import {StructuredGraph} from './structured-graph';
import {StructuredGraphNode} from '../../interface';

interface SccEdges {
    incomingEdges: number[];
    outgoingEdges: number[];
}

export interface GraphSccsResult {
    sccByNodeMap: {[nodeId: number]: number};
    edgesBySccMap: {[sccId: number]: SccEdges};
}

// Strongly Connected Component is a set of nodes in the graph where
// you can get from every node in SCC to any other node in this SCC.
// Finding SCCs can be useful if you want to turn you cyclic graph to an acyclic one.
// Here, I use Tarjan's algorithm.
export function findGraphSccs(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    structuredGraph: StructuredGraph,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nodesToIgnore?: number[]
): GraphSccsResult {
    const discoveryTime: {[nodeId: number]: number} = {};
    // lowLink - earliest visited vertex (the vertex with minimum discovery time) that can be
    // reached from subtree rooted with current vertex
    const lowLink: {[nodeId: number]: number} = {};
    const nodesStack: number[] = [];
    let currentTime = 0;
    let sccId = 0;
    const sccByNodeMap: GraphSccsResult['sccByNodeMap'] = {};
    const edgesBySccMap: GraphSccsResult['edgesBySccMap'] = {};

    function findNodeSccRecursive(node: StructuredGraphNode) {
        discoveryTime[node.id] = currentTime;
        lowLink[node.id] = currentTime;
        currentTime++;
        nodesStack.push(node.id);

        const nextNodes = node.outgoingEdges
            .map((edge) => edge.targetNode)
            .filter((n) => !nodesToIgnore?.includes(n.id));

        nextNodes.forEach((nextNode) => {
            if (!discoveryTime[nextNode.id]) {
                findNodeSccRecursive(nextNode);
                lowLink[node.id] = Math.min(lowLink[node.id], lowLink[nextNode.id]);
            } else if (nodesStack.includes(nextNode.id)) {
                lowLink[node.id] = Math.min(lowLink[node.id], discoveryTime[nextNode.id]);
            }
        });

        const isSccHead = lowLink[node.id] === discoveryTime[node.id];
        if (isSccHead) {
            const sccNodes: number[] = [];

            while (!sccNodes.includes(node.id)) {
                const sccNodeId = nodesStack.pop()!;
                sccNodes.push(sccNodeId);
            }

            // if there is more than 1 node in this scc, add it to result
            if (sccNodes.length > 1) {
                sccId--;
                edgesBySccMap[sccId] = {outgoingEdges: [], incomingEdges: []};
                sccNodes.forEach((nodeId) => {
                    sccByNodeMap[nodeId] = sccId;
                    const sccNode = structuredGraph.getNode(nodeId);

                    sccNode.outgoingEdges.forEach((edge) => {
                        if (
                            !sccNodes.includes(edge.targetNode.id) &&
                            !nodesToIgnore?.includes(edge.targetNode.id)
                        ) {
                            edgesBySccMap[sccId].outgoingEdges.push(edge.id);
                        }
                    });

                    sccNode.incomingEdges.forEach((edge) => {
                        if (
                            !sccNodes.includes(edge.sourceNode.id) &&
                            !nodesToIgnore?.includes(edge.sourceNode.id)
                        ) {
                            edgesBySccMap[sccId].incomingEdges.push(edge.id);
                        }
                    });
                });
            }
        }
    }

    structuredGraph.allNodes.forEach((node) => {
        if (!discoveryTime[node.id] && !nodesToIgnore?.includes(node.id)) {
            findNodeSccRecursive(node);
        }
    });

    return {
        sccByNodeMap,
        edgesBySccMap
    };
}
