import React from 'react';
import {Statistics} from './statistics/statistics';
import {ResultsList} from './results-list/results-list';
import {StartEndNodesPanel} from './start-end-nodes/start-end-nodes-panel';
import {GraphViewKind} from '../../../../../components/graph/interface';
import {useResultsListProps} from '../result-chains-panel/use-results-list-props';
import {useGlobalStateVariable} from '../../../../../providers/global-state';
import {QueryRightPanelTab} from '../../../../../components/layout/page-layout/layout-typings';
import {Sidebar} from '../../../../../components/layout/sidebar';

interface Props {
    queryId: number;
    viewKind: GraphViewKind;
    resultsListProps: ReturnType<typeof useResultsListProps>;
    maxPathLength: number;
    closePanel: () => void;
    selectedGraphNodes: number[];
    selectGraphNodes: (nodes: number[], isSelectMultiple: boolean) => void;
    setHoveredNodes: (nodeIds: number[]) => void;
    invisibleNodes: number[];
}

export function RightPanel(props: Props) {
    const [selectedTab] = useGlobalStateVariable(
        'queries.rightPanel.selectedTab',
        QueryRightPanelTab.STATISTICS
    );

    const content = (function () {
        if (selectedTab === QueryRightPanelTab.RESULTS_LIST) {
            return (
                <ResultsList
                    closePanel={props.closePanel}
                    resultsListProps={props.resultsListProps}
                />
            );
        }

        if (selectedTab === QueryRightPanelTab.START_END_NODES) {
            return (
                <StartEndNodesPanel
                    closePanel={props.closePanel}
                    queryId={props.queryId}
                    viewKind={props.viewKind}
                    maxPathLength={props.maxPathLength}
                    selectedGraphNodes={props.selectedGraphNodes}
                    setHoveredNodes={props.setHoveredNodes}
                    selectGraphNodes={props.selectGraphNodes}
                    invisibleNodes={props.invisibleNodes}
                />
            );
        }

        return (
            <Statistics
                queryId={props.queryId}
                viewKind={props.viewKind}
                maxPathLength={props.maxPathLength}
                closePanel={props.closePanel}
                selectedGraphNodes={props.selectedGraphNodes}
                selectGraphNodes={props.selectGraphNodes}
                setHoveredNodes={props.setHoveredNodes}
                invisibleNodes={props.invisibleNodes}
            />
        );
    })();

    return <Sidebar border="left">{content}</Sidebar>;
}
