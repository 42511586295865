import React from 'react';
import {ListItem, PageContent, Preloader, ScrollView, Typography} from '@genestack/ui';
import {QueryResultConnectionItem} from './query-result-connection-item';
import styles from './results-list.module.css';
import {useResultsListProps} from '../../result-chains-panel/use-results-list-props';
import {EmptyList} from '../../../../../../components/empty-list/empty-list';
import {PaginatedList} from '../../../../../../components/pagination/paginated-list';
import {QUERY_RESULTS_LIST_PAGE_SIZE} from '../../result-chains-panel/use-query-results-list';
import {SidebarHeader} from '../../../../../../components/layout/sidebar/sidebar-header/sidebar-header';

interface Props {
    closePanel: () => void;
    resultsListProps: ReturnType<typeof useResultsListProps>;
}

export function ResultsList({closePanel, resultsListProps}: Props) {
    const renderPathEntry = React.useCallback(
        (resultItem: ConnectionDto, index: number) => {
            const element = (
                <QueryResultConnectionItem
                    onSelect={() => {
                        resultsListProps.setSelectedResultItem(resultItem.id);
                    }}
                    connection={resultItem}
                    index={index + 1}
                    active={resultsListProps.selectedResultItem === resultItem.id}
                />
            );

            return [resultItem.id, element] as [React.Key, React.ReactElement];
        },
        [resultsListProps]
    );

    return (
        <React.Fragment>
            <SidebarHeader title="Results as list" onClose={closePanel} />

            {(function () {
                if (!resultsListProps.queryResultsListItems) {
                    return (
                        <PageContent>
                            <Preloader show count={5} box="paragraph" />
                        </PageContent>
                    );
                }

                if (!resultsListProps.queryResultsListItems.length) {
                    return <EmptyList message="Nothing found" />;
                }

                return (
                    <React.Fragment>
                        <ListItem>
                            <div className={styles.resultsListRow}>
                                <Typography intent="quiet">#</Typography>

                                <Typography intent="quiet">Nodes</Typography>

                                <Typography intent="quiet">Score</Typography>
                            </div>
                        </ListItem>

                        <ScrollView>
                            <PaginatedList
                                mode="manual"
                                pageSize={QUERY_RESULTS_LIST_PAGE_SIZE}
                                items={resultsListProps.queryResultsListItems}
                                isLoadingNextPage={
                                    resultsListProps.queryResultsList.isFetchingNextPage
                                }
                                hasNextPage={resultsListProps.queryResultsList.hasNextPage}
                                renderItem={renderPathEntry}
                                onLoadNext={resultsListProps.queryResultsList.fetchNextPage}
                            />
                        </ScrollView>
                    </React.Fragment>
                );
            })()}
        </React.Fragment>
    );
}
