import {
    Button,
    chain,
    MenuIcon,
    PlusIcon,
    SearchIcon,
    TimeReverseIcon,
    TrashIcon,
    WorkingCopyIcon
} from '@genestack/ui';
import React from 'react';

import {LayoutMenu} from './layout-menu';
import {EditModeTab} from '../../../../components/layout/page-layout/layout-typings';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';
import {usePageLayout} from '../../../../components/layout/page-layout/use-page-layout';
import {
    VerticalBar,
    VerticalBarItem
} from '../../../../components/layout/vertical-bar/vertical-bar';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Search',
        value: EditModeTab.SEARCH,
        prepend: <SearchIcon />
    },
    {
        tooltip: 'Show recent document',
        value: EditModeTab.RECENT,
        prepend: <TimeReverseIcon />
    },
    {
        tooltip: 'To be published',
        value: EditModeTab.UNPUBLISHED,
        prepend: <WorkingCopyIcon />
    },
    {
        tooltip: 'Browse documents',
        value: EditModeTab.DOCUMENTS,
        prepend: <MenuIcon />
    },
    {
        tooltip: 'Recycle bin',
        value: EditModeTab.BIN,
        prepend: <TrashIcon />,
        stickToBottom: true
    }
];

interface Props {
    createNew: (docType: DocType) => void;
    isDocCreating: boolean;
    tabSettings: ReturnType<typeof useSidebarTabs>;
    layoutSettings: ReturnType<typeof usePageLayout>;
}

export function EditModeActivityBar({
    createNew,
    isDocCreating,
    tabSettings,
    layoutSettings
}: Props) {
    const handleCreateNewDocument = () => {
        createNew('BTB');
    };

    return (
        <VerticalBar
            topControls={
                <React.Fragment>
                    <Button
                        data-qa="add-new-btb-document"
                        icon={<PlusIcon />}
                        onClick={handleCreateNewDocument}
                        disabled={isDocCreating}
                    />

                    <LayoutMenu tabSettings={tabSettings} createNew={createNew} />
                </React.Fragment>
            }
            tabs={SIDEBAR_TABS}
            value={layoutSettings.isSidebarClosed ? undefined : tabSettings.activityBarTab}
            onValueChange={chain(tabSettings.setActivityBarTab, layoutSettings.openSidebar)}
        />
    );
}
