import {Divider} from '@genestack/ui';
import React from 'react';

import {LocalErrorBoundary} from '../../../../components/error-boundary';
import {Sidebar} from '../../../../components/layout/sidebar';
import {useLocationParams} from '../../../../hooks/use-location';

import {CategoryResults, DocResults, NodeResults, SearchHistory} from './search-results';
import {useSearchSidebarContext} from '../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {SidebarSearchField} from '../../common/sidebar-search-field';
import {LayoutContextValue} from '../../../../components/layout/page-layout/use-page-layout';
import {useAddRecordToExploreViewSearchHistoryMutation} from '../hooks/requests/use-add-record-to-explore-view-search-history-mutation';
import {useSuggestions} from '../../common/sidebar-search-field/hooks/use-suggestions';
import {useExploreViewSearchHistoryQuery} from '../hooks/requests/use-explore-view-search-history-query';
import {SearchData} from '../../common/sidebar-search-field/interface';
import {SearchResultComponentProps} from './search-results/interface';
import {useCategoriesFilters} from '../hooks/use-categories-filters';
import {useSearchSidebarLoadingState} from '../hooks/use-search-sidebar-loading-state';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';
import {ExploreDocumentsSidebarProps} from './interface';

interface Props extends ExploreDocumentsSidebarProps {
    layoutSettings: LayoutContextValue;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
}

export const SearchDocumentsSidebar = (props: Props) => {
    const {
        layoutSettings,
        searchSidebarContext,
        findUsagesProps,
        selectedGlobalVersion,
        getDocumentLink,
        footer,
        closeSidebar
    } = props;
    const {revisionId} = useLocationParams();
    const addItemToSearchHistoryMutation = useAddRecordToExploreViewSearchHistoryMutation();
    const {isExpandedSidebar, toggleIsExpandedSidebar} = layoutSettings;
    const {loadingState, setLoadingState} = useSearchSidebarLoadingState();

    const categoriesFilters = useCategoriesFilters();

    const searchHistory = useExploreViewSearchHistoryQuery({
        enabled: searchSidebarContext.parsedInputValue.type === 'emptyInput'
    });

    const suggestionsProps = useSuggestions({
        currentGlobalVersion: selectedGlobalVersion,
        inputValue: searchSidebarContext.parsedInputValue,
        searchHistory: searchHistory.data
    });

    const onSearchFieldBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        if (
            searchSidebarContext.parsedInputValue.type === 'searchByDocName' &&
            searchSidebarContext.parsedInputValue.value &&
            e.target instanceof HTMLInputElement
        ) {
            addItemToSearchHistoryMutation.mutate({
                categoriesSearch: null,
                referencedNode: null,
                stringSearch: {strict: false, value: searchSidebarContext.parsedInputValue.value}
            });
        }
    };

    const header = (
        <SidebarHeader title="Search" onClose={closeSidebar}>
            <Divider variant="transparent" gap={1} />
            <SidebarSearchField
                searchSidebarContext={searchSidebarContext}
                disabled={
                    Boolean(revisionId) ||
                    loadingState.isLoadingNextPage ||
                    findUsagesProps.loadingNodeType
                }
                busy={
                    suggestionsProps.isLoading ||
                    searchHistory.isLoading ||
                    findUsagesProps.loadingNodeType
                }
                onBlur={onSearchFieldBlur}
                suggestionsProps={suggestionsProps}
            />
        </SidebarHeader>
    );

    return (
        <Sidebar component="div" header={header} footer={footer} border="right">
            <LocalErrorBoundary>
                {(function () {
                    const resultsProps: Omit<
                        SearchResultComponentProps<SearchData>,
                        'searchData'
                    > = {
                        versionId: selectedGlobalVersion.id,
                        expanded: isExpandedSidebar,
                        onToggleExpand: toggleIsExpandedSidebar,
                        categoriesFilters,
                        loadingState,
                        setLoadingState,
                        selectedGlobalVersion,
                        getDocumentLink
                    };

                    if (!searchSidebarContext.searchData) {
                        return <SearchHistory search={searchSidebarContext.search} />;
                    }

                    if (searchSidebarContext.searchData.mode === 'doc') {
                        return (
                            <DocResults
                                {...resultsProps}
                                searchData={searchSidebarContext.searchData}
                            />
                        );
                    }

                    if (searchSidebarContext.searchData.mode === 'node') {
                        return (
                            <NodeResults
                                {...resultsProps}
                                searchData={searchSidebarContext.searchData}
                            />
                        );
                    }

                    if (searchSidebarContext.searchData.mode === 'category') {
                        return (
                            <CategoryResults
                                {...resultsProps}
                                searchData={searchSidebarContext.searchData}
                            />
                        );
                    }

                    return null;
                })()}
            </LocalErrorBoundary>
        </Sidebar>
    );
};
