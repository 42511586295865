import {useHistory} from 'react-router-dom';

import {EditModeTab, QueriesTab, ViewModeTab} from './layout-typings';
import type {ActivityBarTabs, LayoutType} from './layout-typings';

function layoutHasTab(layout: LayoutType, tab: string): tab is ActivityBarTabs {
    const availableTabList = (function () {
        if (layout === 'explore') {
            return ViewModeTab;
        }

        if (layout === 'edit') {
            return EditModeTab;
        }

        return QueriesTab;
    })();

    return Object.values(availableTabList).includes(tab);
}

/** Encapsulates functionality which redirects user to page with tabs
 * (document view, document edit or queries) with preselected tab */
export function usePreselectedTab() {
    const {location, replace} = useHistory();

    function getPreselectedTabURLParam(tabName: ActivityBarTabs) {
        return `tab=${tabName}`;
    }

    function getPreselectedTabFromUrl(layout: LayoutType) {
        const tab = new URLSearchParams(location.search).get('tab');
        if (!tab || !layoutHasTab(layout, tab)) {
            return null;
        }

        return tab;
    }

    function clearPreselectedTab() {
        const params = new URLSearchParams(location.search);
        params.delete('tab');
        replace(`${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`);
    }

    return {getPreselectedTabURLParam, getPreselectedTabFromUrl, clearPreselectedTab};
}
