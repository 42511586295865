import {useInfiniteQuery} from 'react-query';
import {OntologyTreeAdditionalEl, OntologyTreeElementId} from './interface';
import {apiClient} from '../../../../../utils/api-client';

interface Props {
    parentId: OntologyTreeElementId;
    ignoreElementIds: OntologyTreeElementId[];
    elementsNum: number;
}

export type AdditionalChildrenPageResponse = PageResponse<
    OntologyTreeAdditionalEl,
    OntologyTreeElementId
>;

const PAGE_SIZE = 10;

export function useAdditionalChildrenQuery(props: Props) {
    return useInfiniteQuery<AdditionalChildrenPageResponse>({
        queryKey: ['ontology-tree-additional-children', props.parentId, props.ignoreElementIds],
        queryFn: ({
            pageParam = {
                cursor: null,
                limit: props.elementsNum > PAGE_SIZE ? PAGE_SIZE : props.elementsNum
            }
        }) =>
            apiClient.post({
                path: 'editor-documents-service/api/trees/children',
                parameters: {
                    parentId: props.parentId,
                    limit: pageParam.limit,
                    cursor: pageParam.cursor,
                    ignoreElementIds: props.ignoreElementIds
                }
            }),
        getNextPageParam: ({remaining, nextCursor}) => {
            const hasNextPage = remaining > 0;

            if (hasNextPage) {
                return {
                    cursor: nextCursor,
                    limit: remaining > PAGE_SIZE ? PAGE_SIZE : remaining
                };
            }
        },
        keepPreviousData: true,
        enabled: false
    });
}
