import {PageContent, DividerProps} from '@genestack/ui';
import * as React from 'react';

import {LocalBackdrop} from '../../components/local-backdrop';
import {ProfileSettings} from './profile-settings';
import {WindowTitle} from '../../components/window-title';

import styles from './profile-page.module.css';

const PAGE_DIVIDER_GAPS: DividerProps = {startGap: 5, endGap: 5};

/** Page for managing user's profile */
export function ProfilePage() {
    return (
        <WindowTitle text="Profile">
            <LocalBackdrop open variant="zerostate" className={styles.backdrop} />

            <PageContent startDividerProps={PAGE_DIVIDER_GAPS} endDividerProps={PAGE_DIVIDER_GAPS}>
                <ProfileSettings />
            </PageContent>
        </WindowTitle>
    );
}
