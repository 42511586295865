import React from 'react';
import {LS_PREFIX} from '../../../providers/global-state';
import {isQueryCompleted} from '../utils';
import {useLsFlag} from '../../../hooks/use-ls-flag';

interface Props {
    selectedQuery?: QueryInfo;
    selectedNodes: number[];
}

export function useQueryLayoutProps({selectedQuery, selectedNodes}: Props) {
    const [isBodyCollapsed, setIsBodyCollapsed] = useLsFlag(
        `${LS_PREFIX}.queryLayout.${selectedQuery?.id}.queryBodyCollapsed`,
        false
    );

    const [isQueryRightPanelOpen, setIsQueryRightPanelOpen] = useLsFlag(
        `${LS_PREFIX}.queryLayout.${selectedQuery?.id}.isQueryRightPanelOpen`,
        false
    );

    const [isFactsetCollapsed, setIsFactsetCollapsed] = React.useState(false);

    const toggleIsBodyCollapsed = () => setIsBodyCollapsed(!isBodyCollapsed);

    // mb rename collapsed to minimized in this case?
    const toggleIsFactsetCollapsed = React.useCallback(
        () => setIsFactsetCollapsed(!isFactsetCollapsed),
        [isFactsetCollapsed]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const openQueryRightPanel = React.useCallback(() => setIsQueryRightPanelOpen(true), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closeQueryRightPanel = React.useCallback(() => setIsQueryRightPanelOpen(false), []);

    React.useEffect(() => {
        if (!selectedNodes.length) {
            setIsFactsetCollapsed(false);
        }
    }, [selectedNodes]);

    return {
        isBodyCollapsed: !!isBodyCollapsed,
        toggleIsBodyCollapsed,
        isQueryRightPanelOpen:
            selectedQuery && isQueryCompleted(selectedQuery) && isQueryRightPanelOpen,
        openQueryRightPanel,
        closeQueryRightPanel,
        isFactsetCollapsed,
        toggleIsFactsetCollapsed
    };
}
