import React from 'react';

import {DeletedDocuments} from './deleted-documents';
import {CategoryTree} from './ontology-layout';
import {RecentDocuments} from './recent-documents';
import {SearchLayout} from './search-layout';
import {UnpublishedDocuments} from './unpublished-documents';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';
import {useSearchSidebarContext} from '../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {usePageLayout} from '../../../../components/layout/page-layout/use-page-layout';

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
    layoutSettings: ReturnType<typeof usePageLayout>;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    revisionId?: string;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export const EditModeDocumentsSidebar = (props: Props) => {
    switch (props.tabSettings.activityBarTab) {
        // eslint-disable-next-line default-case-last
        default:
        case 'recent':
            return <RecentDocuments closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'search':
            return (
                <SearchLayout
                    searchSidebarContext={props.searchSidebarContext}
                    findUsagesProps={props.findUsagesProps}
                    revisionId={props.revisionId}
                    getDocumentLink={props.getDocumentLink}
                    closeSidebar={props.layoutSettings.closeSidebar}
                />
            );
        case 'unpublished':
            return <UnpublishedDocuments closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'documents':
            return <CategoryTree closeSidebar={props.layoutSettings.closeSidebar} />;
        case 'bin':
            return <DeletedDocuments closeSidebar={props.layoutSettings.closeSidebar} />;
    }
};
