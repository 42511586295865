import React from 'react';
import {
    Button,
    Divider,
    EyeClosedIcon,
    EyeEmptyIcon,
    FocusIcon,
    Menu,
    MenuHandler,
    MenuItem,
    MinusCircledIcon,
    PlusCircledIcon
} from '@genestack/ui';
import style from './control-panel.module.css';
import {useHideNodes} from './use-hide-nodes';

interface Props {
    focusGraph: () => void;
    hideNodesProps?: ReturnType<typeof useHideNodes>;
}

export function ControlPanel(props: Props) {
    const hideNodesMenu = !props.hideNodesProps ? null : (
        <Menu
            onValueSelect={(val) => {
                if (val === 'hideOnlyNodes') {
                    props.hideNodesProps!.hideSelectedNodes();
                }

                if (val === 'hideNodesAndDeadEnds') {
                    props.hideNodesProps!.hideNodesAndDeadEnds();
                }

                if (val === 'hideAllNodes') {
                    props.hideNodesProps!.hideAllNodes();
                }
            }}
        >
            <MenuItem
                key="hideNodesAndPaths"
                value="hideNodesAndDeadEnds"
                disabled={!props.hideNodesProps.isCanHideSelectedNodes}
            >
                Hide selected and dead-end nodes
            </MenuItem>
            <MenuItem
                key="hideOnlyNodes"
                value="hideOnlyNodes"
                disabled={!props.hideNodesProps.isCanHideSelectedNodes}
            >
                Hide selected nodes only
            </MenuItem>
            <MenuItem key="hideAllNodes" value="hideAllNodes">
                Hide all nodes
            </MenuItem>
        </Menu>
    );

    const unhideNodesMenu = !props.hideNodesProps ? null : (
        <Menu
            onValueSelect={(val) => {
                if (val === 'unhideSelectedNodes') {
                    props.hideNodesProps!.unhideSelectedNodes();
                }

                if (val === 'unhideAllNodes') {
                    props.hideNodesProps!.unhideAllNodes();
                }
            }}
        >
            <MenuItem
                key="unhideSelectedNodes"
                value="unhideSelectedNodes"
                disabled={!props.hideNodesProps.hasHiddenSelectedNodes}
            >
                Return selected nodes only
            </MenuItem>
            <MenuItem key="unhideAllNodes" value="unhideAllNodes">
                Return all nodes
            </MenuItem>
        </Menu>
    );

    return (
        <div className={style.controlPanel}>
            <Button ghost size="small" iconStart={<FocusIcon />} onClick={props.focusGraph} />
            {props.hideNodesProps ? (
                <React.Fragment>
                    <Divider />
                    <MenuHandler menu={hideNodesMenu!}>
                        <Button
                            ghost
                            size="small"
                            iconStart={<MinusCircledIcon />}
                            hasSubmenuIndicator
                            disabled={props.hideNodesProps.isAllNodesHidden}
                        />
                    </MenuHandler>

                    <MenuHandler menu={unhideNodesMenu!}>
                        <Button
                            ghost
                            size="small"
                            iconStart={<PlusCircledIcon />}
                            hasSubmenuIndicator
                            disabled={!props.hideNodesProps.hasHiddenNodes}
                        />
                    </MenuHandler>

                    <Divider />
                    <Button
                        ghost
                        size="small"
                        iconStart={
                            props.hideNodesProps.isHiddenNodesVisible ? (
                                <EyeEmptyIcon />
                            ) : (
                                <EyeClosedIcon />
                            )
                        }
                        active={props.hideNodesProps.isHiddenNodesVisible}
                        disabled={!props.hideNodesProps.hasHiddenNodes}
                        onClick={props.hideNodesProps.toggleIsHiddenNodesVisible}
                    />
                </React.Fragment>
            ) : null}
        </div>
    );
}
