import {Notification, Typography} from '@genestack/ui';
import React from 'react';

import {showNotification} from '../notifications-center';
import {useGlobalVersionsQuery} from '../../hooks/global-version/use-global-version-query';
import {useUrlSearchValue, UrlSearchParam} from '../../hooks/use-search-params';
import {useGlobalStateVariable} from '../global-state';

interface Props {
    shouldRefetchGVFrequently: boolean;
}

export function useGlobalVersion(props: Props) {
    const {value: globalVersionFromUrl, setValue: setGlobalVersionFromUrl} = useUrlSearchValue(
        UrlSearchParam.GV
    );

    const [globalVersionFromLS, setGlobalVersionToLS] = useGlobalStateVariable(
        'global.version',
        null
    );

    React.useEffect(() => {
        if (globalVersionFromUrl && Number(globalVersionFromUrl) !== globalVersionFromLS) {
            setGlobalVersionToLS(Number(globalVersionFromUrl));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalVersionFromLS, globalVersionFromUrl]);

    const globalVersionsQuery = useGlobalVersionsQuery({
        shouldRefetchFrequently: props.shouldRefetchGVFrequently
    });

    const currentGlobalVersionData = (function () {
        if (!globalVersionsQuery.data) {
            return undefined;
        }

        if (globalVersionFromUrl) {
            return globalVersionsQuery.data.find(({id}) => id === Number(globalVersionFromUrl));
        }

        if (globalVersionFromLS) {
            return globalVersionsQuery.data.find(({id}) => id === globalVersionFromLS);
        }

        return globalVersionsQuery.data[0];
    })();

    React.useEffect(() => {
        if (!globalVersionsQuery.data || !globalVersionsQuery.data[0]) {
            return undefined;
        }

        if (globalVersionFromUrl && !currentGlobalVersionData) {
            setGlobalVersionFromUrl(String(globalVersionsQuery.data[0].id));

            showNotification(
                <Notification>
                    <Typography>URL is invalid, the last Global Version is shown</Typography>
                </Notification>
            );
        }

        if (globalVersionFromLS && !currentGlobalVersionData) {
            setGlobalVersionToLS(globalVersionsQuery.data[0].id);

            showNotification(
                <Notification>
                    <Typography>
                        LocalStorage value is invalid, the last Global Version is shown
                    </Typography>
                </Notification>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        globalVersionsQuery.data,
        currentGlobalVersionData,
        globalVersionFromLS,
        globalVersionFromUrl
    ]);

    return {
        globalVersions: globalVersionsQuery.data || null,
        versionsLoading: globalVersionsQuery.isLoading,
        activeGlobalVersionId: Number(globalVersionFromUrl) || globalVersionFromLS,
        currentGlobalVersionData
    };
}
