import {WithClasses, mergeClassesProps, OverridableProps, chainRefs} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './resizable-layout.module.css';
import {useFlexibleLayout} from '../../page-layout/flexible-layout';
import {ResizableLayoutContextProps} from '../resizable-panel';
import {FlexibleLayoutSettings} from '../../page-layout/flexible-layout/interface';

interface Props extends WithClasses<keyof typeof styles> {
    localStorageKey:
        | 'queries.results.chain.layoutSize'
        | 'users.manager.layoutSize'
        | 'queries.rightPanel.layoutSize'
        | 'documents.explore.neighborhoodGraph.layoutSize'
        | 'documents.explore.neighborhoodGraph.factsetPanel.layoutSize'
        | 'documents.edit.ontologySidebar.layoutSize';
    alignment: 'horizontal' | 'vertical';
    settings?: Partial<FlexibleLayoutSettings>;
    children: (props: ResizableLayoutContextProps) => React.ReactElement;
    fixedPanelSize?: {panelIndex: 0 | 1; size: number};
    // disables user resizing and forces second panel to have fixed size in px
    // secondPanelFixedSize?: number;
}

interface TypeMap {
    props: Props;
    defaultType: 'div';
}

type ResizableLayoutProps = OverridableProps<TypeMap>;

export const CustomResizableLayout = React.forwardRef<HTMLDivElement, ResizableLayoutProps>(
    function BaseResizableLayoutComponent(props, ref) {
        const {
            component: Component = 'div',
            classes,
            settings,
            fixedPanelSize,
            children,
            ...restProps
        } = mergeClassesProps(props, styles);

        const flexiblePanel = useFlexibleLayout<HTMLDivElement, HTMLDivElement>(
            props.localStorageKey,
            settings,
            restProps.alignment === 'vertical'
        );

        return (
            <Component
                {...restProps}
                className={classNames(classes.root, restProps.className, {
                    [styles.horizontal]: restProps.alignment === 'horizontal',
                    [styles.vertical]: restProps.alignment === 'vertical'
                })}
                ref={chainRefs(flexiblePanel.wrapperRef, ref)}
            >
                {children({
                    isDragging: flexiblePanel.isDragging,
                    separatorRef: flexiblePanel.separatorRef,
                    firstPanelShare: flexiblePanel.firstPanelShare,
                    panelsAlignment: restProps.alignment,
                    minWidth: flexiblePanel.minPermittedWidth,
                    minHeight: flexiblePanel.minPermittedHeight,
                    fixedPanelSize
                })}
            </Component>
        );
    }
);
