import React from 'react';

import {Preloader} from '../../../components/preloader/preloader';
import {Sidebar} from '../../../components/layout/sidebar';
import {useFavoriteQueries} from './hooks/use-favorite-queries';
import {useQueryId} from '../../../hooks/use-location';

import {QueryInfoItem} from './query-info-item';
import {QueriesSidebarProps} from './interface';
import {SidebarHeader} from '../../../components/layout/sidebar/sidebar-header/sidebar-header';
import {numericSortComparator} from '../../../utils/array';
import {EmptyList} from '../../../components/empty-list/empty-list';

export function FavoriteQueriesSidebar(props: QueriesSidebarProps) {
    const favoriteQueries = useFavoriteQueries();
    const queryId = useQueryId();

    const favoriteQueriesSorted = React.useMemo(
        () => favoriteQueries.data?.sort(numericSortComparator('createdAt')),
        [favoriteQueries.data]
    );

    return (
        <Sidebar
            header={<SidebarHeader onClose={props.closeSidebar} title="Bookmarked queries" />}
            border="right"
        >
            <Preloader show={favoriteQueries.isLoading}>
                {favoriteQueriesSorted && (
                    <>
                        {favoriteQueriesSorted.length === 0 && (
                            <EmptyList message="No queries found" />
                        )}

                        {favoriteQueriesSorted.map((queryInfo) => (
                            <QueryInfoItem
                                key={queryInfo.id}
                                queryInfo={queryInfo}
                                isSelected={queryInfo.id === queryId}
                                isSharedIcon={queryInfo.shared && !queryInfo.sharedWithMe}
                                isNotMyQueryIcon={queryInfo.shared && queryInfo.sharedWithMe}
                                showOwnerName={queryInfo.shared && queryInfo.sharedWithMe}
                            />
                        ))}
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
}
