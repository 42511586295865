import {Button, Menu, MenuItem, MoreIcon, useMenuHandler} from '@genestack/ui';
import React from 'react';

interface Props extends React.ComponentPropsWithoutRef<typeof Button> {
    isLoading: boolean;
    onEmptyTrashBin: () => void;
}

export function TrashBinMenu({isLoading, onEmptyTrashBin, ...restProps}: Props) {
    const referenceElement = React.useRef<HTMLButtonElement>(null);
    const menu = useMenuHandler({referenceElement: referenceElement.current});

    return (
        <>
            <Button
                ghost
                icon={<MoreIcon />}
                size="small"
                data-qa="bin-menu-button"
                {...menu.getReferenceProps<React.ComponentPropsWithRef<typeof Button>>({
                    ref: referenceElement,
                    active: menu.isOpen
                })}
                {...restProps}
            />

            <Menu {...menu.getMenuProps()}>
                <MenuItem
                    data-qa="empty-bin-menu-item"
                    onClick={onEmptyTrashBin}
                    disabled={isLoading}
                >
                    {isLoading ? 'Deleting...' : 'Empty recycle bin'}
                </MenuItem>
            </Menu>
        </>
    );
}
