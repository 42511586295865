import React from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';

export function useLsFlag(
    key: string,
    initialValue: boolean
): [value: boolean, setValue: (v: boolean) => void] {
    const [lsValue, setLsValue, clearLsValue] = useLocalStorage<boolean | undefined>(
        key,
        undefined
    );

    const setFlag = React.useCallback(
        (value: boolean) => {
            if (value === initialValue) {
                clearLsValue();
            } else {
                setLsValue(value);
            }
        },
        [initialValue, setLsValue, clearLsValue]
    );

    return [lsValue === undefined ? initialValue : lsValue, setFlag];
}
