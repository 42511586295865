import React from 'react';

import {Preloader} from '../../../components/preloader/preloader';
import {Sidebar} from '../../../components/layout/sidebar';
import {useRunningQueries} from './hooks/use-recent-queries';
import {useQueryId} from '../../../hooks/use-location';

import {QueryInfoItem} from './query-info-item';
import {QueriesSidebarProps} from './interface';
import {SidebarHeader} from '../../../components/layout/sidebar/sidebar-header/sidebar-header';
import {EmptyList} from '../../../components/empty-list/empty-list';

export function RunningQueriesSidebar(props: QueriesSidebarProps) {
    const runningQueries = useRunningQueries();
    const queryId = useQueryId();

    return (
        <Sidebar
            header={<SidebarHeader title="Running queries" onClose={props.closeSidebar} />}
            border="right"
        >
            <Preloader show={runningQueries.isLoading}>
                {runningQueries.data.length === 0 && <EmptyList message="No queries found" />}

                {runningQueries.data.map((queryInfo) => (
                    <QueryInfoItem
                        key={queryInfo.id}
                        queryInfo={queryInfo}
                        isSelected={queryInfo.id === queryId}
                        isSharedIcon={queryInfo.shared}
                        isBookmarkedIcon={queryInfo.favorite}
                    />
                ))}
            </Preloader>
        </Sidebar>
    );
}
