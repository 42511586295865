import {useQuery, UseQueryOptions} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

import {useQueryListCache} from './update-query-list-cache';

const PAGE_LIMIT = 100;

function getSharedQueriesRequest() {
    return apiClient
        .post<PageResponse<RecentQuery, number>>({
            path: 'queries-service/api/queries/shared',
            parameters: {limit: PAGE_LIMIT}
        })
        .then((res) => res.data);
}

/** Hook returns shared queries  */
export function useSharedQueries(
    options?: Omit<UseQueryOptions<RecentQuery[]>, 'queryKey' | 'queryFn'>
) {
    return useQuery('getSharedQueriesRequest', getSharedQueriesRequest, options);
}

/** Provides methods for managing the cache for shared queries */
export function useSharedQueriesCache() {
    return useQueryListCache<RecentQuery>('getSharedQueriesRequest');
}
