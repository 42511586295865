import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';
import {useQueryListCache} from './update-query-list-cache';

const PAGE_LIMIT = 100;

function getDeletedQueriesRequest() {
    return apiClient
        .post<PageResponse<QueryInfo>>({
            path: 'queries-service/api/queries/trash-bin',
            parameters: {limit: PAGE_LIMIT}
        })
        .then((res) => res.data);
}

export function useDeletedQueries() {
    return useQuery('getDeletedQueriesRequest', getDeletedQueriesRequest);
}

export function useDeletedQueriesCache() {
    return useQueryListCache<QueryInfo>('getDeletedQueriesRequest');
}
