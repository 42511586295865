import {chain, chainRefs, Popover, ScrollView, Typography} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import {OutsideClickWrapper} from '../outside-click';
import {Preloader} from '../preloader/preloader';
import {SearchField, SearchFieldProps} from '../search-field';

import styles from './autocompletion.module.css';
import {SuggestionHandler, SuggestionMap} from './common-typings';
import {PreloaderListItem} from './preloader-list-item';
import {useResizeChangedField} from './use-resize-changed-field';
import {updateScrollPositionByElement} from './utils';
import {AutocompletionProps} from './suggestion-item';

const META_KEYS = ['Meta', 'Shift', 'Alt', 'Control', 'Tab', 'CapsLock', 'ArrowLeft', 'ArrowRight'];

type ItemHandlerEvent = React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>;
type KeyboardHandlers = Record<string, React.KeyboardEventHandler<HTMLInputElement> | undefined>;

export interface ItemProps<Suggestion> {
    inputValue: SearchFieldProps['value'];
    suggestion: Suggestion;
}

export interface Props<Suggestion> extends SearchFieldProps {
    inputRef?: React.RefObject<HTMLInputElement>;
    isOpen?: boolean;
    onClose: () => void;
    suggestionHandler: SuggestionHandler<Suggestion, ItemHandlerEvent>;
    emptyListText?: string;
    shortcutHandlers?: KeyboardHandlers;
    suggestions: Suggestion[];
    children: (autocompleteProps: AutocompletionProps) => React.ReactElement;
}

export function Autocompletion<Suggestion>(props: Props<Suggestion>) {
    const {
        inputRef,

        isOpen,
        onClose,

        suggestionHandler,

        emptyListText = 'No results available',
        shortcutHandlers,
        suggestions,

        children,
        ...restProps
    } = props;

    const autocompleteContainer = React.useRef<HTMLDivElement>(null);
    const fieldRef = React.useRef<HTMLInputElement>(null);
    const suggestionsListRef = React.useRef<HTMLDivElement>(null);

    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

    const indexBySuggestionMap = React.useMemo(
        () =>
            suggestions.reduce<SuggestionMap<Suggestion>>((acc, suggestion, ndx) => {
                acc.set(suggestion, ndx);

                return acc;
            }, new Map()),
        [suggestions]
    );

    React.useEffect(() => {
        if (!isOpen) {
            setSelectedIndex(0);
        }
    }, [isOpen]);

    const handleChangeSuggestion: SuggestionHandler<unknown> = React.useCallback(
        (suggestion: unknown, e: React.MouseEvent<HTMLButtonElement>) => {
            suggestionHandler(suggestion as Suggestion, e);
            fieldRef.current?.focus();
            onClose();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const updateScrollViewPositionByElement = React.useCallback(
        (itemNode: HTMLElement | null) => {
            updateScrollPositionByElement(suggestionsListRef.current, itemNode);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [suggestionsListRef.current]
    );

    const internalShortcutHandlers = React.useMemo<KeyboardHandlers>(() => {
        const suggestionsCount = suggestions.length;

        return {
            Escape: onClose,
            ArrowUp: () => {
                const prevPosition = selectedIndex - 1;
                setSelectedIndex(prevPosition >= 0 ? prevPosition : suggestionsCount - 1);
            },
            ArrowDown: () => {
                const nextPosition = selectedIndex + 1;
                setSelectedIndex(nextPosition >= suggestionsCount ? 0 : nextPosition);
            },
            Enter: (e) => {
                const selectedItem = suggestions[selectedIndex] ?? null;

                if (isOpen && selectedItem) {
                    suggestionHandler(selectedItem, e);
                }

                onClose();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, suggestions, isOpen]);

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
        (e) => {
            if (!META_KEYS.includes(e.key)) {
                setSelectedIndex(0);
            }

            const shortcutHandler = shortcutHandlers?.[e.key] ?? internalShortcutHandlers[e.key];
            if (shortcutHandler) {
                e.preventDefault();
                shortcutHandler(e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [suggestions, shortcutHandlers, internalShortcutHandlers]
    );

    const resizeFocusedField = useResizeChangedField({
        containerNode: autocompleteContainer.current,
        fieldNode: fieldRef.current,
        disabled: restProps.disabled
    });

    const onValueChange = React.useCallback(
        (value: string) => {
            if (restProps.onValueChange) {
                restProps.onValueChange(value);
            }

            if (resizeFocusedField.fieldHandlers.onValueChange) {
                resizeFocusedField.fieldHandlers.onValueChange(value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [resizeFocusedField.fieldHandlers.onValueChange]
    );

    return (
        <div ref={autocompleteContainer} className={styles.autocompletionRoot}>
            <OutsideClickWrapper
                className={classNames(styles.autocompletion, {
                    [styles.changed]: resizeFocusedField.isValueChanged,
                    [styles.disabled]: props.disabled
                })}
                handler={onClose}
                disabled={!isOpen}
                style={resizeFocusedField.styles}
                onBlur={resizeFocusedField.fieldHandlers.onBlur}
            >
                <SearchField
                    {...restProps}
                    data-qa="explore-mode-search-input"
                    className={styles.input}
                    ref={chainRefs(fieldRef, inputRef)}
                    onKeyDown={chain(onKeyDown, restProps.onKeyDown)!}
                    onValueChange={onValueChange}
                />

                {isOpen && (
                    <Popover
                        open={isOpen}
                        className={styles.list}
                        referenceElement={autocompleteContainer.current}
                        containerProps={{className: styles.popover}}
                        placement="bottom-start"
                        roundCorners
                    >
                        <ScrollView
                            className={styles.content}
                            scrollableNodeProps={{ref: suggestionsListRef}}
                            showScrollbars="always"
                        >
                            {children({
                                inputValue: props.value,
                                selectedIndex,
                                indexBySuggestionMap,
                                onChangeSuggestion: handleChangeSuggestion,
                                onChangeSelectedItem: setSelectedIndex,
                                updateScrollViewPositionByElement
                            })}

                            {suggestions.length === 0 && (
                                <Preloader show={restProps.busy} wrapEach={PreloaderListItem}>
                                    <Typography className={styles.empty} intent="quiet">
                                        {emptyListText}
                                    </Typography>
                                </Preloader>
                            )}
                        </ScrollView>
                    </Popover>
                )}
            </OutsideClickWrapper>
        </div>
    );
}
