import {Controls, ControlsItem, Typography} from '@genestack/ui';
import React from 'react';

import {UserBadge} from '../../../../../components/user-badge/user-badge';
import {CommitDto} from '../../../../../providers/version';
import {getFormattedDate} from '../../../../../utils/common';

/** A component displaying last action performed on a document */
export const LastActionLabel = ({createdAt, author}: CommitDto) => (
    <Controls gap={2}>
        <ControlsItem>
            <Controls gap={1}>
                <ControlsItem>
                    <UserBadge fullName={author.name || author.email} />
                </ControlsItem>
                <ControlsItem> {author.name || author.email}</ControlsItem>
            </Controls>
        </ControlsItem>
        <ControlsItem>
            <Typography intent="quiet">
                {getFormattedDate({date: createdAt, showTime: true})}
            </Typography>
        </ControlsItem>
    </Controls>
);
