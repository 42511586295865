import {PageContent, Typography, Divider} from '@genestack/ui';
import React from 'react';

import {Preloader} from '../../../../components/preloader/preloader';
import {Sidebar} from '../../../../components/layout/sidebar';
import {useLocationParams} from '../../../../hooks/use-location';
import {useConsumeDocInfo} from '../../../../providers/doc-info-store';
import {extractInfiniteQueryItems} from '../../../../components/pagination/infinite-query-items';

import {DocumentLink} from './document-link';
import {useListFavoritesInfiniteQuery} from './explore-favorites-query';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';
import {ExploreDocumentsSidebarProps} from './interface';

function FavoritesResults(props: {
    items: ViewModeDocInfo[];
    selectedDocumentId?: string;
    getDocumentLink: (documentId: string) => string;
}) {
    useConsumeDocInfo(props.items);

    return (
        <>
            {props.items.map((docInfo) => (
                <DocumentLink
                    key={docInfo.accession}
                    documentId={docInfo.accession}
                    active={docInfo.accession === props.selectedDocumentId}
                    displayFavoriteState={false}
                    getDocumentLink={props.getDocumentLink}
                    docInfo={docInfo}
                />
            ))}
        </>
    );
}

export const FavoriteDocumentsSidebar = (props: ExploreDocumentsSidebarProps) => {
    const {documentId: selectedDocumentId} = useLocationParams();

    const listFavoritesQuery = useListFavoritesInfiniteQuery(props.selectedGlobalVersion.id);
    const items = extractInfiniteQueryItems(
        listFavoritesQuery,
        (result: PageResponse<ViewModeDocInfo>) => result.data
    );

    const header = <SidebarHeader title="Bookmarks" onClose={props.closeSidebar} />;

    return (
        <Sidebar header={header} footer={props.footer} border="right">
            <Preloader show={listFavoritesQuery.isFetching}>
                {!listFavoritesQuery.isSuccess || !items?.length ? (
                    <PageContent noStartDivider noEndDivider>
                        <Typography intent="quiet">
                            You haven't bookmarked any documents yet
                        </Typography>
                    </PageContent>
                ) : (
                    <>
                        <FavoritesResults
                            items={items}
                            selectedDocumentId={selectedDocumentId}
                            getDocumentLink={props.getDocumentLink}
                        />
                        <Divider
                            variant="transparent"
                            gap={4} // tslint:disable-line: no-magic-numbers
                        />
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
};
