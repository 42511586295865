import {Button, Menu, MenuItem, MoreIcon, Typography, useMenuHandler} from '@genestack/ui';
import React from 'react';

import {useUserEditingPermissions} from '../../../../../hooks/user/use-has-permission';

interface Props extends React.ComponentPropsWithoutRef<typeof Button> {
    isDocsLoading: boolean;
    isPublishAllPending: boolean;
    onPublishDrafts: (onlyMyDrafts: boolean) => void;
    myDraftsNumber: number;
    allDraftsNumber: number;
}

export function UnpublishedDocsMenu({
    isDocsLoading,
    isPublishAllPending,
    onPublishDrafts,
    myDraftsNumber,
    allDraftsNumber,
    ...restProps
}: Props) {
    const referenceElement = React.useRef<HTMLButtonElement>(null);
    const menu = useMenuHandler({referenceElement: referenceElement.current});

    const {fullEditingAccess} = useUserEditingPermissions();

    const publishAllDrafts = () => {
        onPublishDrafts(false);
    };

    const publishOnlyMyDrafts = () => {
        onPublishDrafts(true);
    };

    return (
        <>
            <Button
                ghost
                size="small"
                icon={<MoreIcon />}
                data-qa="unpublished-menu-button"
                {...menu.getReferenceProps<React.ComponentPropsWithRef<typeof Button>>({
                    ref: referenceElement,
                    active: menu.isOpen
                })}
                {...restProps}
            />

            <Menu {...menu.getMenuProps()}>
                <MenuItem
                    disabled={isDocsLoading || isPublishAllPending || !myDraftsNumber}
                    onClick={publishOnlyMyDrafts}
                    data-qa="publish-all-menu-item"
                    append={
                        <Typography intent="quiet" variant="caption">
                            {myDraftsNumber} documents
                        </Typography>
                    }
                >
                    {isPublishAllPending ? 'Publishing...' : 'Publish my drafts'}
                </MenuItem>

                {fullEditingAccess && (
                    <MenuItem
                        disabled={isDocsLoading || isPublishAllPending || !allDraftsNumber}
                        onClick={publishAllDrafts}
                        data-qa="publish-all-menu-item"
                        append={
                            <Typography intent="quiet" variant="caption">
                                {allDraftsNumber} documents
                            </Typography>
                        }
                        subtitle={
                            <Typography intent="quiet" variant="caption">
                                All drafts from all the users
                            </Typography>
                        }
                    >
                        {isPublishAllPending ? 'Publishing...' : 'Publish all'}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
