import React, {PropsWithChildren} from 'react';
import {
    Button,
    CrossIcon,
    Tooltip,
    TooltipHandler,
    Typography,
    Divider,
    Controls,
    ControlsItem,
    DividerProps,
    PageContent
} from '@genestack/ui';
import styles from './sidebar-header.module.css';

interface Props {
    title: string | React.ReactNode;
    onClose?: () => void;
    additionalControl?: React.ReactNode;
    customCloseTooltip?: string;
}

const SMALL_GAP: DividerProps = {startGap: 1, endGap: 0};

export const SidebarHeader = (props: PropsWithChildren<Props>) => {
    return (
        <PageContent className={styles.root} endDividerProps={SMALL_GAP}>
            <div className={styles.content}>
                <div className={styles.title}>
                    {typeof props.title === 'string' ? (
                        <Typography variant="section">{props.title}</Typography>
                    ) : (
                        props.title
                    )}

                    <Controls className={styles.controls}>
                        {props.additionalControl && (
                            <ControlsItem>{props.additionalControl}</ControlsItem>
                        )}

                        {props.onClose && (
                            <ControlsItem>
                                <TooltipHandler
                                    tooltip={
                                        <Tooltip placement="bottom">
                                            {props.customCloseTooltip || 'Close sidebar'}
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        iconEnd={<CrossIcon />}
                                        size="small"
                                        ghost
                                        onClick={props.onClose}
                                    />
                                </TooltipHandler>
                            </ControlsItem>
                        )}
                    </Controls>
                </div>

                {props.children && <Divider variant="transparent" />}

                {props.children}
            </div>
        </PageContent>
    );
};
