import React from 'react';

export function useFoldingItemsState() {
    const [foldingState, setFoldingState] = React.useState<{[key: string]: boolean}>({});

    const fold = React.useCallback((key: string) => {
        setFoldingState((fState) => ({...fState, [key]: false}));
    }, []);

    const unfold = React.useCallback((key: string) => {
        setFoldingState((fState) => ({...fState, [key]: true}));
    }, []);

    const toggleFoldingState = React.useCallback(
        (key: string) => {
            if (foldingState[key]) {
                fold(key);
            } else {
                unfold(key);
            }
        },
        [fold, unfold, foldingState]
    );

    return {
        foldingState,
        toggleFoldingState,
        fold,
        unfold
    };
}
