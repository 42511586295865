import {Divider, ScrollView} from '@genestack/ui';
import React from 'react';

import {WindowTitle} from '../../../components/window-title';

import {RecentQueries} from './recent-queries';
import {UnresolvedQueries} from './unresolved-queries';
import {useMPRecentQueries} from './use-mp-recent-queries';
import {useMPUnresolvedQueries} from './use-mp-unresolved-queries';
import {ManagementPanelHeader} from '../../../components/management-panel/management-panel-header';
import {ManagementPanel} from '../../../components/management-panel/management-panel';

export function QueryManagementPanel() {
    const MPUnresolvedQueries = useMPUnresolvedQueries();
    const MPRecentQueries = useMPRecentQueries();

    const getRecentQueryById = (id: number) => {
        return MPRecentQueries.data?.find((query) => query.id === id);
    };

    return (
        <WindowTitle text="Queries">
            <ManagementPanel>
                <ScrollView>
                    <ManagementPanelHeader title="Query management panel" />

                    <UnresolvedQueries
                        unresolvedQueriesRequest={MPUnresolvedQueries}
                        getRecentQueryById={getRecentQueryById}
                    />

                    {/* tslint:disable-next-line: no-magic-numbers */}
                    <Divider variant="transparent" gap={3} />

                    <RecentQueries recentQueriesRequest={MPRecentQueries} />
                </ScrollView>
            </ManagementPanel>
        </WindowTitle>
    );
}
