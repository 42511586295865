import React from 'react';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {useGetPresentationDetails} from './requests/use-get-presentation-details';
import {
    GraphType,
    GraphViewType,
    ResultMaxLengthOption
} from '../../../../../components/graph/interface';
import {QueryGraphLayoutAlgorithm} from '../../../../../components/graph/cytoscape-graph/interface';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';
import {isGraphProblematic, isGraphUnmanageable} from './helpers';

export const defaultPresentationId = -1;
function addDefaultPresentation(presentations: GraphPresentation[]): GraphPresentation[] {
    return [
        ...presentations,
        {
            id: defaultPresentationId,
            name: 'Default presentation',
            isFavorite: !presentations.find((presentation) => presentation.isFavorite)
        }
    ];
}

function getDefaultMaxPathLength(options: ResultMaxLengthOption[], graphType: GraphType) {
    if (!options.length) {
        return '0';
    }

    const minimalValuableOption = options.find(
        (option) => option.minimalValuable
    ) as ResultMaxLengthOption;
    if (!isGraphProblematic(minimalValuableOption.amountOfNodes, graphType)) {
        return String(minimalValuableOption.pathLength);
    }

    const biggestNonProblematicGraph = [...options]
        .reverse()
        .find((option) => !isGraphProblematic(option.amountOfNodes, graphType));
    if (biggestNonProblematicGraph) {
        return String(biggestNonProblematicGraph.pathLength);
    }

    const firstOptionIsUnmanageable = isGraphUnmanageable(options[0].amountOfNodes, graphType);
    if (!firstOptionIsUnmanageable) {
        return String(options[0].pathLength);
    }

    return '0';
}

function getDefaultPresentationDetails(
    maxPathLengthOptions: MaxPathLengthOptionResult
): GraphPresentationDetails {
    return {
        layoutAlgorithm: QueryGraphLayoutAlgorithm.LAYERED_HORIZONTAL,
        graphType: GraphType.cytoscape,
        viewKind: GraphViewType.MERGED,
        maxPathLength: Number(
            getDefaultMaxPathLength(maxPathLengthOptions.merged, GraphType.cytoscape)
        ),
        layout: '',
        isLayoutCustom: false,
        hiddenNodes: []
    };
}

interface BaseGraphPresentationProps {
    presentations: GraphPresentation[];
    selectedPresentation: GraphPresentation;
    selectPresentation: (id: number) => void;
    isDefaultPresentation: boolean;
}

export interface GraphPresentationPropsLoaded extends BaseGraphPresentationProps {
    presentationDetails: GraphPresentationDetails;
}

export function useGraphPresentationProps(
    queryId: number,
    presentations: GraphPresentation[],
    maxPathLengthOptions: MaxPathLengthOptionResult
): BaseGraphPresentationProps | GraphPresentationPropsLoaded {
    const presentationsWithDefault = React.useMemo(
        () => addDefaultPresentation(presentations),
        [presentations]
    );

    const [selectedPresentation, setSelectedPresentation] = useLsValue<string, [number], []>({
        changeEntityParams: [queryId],
        getKey: ([qId]) => `${LS_PREFIX}.queryGraph.${qId}.graphPresentation`,
        rewriteKeyParams: [],
        defaultValue: presentationsWithDefault
            .find((presentation) => presentation.isFavorite)!
            .id.toString(),
        validateValue: (val) => {
            return !!presentationsWithDefault.find(
                (presentation) => presentation.id === Number(val)
            );
        }
    });

    const isDefaultPresentation = Number(selectedPresentation) === defaultPresentationId;
    const detailsRequest = useGetPresentationDetails(
        queryId,
        Number(selectedPresentation),
        !isDefaultPresentation
    );

    return {
        presentations: presentationsWithDefault,
        selectedPresentation: presentationsWithDefault.find(
            (presentation) => String(presentation.id) === selectedPresentation
        )!,
        selectPresentation: React.useCallback(
            (id: number) => setSelectedPresentation(String(id)),
            [setSelectedPresentation]
        ),
        presentationDetails: isDefaultPresentation
            ? getDefaultPresentationDetails(maxPathLengthOptions)
            : detailsRequest.data,
        isDefaultPresentation
    };
}

export function isLoadedPresentationProps(
    props: BaseGraphPresentationProps | GraphPresentationPropsLoaded
): props is GraphPresentationPropsLoaded {
    return !!(props as GraphPresentationPropsLoaded).presentationDetails;
}
