import {Button, ListItem} from '@genestack/ui';
import classNames from 'classnames';
import React, {CSSProperties, PropsWithChildren} from 'react';

import {ListItemPreloader} from '../../preloader/list-item-preloader/list-item-preloader';
import {ListPreloaderItem} from '../../preloader/list-item-preloader/list-preloader-item';

import styles from './load-more-control.module.css';

interface Props {
    isLoading?: boolean;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    style?: CSSProperties;
}

function LoadMoreControlPreloaderItem(
    props: React.ComponentPropsWithoutRef<typeof ListPreloaderItem>
) {
    return (
        <ListPreloaderItem
            {...props}
            className={classNames(styles.preloaderItem, props.className)}
        />
    );
}

export function LoadMoreControl({
    isLoading,
    children,
    style = {paddingLeft: 16},
    ...restProps
}: PropsWithChildren<Props>) {
    return (
        <ListItem as="div" style={style} className={restProps.className}>
            <ListItemPreloader show={isLoading} wrapEach={LoadMoreControlPreloaderItem}>
                <Button
                    ghost
                    size="small"
                    {...restProps}
                    className={classNames(styles.root, styles.ghost)}
                >
                    {children}
                </Button>
            </ListItemPreloader>
        </ListItem>
    );
}
