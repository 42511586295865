import React from 'react';
import {useHistory} from 'react-router-dom';

import {showNotification} from '../../../../../../providers/notifications-center';
import {DeleteStatusNotification} from '../../../../../../providers/notifications-center/notifications/delete';
import {useDeleteDocumentMutation} from '../../../hooks/use-delete-document-mutation';

interface Props {
    accession: string;
    children: React.ReactElement;
}

/**
 * Component allowing to Delete a document
 */
export function DeleteItem(props: Props) {
    const history = useHistory();
    const deleteMutation = useDeleteDocumentMutation();

    const handleDelete = async () => {
        try {
            await deleteMutation.mutateAsync(props.accession);

            showNotification(<DeleteStatusNotification />);
            history.push('/documents/edit');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            showNotification(<DeleteStatusNotification isError errorMessage={error.message} />);
        }
    };

    return React.cloneElement(props.children, {onClick: handleDelete});
}
