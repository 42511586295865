import {
    ListItem,
    PageContent,
    PageFullWidth,
    Typography,
    WithSeparator,
    Divider
} from '@genestack/ui';
import React from 'react';

import {DSLPreloader} from '../../../../../../components/preloader/dsl-preloader';
import {useCategoriesQuery, ReferencedNode} from '../../../hooks/requests/use-categories-query';
import {UNCATEGORIZED_CATEGORY_ID} from '../../../../../../utils/get-categories-search';

import {CategoryTreeElement} from './adapt-tree';
import styles from './categories.module.css';
import {CategoryTree} from './category-tree';
import {CategoryTreeItem} from './category-tree-item';

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    versionId: number;
    // eslint-disable-next-line react/no-unused-prop-types
    stringSearch?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    categories?: string[];
    // eslint-disable-next-line react/no-unused-prop-types
    referenceNode?: ReferencedNode;
    onChangeFilters: (
        toAddVisual: CategoryTreeElement[],
        toRemoveVisual: CategoryTreeElement[],
        toAddApplied: string[],
        toRemoveApplied: string[]
    ) => void;
    appliedFilters: string[];
    visualFilters: CategoryTreeElement[];
}

function createUncategorized(count: number): CategoryTreeElement {
    return {
        categoryId: UNCATEGORIZED_CATEGORY_ID,
        documentsCount: count,
        name: 'Uncategorized',
        children: [],
        childIds: [],
        childCategoryIds: [],
        path: [],
        parents: [],
        rootName: 'Uncategorized',
        id: UNCATEGORIZED_CATEGORY_ID,
        assigned: true
    };
}

export function Categories(props: Props) {
    const treeQuery = useCategoriesQuery(props);

    const uncategorized = React.useMemo(() => {
        return createUncategorized(treeQuery.data?.uncategorizedDocumentsCount || 0);
    }, [treeQuery.data?.uncategorizedDocumentsCount]);

    const handleCheckedChange = React.useCallback((el: CategoryTreeElement, checked: boolean) => {
        if (el.categoryId === UNCATEGORIZED_CATEGORY_ID) {
            if (checked) {
                props.onChangeFilters([uncategorized], [], [UNCATEGORIZED_CATEGORY_ID], []);
            } else {
                props.onChangeFilters([], [uncategorized], [], [UNCATEGORIZED_CATEGORY_ID]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderUncategorized = (count?: number) => {
        if (count) {
            return (
                <CategoryTreeItem
                    item={uncategorized}
                    order={0}
                    onCheckedChange={handleCheckedChange}
                    checked={props.appliedFilters.includes(UNCATEGORIZED_CATEGORY_ID)}
                    titleContentProps={{className: styles.uncategorizedTitle}}
                />
            );
        }

        return null;
    };

    const renderContent = () => {
        if (treeQuery.isSuccess && !treeQuery.data) {
            return (
                <PageFullWidth>
                    <ListItem>Categories available for narrowed request</ListItem>
                </PageFullWidth>
            );
        }

        if (!treeQuery.data) {
            return null;
        }

        const uncategorizedCount = treeQuery.data.uncategorizedDocumentsCount;

        return (
            <PageFullWidth>
                {treeQuery.data.trees.length || uncategorizedCount ? (
                    <>
                        <Typography className={styles.heading} variant="section" intent="quiet">
                            Categories
                        </Typography>
                        <div className={styles.container}>
                            <WithSeparator separator={<Divider variant="transparent" />}>
                                {renderUncategorized(uncategorizedCount)}

                                {treeQuery.data.trees.map((tree) => (
                                    <CategoryTree
                                        key={tree[0].categoryId}
                                        tree={tree}
                                        onChangeFilters={props.onChangeFilters}
                                        appliedFilters={props.appliedFilters}
                                        visualFilters={props.visualFilters}
                                    />
                                ))}
                            </WithSeparator>
                        </div>
                    </>
                ) : (
                    <ListItem>No categories available</ListItem>
                )}
            </PageFullWidth>
        );
    };

    return (
        <PageContent className={styles.root}>
            {renderContent() || <DSLPreloader show />}
        </PageContent>
    );
}
