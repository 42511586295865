import React from 'react';
import {
    Button,
    Divider,
    EyeClosedIcon,
    EyeEmptyIcon,
    FocusIcon,
    Menu,
    MenuHandler,
    MenuItem,
    MinusCircledIcon,
    PathIcon,
    PlusCircledIcon
} from '@genestack/ui';
import style from './control-panel.module.css';
import {useHiddenNodesActions} from '../../graph-logic/hidden-nodes-actions/use-hidden-nodes-actions';

interface Props {
    focusGraph: () => void;
    hiddenNodesActions?: ReturnType<typeof useHiddenNodesActions>;
}

export function ControlPanel(props: Props) {
    const buildPathMenu = !props.hiddenNodesActions ? null : (
        <Menu
            onValueSelect={(val) => {
                if (val === 'connectNodes') {
                    props.hiddenNodesActions!.buildPathProps.connectSelectedNodes();
                }

                if (val === 'pathsThroughNodes') {
                    props.hiddenNodesActions!.buildPathProps.buildPathsThroughSelected();
                }
            }}
        >
            <MenuItem
                key="pathsThroughNodes"
                value="pathsThroughNodes"
                disabled={props.hiddenNodesActions.nonHiddenSelectedNodes.length < 1}
            >
                Keep paths through selected nodes
            </MenuItem>
            <MenuItem
                key="connectNodes"
                value="connectNodes"
                disabled={props.hiddenNodesActions.nonHiddenSelectedNodes.length < 2}
            >
                Keep paths between selected nodes
            </MenuItem>
        </Menu>
    );

    const hideNodesMenu = !props.hiddenNodesActions ? null : (
        <Menu
            onValueSelect={(val) => {
                if (val === 'hideOnlyNodes') {
                    props.hiddenNodesActions!.hideNodesProps.hideSelectedNodes();
                }

                if (val === 'hideNodesAndDeadEnds') {
                    props.hiddenNodesActions!.hideNodesProps.hideNodesAndDeadEnds();
                }

                if (val === 'hideAllNodes') {
                    props.hiddenNodesActions!.hideNodesProps.hideAllNodes();
                }
            }}
        >
            <MenuItem
                key="hideNodesAndPaths"
                value="hideNodesAndDeadEnds"
                disabled={!props.hiddenNodesActions.nonHiddenSelectedNodes.length}
            >
                Hide selected and dead-end nodes
            </MenuItem>
            <MenuItem
                key="hideOnlyNodes"
                value="hideOnlyNodes"
                disabled={!props.hiddenNodesActions.nonHiddenSelectedNodes.length}
            >
                Hide selected nodes only
            </MenuItem>
            <MenuItem key="hideAllNodes" value="hideAllNodes">
                Hide all nodes
            </MenuItem>
        </Menu>
    );

    const unhideNodesMenu = !props.hiddenNodesActions ? null : (
        <Menu
            onValueSelect={(val) => {
                if (val === 'unhideSelectedNodes') {
                    props.hiddenNodesActions!.unhideNodesProps.unhideSelectedNodes();
                }

                if (val === 'unhideAllNodes') {
                    props.hiddenNodesActions!.unhideNodesProps.unhideAllNodes();
                }

                if (val === 'addPathsThroughSelected') {
                    props.hiddenNodesActions!.unhideNodesProps.unhideAllPathsThroughSelected();
                }

                if (val === 'attachToSubgraph') {
                    props.hiddenNodesActions!.unhideNodesProps.connectSelectedToUnhidden();
                }
            }}
        >
            <MenuItem
                key="unhideSelectedNodes"
                value="unhideSelectedNodes"
                disabled={!props.hiddenNodesActions.hiddenSelectedNodes.length}
            >
                Return selected nodes only
            </MenuItem>
            <MenuItem key="unhideAllNodes" value="unhideAllNodes">
                Return all nodes
            </MenuItem>
            <MenuItem
                key="addPathsThroughSelected"
                value="addPathsThroughSelected"
                disabled={!props.hiddenNodesActions.hiddenSelectedNodes.length}
            >
                Add paths going through selected nodes
            </MenuItem>
            <MenuItem
                key="attachToSubgraph"
                value="attachToSubgraph"
                disabled={!props.hiddenNodesActions.hiddenSelectedNodes.length}
            >
                Attach to the current subgraph
            </MenuItem>
        </Menu>
    );

    return (
        <div className={style.controlPanel}>
            <Button ghost size="small" iconStart={<FocusIcon />} onClick={props.focusGraph} />
            {props.hiddenNodesActions ? (
                <React.Fragment>
                    <Divider />
                    <MenuHandler menu={buildPathMenu!}>
                        <Button
                            ghost
                            size="small"
                            iconStart={<PathIcon />}
                            hasSubmenuIndicator
                            disabled={!props.hiddenNodesActions.nonHiddenSelectedNodes.length}
                        />
                    </MenuHandler>

                    <MenuHandler menu={hideNodesMenu!}>
                        <Button
                            ghost
                            size="small"
                            iconStart={<MinusCircledIcon />}
                            hasSubmenuIndicator
                            disabled={props.hiddenNodesActions.isAllNodesHidden}
                        />
                    </MenuHandler>

                    <MenuHandler menu={unhideNodesMenu!}>
                        <Button
                            ghost
                            size="small"
                            iconStart={<PlusCircledIcon />}
                            hasSubmenuIndicator
                            disabled={!props.hiddenNodesActions.hasHiddenNodes}
                        />
                    </MenuHandler>

                    <Divider />
                    <Button
                        ghost
                        size="small"
                        iconStart={
                            props.hiddenNodesActions.isHiddenNodesVisible ? (
                                <EyeEmptyIcon />
                            ) : (
                                <EyeClosedIcon />
                            )
                        }
                        active={props.hiddenNodesActions.isHiddenNodesVisible}
                        disabled={!props.hiddenNodesActions.hasHiddenNodes}
                        onClick={props.hiddenNodesActions.toggleIsHiddenNodesVisible}
                    />
                </React.Fragment>
            ) : null}
        </div>
    );
}
