import {Notification, Typography, NotificationProps, Button} from '@genestack/ui';
import React from 'react';

import {showNotification} from '../../../providers/notifications-center';
import {useLocationParams} from '../../../hooks/use-location';
import {useMounted} from '../../../hooks/use-mounted';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {useJbkbSocketEvent} from '../../../providers/jbkb-socket';
import {CompleteSocketEvent, FailSocketEvent} from '../../../providers/jbkb-socket/socket-events';
import {usePreselectedTab} from '../../../components/layout/page-layout/use-preselected-tab';
import {apiClient} from '../../../utils/api-client';
import {QueriesTab} from '../../../components/layout/page-layout/layout-typings';

interface QueryCompletedNotificationProps extends NotificationProps {
    variant: 'success' | 'alarm';
    queryInfo: QueryInfo;
    onGoToQuery: (queryId: number) => void;
}

const NOTIFICATION_DURATION = 9000;

function QueryCompletedNotification({
    queryInfo,
    variant,
    onGoToQuery,
    ...restProps
}: QueryCompletedNotificationProps) {
    const handleGoToQuery = () => {
        onGoToQuery(queryInfo.id);
    };

    return (
        <Notification {...restProps} countdownDuration={NOTIFICATION_DURATION}>
            <Typography intent={variant} box="paragraph">
                Search {variant === 'success' ? 'completed' : 'interrupted'}
            </Typography>
            <Typography box="paragraph">
                {`${queryInfo.resultsCount === 0 ? 'No' : queryInfo.resultsCount}`} results found
                for query <strong>{queryInfo.name}</strong>.
            </Typography>
            <Button size="small" onClick={handleGoToQuery}>
                View query
            </Button>
        </Notification>
    );
}

interface QuerySharedNotificationProps extends NotificationProps {
    queryInfo: QueryInfo;
    variant: 'shared' | 'unshared';
    onGoToQuery: (queryId: number, preselectedTab?: QueriesTab) => void;
}

function QuerySharedNotification({
    queryInfo,
    variant,
    onGoToQuery,
    ...restProps
}: QuerySharedNotificationProps) {
    const handleGoToQuery = () => {
        onGoToQuery(queryInfo.id, QueriesTab.SHARED);
    };

    return (
        <Notification countdownDuration={NOTIFICATION_DURATION} {...restProps}>
            <Typography box="paragraph">
                {variant === 'shared'
                    ? `Query '${queryInfo.name}' has been shared with you`
                    : `Your access to query '${queryInfo.name}' has been revoked`}
            </Typography>
            {variant === 'shared' && (
                <Button size="small" onClick={handleGoToQuery}>
                    View query
                </Button>
            )}
        </Notification>
    );
}

/** A component showing notifications on important query events and updating the query cache */
export function useQueryEventsNotifications(notifyOnlyOnMyQueries: boolean) {
    const mounted = useMounted();
    const {queryId} = useLocationParams();
    const currentUserQuery = useCurrentUserQuery();
    const {getPreselectedTabURLParam} = usePreselectedTab();

    const handleGoToQuery = React.useCallback((qId: number, preselectedTab?: QueriesTab) => {
        window.open(
            `${window.location.origin}/queries/${qId}${
                preselectedTab ? `?${getPreselectedTabURLParam(preselectedTab)}` : ''
            }`,
            '_blank'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleCompletedSocketEvent(message: CompleteSocketEvent | FailSocketEvent) {
        const isMyQuery =
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            currentUserQuery.data!.email === message.payload.owner.email ||
            message.payload.sharedWithMe;

        if ((notifyOnlyOnMyQueries && !isMyQuery) || message.payload.id === Number(queryId)) {
            return;
        }

        const queryInfo = await apiClient.get<QueryInfo>({
            path: `queries-service/api/queries/${message.payload.id}`
        });

        if (!mounted || !queryInfo) {
            return;
        }

        showNotification(
            <QueryCompletedNotification
                variant={message.type === 'query:complete' ? 'success' : 'alarm'}
                queryInfo={queryInfo}
                onGoToQuery={handleGoToQuery}
            />
        );
    }

    useJbkbSocketEvent((message) => {
        if (message.type === 'query:complete' || message.type === 'query:fail') {
            handleCompletedSocketEvent(message);
        }

        if (message.type === 'query:share' || message.type === 'query:unshare') {
            showNotification(
                <QuerySharedNotification
                    variant={message.type === 'query:share' ? 'shared' : 'unshared'}
                    queryInfo={message.payload}
                    onGoToQuery={handleGoToQuery}
                />
            );
        }
    });

    return null;
}
