import {Css} from 'cytoscape';
import {GraphEdge, GraphLayout, GraphNode} from '../interface';
import {
    getDefaultEdgeStyles,
    getHiddenNodeStyles,
    getUnhiddenNodeStyles,
    getUnhoveredNodeStyles,
    getUnselectedNodeStyles
} from './get-dynamic-styles';
import {CytoscapeEdgeData} from './interface';
import {getEdgeCytoscapeId} from './utils';

export function createNodeElement(
    node: GraphNode,
    nodePositions: GraphLayout,
    getIsNodePartiallyHidden: (id: number) => boolean
) {
    return {
        data: {id: `${node.id}`, nodeType: node.nodeType, label: node.label},
        position: nodePositions[node.id],
        style: {
            ...getUnhoveredNodeStyles(),
            ...getUnselectedNodeStyles(),
            ...(getIsNodePartiallyHidden(node.id) ? getHiddenNodeStyles() : getUnhiddenNodeStyles())
        }
    };
}

export function createEdgeElement(
    edge: GraphEdge,
    getIsEdgePartiallyHidden: (edgeId: number) => boolean
): {data: CytoscapeEdgeData; style: Css.Edge} {
    return {
        data: {
            id: getEdgeCytoscapeId(edge.id),
            source: `${edge.startViewNodeId}`,
            target: `${edge.endViewNodeId}`,
            presentation: edge.presentation
        },
        style: getDefaultEdgeStyles(getIsEdgePartiallyHidden(edge.id))
    };
}
