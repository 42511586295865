import {Typography} from '@genestack/ui/dist';
import classNames from 'classnames';
import * as React from 'react';

import {getInitials} from '../../../utils/initials';

import styles from './user-badge-inline.module.css';

interface Props {
    fullName: string;
    className?: string;
}

/** This is a new type of avatar, in the future it must be able to
 * form a line with other avatars of this kind */
export const UserBadgeInline = ({fullName, className}: Props) => {
    return (
        <div className={classNames(styles.userBadgeInline, className)}>
            <Typography inverted variant="caption">
                {getInitials(fullName)}
            </Typography>
        </div>
    );
};
