import React from 'react';
import {getDeadEndNodes} from '../get-dead-end-nodes';
import {deselectFullyHiddenNodes} from '../../../../../application/query-workflow/query-layout/completed-query/results-graph/use-hidden-nodes';
import {HiddenNodesActionsProps} from './interface';

interface Props extends HiddenNodesActionsProps {
    setHiddenNodesAndMovePositions: (newHiddenNodes: number[]) => void;
}

export function useHideNodes(props: Props) {
    const {
        hiddenNodes,
        selectedNodes,
        structuredGraph,
        setSelectedNodes,
        isHiddenNodesVisible,
        setHiddenNodesAndMovePositions
    } = props;

    const hideNodesAndDeadEnds = React.useCallback(() => {
        const newHiddenNodes = new Set<number>([...hiddenNodes, ...selectedNodes]);
        selectedNodes.forEach((selectedNodeId) =>
            getDeadEndNodes(structuredGraph!, selectedNodeId).forEach((id) =>
                newHiddenNodes.add(id)
            )
        );

        setHiddenNodesAndMovePositions(Array.from(newHiddenNodes));
        if (!isHiddenNodesVisible) {
            deselectFullyHiddenNodes(Array.from(newHiddenNodes), selectedNodes, setSelectedNodes);
        }
    }, [
        selectedNodes,
        hiddenNodes,
        setHiddenNodesAndMovePositions,
        structuredGraph,
        setSelectedNodes,
        isHiddenNodesVisible
    ]);

    const hideSelectedNodes = React.useCallback(() => {
        const newVal = Array.from(new Set<number>([...selectedNodes, ...hiddenNodes]));
        setHiddenNodesAndMovePositions(newVal);
        if (!isHiddenNodesVisible) {
            deselectFullyHiddenNodes(Array.from(newVal), selectedNodes, setSelectedNodes);
        }
    }, [
        setHiddenNodesAndMovePositions,
        selectedNodes,
        setSelectedNodes,
        hiddenNodes,
        isHiddenNodesVisible
    ]);

    const hideAllNodes = React.useCallback(() => {
        setHiddenNodesAndMovePositions(structuredGraph!.allNodes.map((node) => node.id));
    }, [setHiddenNodesAndMovePositions, structuredGraph]);

    return {
        hideNodesAndDeadEnds,
        hideSelectedNodes,
        hideAllNodes
    };
}
