import React from 'react';
import {usePropagatedValues} from './use-propagated-values';

interface Props {
    propagatedValuesProps: ReturnType<typeof usePropagatedValues>;
    maxPathLength: number;
}

// for explanation look up comment in usePropagatedValues
export function usePropagateValues({propagatedValuesProps, maxPathLength}: Props) {
    React.useEffect(() => {
        propagatedValuesProps.propagateMaxPathLength(maxPathLength);
        return () => propagatedValuesProps.propagateMaxPathLength(undefined);
    }, [propagatedValuesProps, maxPathLength]);
}
