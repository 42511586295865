import React from 'react';
import {Controls, ControlsItem, PageContent, Preloader, Switch, Typography} from '@genestack/ui';
import {Sidebar} from '../../../../../components/layout/sidebar';
import {SidebarHeader} from '../../../../../components/layout/sidebar/sidebar-header/sidebar-header';
import {useTreeStructureQuery} from './use-tree-structure-query';
import {getSortTreeItemsFunc, getTreeItem} from './helpers';
import {isDocumentBeingEdited} from '../../../../../utils/document';
import {useFoldingItemsState} from '../../../../../hooks/use-folding-items-state';
import styles from './ontology-tree-item.module.css';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {useLsFlag} from '../../../../../hooks/use-ls-flag';
import {OntologyTreeInitialItem} from './ontology-tree-initial-item';

interface Props {
    onClose: () => void;
    docInfo: DocInfo;
    foldingItemsProps: ReturnType<typeof useFoldingItemsState>;
}

export function OntologyTreeSidebar(props: Props) {
    const isEditedDoc = isDocumentBeingEdited(props.docInfo);
    const [isCurrentDocOnly, setIsCurrentDocOnly] = useLsFlag(
        `${LS_PREFIX}.document.edit.${props.docInfo.accession}.isCurrentDocOnly`,
        true
    );
    const treeStructureQuery = useTreeStructureQuery(
        props.docInfo.accession,
        Boolean(isCurrentDocOnly),
        isEditedDoc
    );

    const rootIdsSorted = React.useMemo(() => {
        return treeStructureQuery.data?.rootIds.sort(
            getSortTreeItemsFunc(treeStructureQuery.data, props.docInfo)
        );
    }, [treeStructureQuery.data, props.docInfo]);

    return (
        <Sidebar border="left">
            <SidebarHeader title="Hierarchical tree" onClose={props.onClose}>
                <Typography
                    as="label"
                    variant="section"
                    intent="quiet"
                    className={styles.onlyThisDocSwitch}
                >
                    <Controls>
                        <ControlsItem>
                            <Switch
                                checked={isCurrentDocOnly}
                                onCheckedChange={setIsCurrentDocOnly}
                            />
                        </ControlsItem>
                        <ControlsItem>Current document only</ControlsItem>{' '}
                    </Controls>
                </Typography>
            </SidebarHeader>

            {(function () {
                if (!treeStructureQuery.data) {
                    return (
                        <PageContent>
                            <Preloader show count={5} box="paragraph" />
                        </PageContent>
                    );
                }

                return rootIdsSorted!.map((id) => {
                    const element = getTreeItem(treeStructureQuery.data, id);

                    return (
                        <OntologyTreeInitialItem
                            key={JSON.stringify(id)}
                            id={id}
                            ontologyTreeData={treeStructureQuery.data}
                            foldingItemsProps={props.foldingItemsProps}
                            padding={1}
                            currentDocInfo={props.docInfo}
                            element={element}
                        />
                    );
                });
            })()}
        </Sidebar>
    );
}
