import React from 'react';
import {useGlobalVersion} from '../../providers/version/use-global-version';
import {UniversalBackdrop} from '../../components/universal-backdrop';
import {StubMessage} from '../../components/stub-message';
import {GlobalVersionData} from '../../hooks/global-version/use-global-version-query';

export interface GlobalVersionProps {
    globalVersions: GlobalVersionData[];
    currentGlobalVersionData: GlobalVersionData;
}

interface Props {
    errorText: string;
    globalVersionProps: ReturnType<typeof useGlobalVersion>;
    children: (props: GlobalVersionProps) => React.ReactNode;
}

export const GlobalVersionsLoadedChecker = (props: Props) => {
    const {versionsLoading, currentGlobalVersionData, globalVersions} = props.globalVersionProps;

    return (
        <React.Fragment>
            {globalVersions &&
                currentGlobalVersionData &&
                props.children({globalVersions, currentGlobalVersionData})}

            <UniversalBackdrop
                isLoading={versionsLoading}
                message={
                    (!globalVersions || !globalVersions.length || !currentGlobalVersionData) && (
                        <StubMessage caption="No global versions found">
                            {props.errorText}
                        </StubMessage>
                    )
                }
            />
        </React.Fragment>
    );
};
