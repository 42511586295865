import {Preloader} from '@genestack/ui';
import React from 'react';

import styles from './documents-content.module.css';
import {DSLPreloader} from '../../../preloader/dsl-preloader';

type TargetProps = React.HTMLAttributes<HTMLOListElement>;

interface Props extends TargetProps {
    show: boolean;
    count: number;
}

/** Path entry preloader */
export function DocumentContentPreloader({show, count, children}: Props) {
    if (!show) {
        return <ol className={styles.list}>{children}</ol>;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const items = React.useMemo(() => new Array(count).fill(null), [count]);

    return (
        <ol className={styles.list}>
            {show &&
                items.map((item, ndx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={ndx} className={styles.item}>
                        <div className={styles.content}>
                            <Preloader show className={styles.titlePreloader} />
                            <DSLPreloader show />
                        </div>
                    </li>
                ))}
        </ol>
    );
}
