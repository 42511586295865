import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {WindowTitle} from '../../../../components/window-title';
import {LocationParams} from '../../../../hooks/use-location';
import {useConsumeDocInfo} from '../../../../providers/doc-info-store';
import {useDocRevisionsContext} from '../../../../providers/version';
import {getDocPageTitle} from '../../../../utils/get-doc-page-title';

import {DocumentEditorHeader} from './document-editor-header';
import styles from './document-view.module.css';
import {Editor} from './editor';
import {useEditorModel} from './model';
import {useHandleFindUsages} from '../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {FindUsagesEditorEvent} from '../../../../../webeditor/biokb-editor-adapters';
import {GlobalVersionData} from '../../../../hooks/global-version/use-global-version-query';
import {
    CustomResizableLayout,
    ResizablePanel
} from '../../../../components/layout/resizable-layout';
import {NeighborhoodGraph} from './neighborhood-graph/neighborhood-graph';
import {useNeighborhoodGraphSettings} from './neighborhood-graph/hooks/use-neighborhood-graph-settings';
import {useDocLayoutSettings} from '../hooks/use-doc-layout-settings';
import {useGraphSelectionProps} from '../../../../components/graph/use-graph-selection-props';

export interface SelectedDocumentParams extends LocationParams {
    documentId: string;
}

interface OwnProps {
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    selectedGlobalVersion: GlobalVersionData;
}

export const DocumentView = ({
    match,
    findUsagesProps,
    selectedGlobalVersion
}: RouteComponentProps<SelectedDocumentParams> & OwnProps) => {
    const {params} = match;
    const {selectedRevision} = useDocRevisionsContext();

    const state = useEditorModel({
        documentId: params.documentId,
        revisionId: params.revisionId,
        globalVersionId: selectedGlobalVersion.id
    });

    const {documentInfo} = state;

    const docInfoToConsume = React.useMemo(
        () => (documentInfo ? [documentInfo] : []),
        [documentInfo]
    );
    useConsumeDocInfo(docInfoToConsume);

    const nodeId = params.nodeId && decodeURIComponent(params.nodeId);
    const name = selectedRevision?.documentName ?? documentInfo?.name;

    const [selectedFacts, setSelectedFacts] = React.useState<string[]>([]);
    const closeNeighborhoodGraph = React.useCallback(() => setSelectedFacts([]), []);

    const handleFindUsages = React.useCallback(
        (event: FindUsagesEditorEvent) =>
            findUsagesProps.findUsages(event, params.documentId, params.revisionId),
        [findUsagesProps, params.documentId, params.revisionId]
    );

    const neighborhoodGraphSettings = useNeighborhoodGraphSettings();
    const graphSelectionProps = useGraphSelectionProps();
    const docLayoutSettings = useDocLayoutSettings({
        selectedFacts,
        selectedNodes: graphSelectionProps.selectedNodes
    });

    return (
        <WindowTitle text={getDocPageTitle(name)}>
            <CustomResizableLayout
                localStorageKey="documents.explore.neighborhoodGraph.layoutSize"
                alignment="vertical"
                settings={{initialFirstPanelShare: 0.6, minPermittedHeight: 200}}
                fixedPanelSize={docLayoutSettings.graphPanelFixedSize}
            >
                {(resizableLayoutProps) => (
                    <React.Fragment>
                        <ResizablePanel index={0} {...resizableLayoutProps}>
                            <div className={styles.documentBody}>
                                <DocumentEditorHeader
                                    documentId={params.documentId}
                                    revisionId={params.revisionId}
                                    editorState={state}
                                    isBodyCollapsed={docLayoutSettings.isDocBodyCollapsed}
                                    toggleIsBodyCollapsed={
                                        docLayoutSettings.toggleIsDocBodyCollapsed
                                    }
                                    isGraphPanelOpen={!!selectedFacts.length}
                                />

                                <Editor
                                    documentId={params.documentId}
                                    revisionId={params.revisionId}
                                    nodeId={nodeId}
                                    editorState={state}
                                    handleFindUsages={handleFindUsages}
                                    selectedGlobalVersion={selectedGlobalVersion}
                                    selectedFacts={selectedFacts}
                                    onFactsSelectionChanged={
                                        !selectedRevision ? setSelectedFacts : () => null
                                    }
                                />
                            </div>
                        </ResizablePanel>

                        {selectedFacts.length ? (
                            <ResizablePanel index={1} {...resizableLayoutProps}>
                                <NeighborhoodGraph
                                    selectedFacts={selectedFacts}
                                    closeGraph={closeNeighborhoodGraph}
                                    documentId={params.documentId}
                                    globalVersionId={selectedGlobalVersion.id}
                                    key={selectedFacts.join(',')}
                                    graphSettings={neighborhoodGraphSettings}
                                    layoutSettings={docLayoutSettings}
                                    graphSelectionProps={graphSelectionProps}
                                />
                            </ResizablePanel>
                        ) : undefined}
                    </React.Fragment>
                )}
            </CustomResizableLayout>
        </WindowTitle>
    );
};
