import {StructuredGraph} from './structured-graph';
import {findGraphSccs} from './find-graph-sccs';

function elIsScc(elId: number) {
    return elId < 0;
}

function getAllNodesOfScc(sccId: number, sccByNodeMap: {[key: number]: number}) {
    return Object.keys(sccByNodeMap)
        .map(Number)
        .filter((sccNodeId) => sccByNodeMap[sccNodeId] === sccId);
}

function findMaxTargetNodesPathOneDirection(
    structuredGraph: StructuredGraph,
    startElement: number,
    nodesToIgnore: number[],
    targetNodeIds: number[],
    graphSccsInfo: ReturnType<typeof findGraphSccs>,
    direction: 'forward' | 'backward'
) {
    const {sccByNodeMap, edgesBySccMap} = graphSccsInfo;

    const currentPath = [startElement];
    // arr of target nodes that can be reached from each element
    const tNodesReachedFromEl: {[elementId: number]: number[]} = {};

    function getNextStepElements(elId: number) {
        return (function () {
            const edgesType = direction === 'forward' ? 'outgoingEdges' : 'incomingEdges';
            const nodesType = direction === 'forward' ? 'targetNode' : 'sourceNode';
            if (elIsScc(elId)) {
                return edgesBySccMap[elId][edgesType]
                    .map((edgeId) => structuredGraph.getEdge(edgeId))
                    .map((edge) => edge[nodesType]);
            }

            return (
                structuredGraph
                    .getNode(elId)
                    // eslint-disable-next-line no-unexpected-multiline
                    [edgesType].map((edge) => edge[nodesType])
            );
        })()
            .filter((node) => !nodesToIgnore.includes(node.id))
            .map((node) => sccByNodeMap[node.id] || node.id);
    }

    // first traversal, we must fill the tNodesReachedFromEl map
    while (currentPath.length) {
        const handledElementId = currentPath[currentPath.length - 1];
        const nextStepElements = getNextStepElements(handledElementId);

        const unhandledNextStepElements = nextStepElements.filter(
            (el) => tNodesReachedFromEl[el] === undefined
        );

        if (unhandledNextStepElements.length) {
            currentPath.push(unhandledNextStepElements[0]);
            continue;
        }

        const tNodesInThisEl = (function () {
            if (elIsScc(handledElementId)) {
                return getAllNodesOfScc(handledElementId, sccByNodeMap).filter((nodeId) =>
                    targetNodeIds.includes(nodeId)
                );
            }

            return targetNodeIds.includes(handledElementId) ? [handledElementId] : [];
        })();

        const tNodesReachedFromNextEls = nextStepElements.length
            ? (function () {
                  const nextElsTargetNodes = nextStepElements.map((el) => tNodesReachedFromEl[el]);

                  const maxTargetNodes = Math.max(
                      ...nextElsTargetNodes.map((targetNodesArr) => targetNodesArr.length)
                  );

                  return nextElsTargetNodes.find(
                      (targetNodesArr) => targetNodesArr.length === maxTargetNodes
                  )!;
              })()
            : [];

        tNodesReachedFromEl[handledElementId] = [...tNodesInThisEl, ...tNodesReachedFromNextEls];
        currentPath.pop();
    }

    const result: number[] = [];
    let currentlyHandledElements = [startElement];

    // second traversal, where we build the target path by choosing to visit the
    // elements with max number of target nodes that can be reached from it
    while (currentlyHandledElements.length) {
        const nextHandledElements = new Set<number>();

        currentlyHandledElements.forEach((el) => {
            const potentialNextStepElements = getNextStepElements(el);
            const maxTargetNodesOfNextStep = Math.max(
                ...potentialNextStepElements.map(
                    (nextStepEl) => tNodesReachedFromEl[nextStepEl].length
                )
            );

            if (maxTargetNodesOfNextStep === 0) {
                return;
            }

            const nextStep: number[] = [];

            potentialNextStepElements.forEach((nextStepEl) => {
                if (
                    tNodesReachedFromEl[nextStepEl].length === maxTargetNodesOfNextStep &&
                    !result.includes(nextStepEl) &&
                    // if there are multiple available paths from this node with the same number of
                    // reachable target nodes from it, but target nodes themselves are different,
                    // that means that some of the target nodes cannot be connected to the path
                    (!nextStep[0] ||
                        tNodesReachedFromEl[nextStep[0]].every((e) =>
                            tNodesReachedFromEl[nextStepEl].includes(e)
                        ))
                ) {
                    nextStep.push(nextStepEl);
                }
            });

            nextStep.forEach(nextHandledElements.add, nextHandledElements);
        });

        currentlyHandledElements = Array.from(nextHandledElements);
        result.push(...currentlyHandledElements);
    }

    const resultWithoutSccs: number[] = [];

    result.forEach((elementId) => {
        if (elIsScc(elementId)) {
            resultWithoutSccs.push(
                ...Object.keys(sccByNodeMap)
                    .map(Number)
                    .filter((sccNodeId) => sccByNodeMap[sccNodeId] === elementId)
            );
        } else {
            resultWithoutSccs.push(elementId);
        }
    });

    return resultWithoutSccs;
}

export function connectNodes(
    structuredGraph: StructuredGraph,
    targetNodeIds: number[],
    nodesToIgnore: number[]
) {
    // I take the first node here as start node, but the func can theoretically work with any node
    const [anyNode, ...rest] = targetNodeIds;
    // make graph acyclic
    const graphSccsInfo = findGraphSccs(structuredGraph, nodesToIgnore);
    const startElement = graphSccsInfo.sccByNodeMap[anyNode] || anyNode;

    // we go in two direction from a random node from target nodes arr,
    // and find two paths that include max available amount of target nodes...
    const forwardPaths = findMaxTargetNodesPathOneDirection(
        structuredGraph,
        startElement,
        nodesToIgnore,
        rest,
        graphSccsInfo,
        'forward'
    );
    const backwardPaths = findMaxTargetNodesPathOneDirection(
        structuredGraph,
        startElement,
        nodesToIgnore,
        rest,
        graphSccsInfo,
        'backward'
    );
    const startElementNodes = elIsScc(startElement)
        ? getAllNodesOfScc(startElement, graphSccsInfo.sccByNodeMap)
        : [startElement];

    const result = [...backwardPaths.reverse(), ...startElementNodes, ...forwardPaths];

    // ... then if every targetNode is included in these paths, we return them...
    if (targetNodeIds.every((nodeId) => result.includes(nodeId))) {
        return result;
    }

    // ... otherwise, path between target nodes cannot be built
    return [];
}
