import {GridCoordinates, StructuredGraphEdge, StructuredGraphNode} from '../interface';
import {GridCellContent, GridMap} from './interface';
import {getDistanceBetweenGraphPoints} from './helpers';
import {StructuredGraph} from './structured-graph';

export class GenestackGraphGrid {
    public nodesGridCoordinates: {[key: string]: GridCoordinates};
    public structuredGraph: StructuredGraph;

    public static getAllEdgesFromCell(gridCellContent: GridCellContent): StructuredGraphEdge[] {
        if (!gridCellContent) {
            return [];
        }

        if (GenestackGraphGrid.isCellHasNode(gridCellContent)) {
            return [...gridCellContent.incomingEdges, ...gridCellContent.outgoingEdges];
        }

        return gridCellContent;
    }

    public static isCellHasNode(
        gridCellContent: GridCellContent
    ): gridCellContent is StructuredGraphNode {
        return !!(gridCellContent && (gridCellContent as StructuredGraphNode).id);
    }

    constructor(structuredGraph: StructuredGraph) {
        this.nodesGridCoordinates = GenestackGraphGrid.createNodesGridCoordinates(
            structuredGraph.startNodes
        );
        this.structuredGraph = structuredGraph;
    }

    public get size() {
        let maxX = 0;
        let maxY = 0;

        Object.values(this.nodesGridCoordinates).forEach(({x, y}) => {
            if (x > maxX) {
                maxX = x;
            }

            if (y > maxY) {
                maxY = y;
            }
        });

        return {
            // tslint:disable-next-line: no-magic-numbers
            x: maxX + 3,
            y: maxY + 1
        };
    }

    private static createNodesGridCoordinates(startNodes: StructuredGraphNode[]) {
        let biggestX = -1;
        const allCoordinates: {[key: string]: GridCoordinates} = {};

        const assignCoordinates = (
            node: StructuredGraphNode,
            parentNodeCoordinates?: GridCoordinates,
            shouldAddHorizontalMargin?: boolean
        ) => {
            const coordinates = (function () {
                if (!parentNodeCoordinates) {
                    // eslint-disable-next-line no-return-assign
                    return {
                        x: (biggestX += 2),
                        y: 0
                    };
                }

                // eslint-disable-next-line no-return-assign
                return {
                    x: shouldAddHorizontalMargin ? (biggestX += 2) : parentNodeCoordinates.x,
                    y: parentNodeCoordinates.y + 2
                };
            })();

            allCoordinates[node.id] = coordinates;

            let shouldMoveChildrenHorizontally = false;
            node.outgoingEdges.forEach((edge) => {
                if (!allCoordinates[edge.targetNode.id]) {
                    assignCoordinates(edge.targetNode, coordinates, shouldMoveChildrenHorizontally);
                    shouldMoveChildrenHorizontally = true;
                }
            });
        };

        // tslint:disable-next-line: no-unnecessary-callback-wrapper
        startNodes.forEach((node) => {
            assignCoordinates(node);
        });

        return allCoordinates;
    }

    public edgesSortedByLength(edges: StructuredGraphEdge[]) {
        return edges.sort((edge1, edge2) => {
            const distance1 = getDistanceBetweenGraphPoints(
                this.nodesGridCoordinates[edge1.sourceNode.id],
                this.nodesGridCoordinates[edge1.targetNode.id]
            );
            const distance2 = getDistanceBetweenGraphPoints(
                this.nodesGridCoordinates[edge2.sourceNode.id],
                this.nodesGridCoordinates[edge2.targetNode.id]
            );

            return distance1 - distance2;
        });
    }

    public getGridMap(edgesGridPaths: {[key: string]: GridCoordinates[]}) {
        const {size} = this;

        const map: GridMap = [];
        for (let i = 0; i < size.x; i += 1) {
            map[i] = [];

            for (let j = 0; j < size.y; j += 1) {
                map[i][j] = null;
            }
        }

        this.structuredGraph.allNodes.forEach((node) => {
            const coordinates = this.nodesGridCoordinates[node.id];
            map[coordinates.x][coordinates.y] = node;
        });

        this.structuredGraph.allEdges.forEach((edge) => {
            const path = edgesGridPaths[edge.id];
            if (!path) {
                return;
            }

            path.slice(1, path.length - 1).forEach((step) => {
                const cellContent = map[step.x][step.y];
                if (Array.isArray(cellContent)) {
                    cellContent.push(edge);
                } else {
                    map[step.x][step.y] = [edge];
                }
            });
        });

        return map;
    }
}
