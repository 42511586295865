import React from 'react';

import {Preloader} from '../../../../../components/preloader/preloader';
import {Sidebar} from '../../../../../components/layout/sidebar';
import {useGlobalStateVariable} from '../../../../../providers/global-state';
import {getDocTypeTitleByType} from '../../../../../utils/document';
import {changeCategoryTreeState} from '../../../../../utils/ontology-tree-state/ontology-tree-state';
import {useDocTypesQuery} from '../../hooks/use-doc-types-query';

import {BtbDocuments} from './btb-ontology';
import {DocumentGroup} from './document-group';
import {EditViewSidebarProps} from '../interface';
import {SidebarHeader} from '../../../../../components/layout/sidebar/sidebar-header/sidebar-header';

export function CategoryTree(props: EditViewSidebarProps) {
    const [ontologyTreeState, setOntologyTreeState] = useGlobalStateVariable(
        'documents.edit.ontologyTree',
        {}
    );

    const changeIsCategoryOpenState = (isOpen: boolean, targetCategoryTreePath: string[]) => {
        const updatedOntologyTreeState = changeCategoryTreeState({
            isTargetCategoryOpen: isOpen,
            initialState: ontologyTreeState,
            targetCategoryTreePath
        });

        setOntologyTreeState(updatedOntologyTreeState);
    };

    const docTypesQuery = useDocTypesQuery();

    return (
        <Sidebar
            border="right"
            header={<SidebarHeader title="Browse documents by type" onClose={props.closeSidebar} />}
        >
            <Preloader show={docTypesQuery.isLoading}>
                {docTypesQuery.data?.map((docType) => {
                    const title = getDocTypeTitleByType(docType) ?? docType;
                    const ontologyBranchState = ontologyTreeState[docType];

                    // FIXME: get from backend info that docType contains categories
                    const Component = docType === 'BTB' ? BtbDocuments : DocumentGroup;

                    return (
                        <Component
                            key={docType}
                            docType={docType}
                            title={title}
                            branchOpenState={ontologyBranchState}
                            changeIsCategoryOpenState={changeIsCategoryOpenState}
                            categoryTreePath={[docType]}
                        />
                    );
                })}
            </Preloader>
        </Sidebar>
    );
}
