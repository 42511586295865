import React from 'react';
import {useHistory} from 'react-router-dom';

import {showNotification} from '../../../../providers/notifications-center';
import {DeletePermanentlyStatusNotification} from '../../../../providers/notifications-center/notifications/delete';
import {Sidebar} from '../../../../components/layout/sidebar';
import {TrashBinMenu} from '../../../../components/trash-bin-menu';
import {useLocationParams} from '../../../../hooks/use-location';
import {isDeletedDocument} from '../../../../utils/document';
import {extractInfiniteQueryItems} from '../../../../components/pagination/infinite-query-items';
import {useDeleteFromTrashBinMutation} from '../hooks/use-delete-from-trash-bin-mutation';
import {useDeletedDocumentsQuery} from '../hooks/use-deleted-documents-query';
import {useDocMetadataQuery} from '../hooks/use-doc-metadata-query';

import {PortionedDocsGroupContent} from './portioned-docs-group-content';
import {DocumentStatusIcon} from '../../common/doc-status-icon';
import {EditViewSidebarProps} from './interface';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';

export function DeletedDocuments(props: EditViewSidebarProps) {
    const {documentId} = useLocationParams();
    const deletedQuery = useDeletedDocumentsQuery();
    const deletedDocuments = extractInfiniteQueryItems(deletedQuery, (response) => response.data);
    const deleteFromTrashBin = useDeleteFromTrashBinMutation();
    const documentQuery = useDocMetadataQuery(documentId);

    const selectedDocument = documentQuery.data || null;

    const {push} = useHistory();

    const handleEmptyTrashBin = async () => {
        try {
            await deleteFromTrashBin.mutateAsync();

            if (selectedDocument && isDeletedDocument(selectedDocument)) {
                push('/documents/edit');
            }

            showNotification(<DeletePermanentlyStatusNotification />);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            showNotification(
                <DeletePermanentlyStatusNotification isError errorMessage={error.message} />
            );
        }
    };

    const header = (
        <SidebarHeader
            title="Recycle bin"
            additionalControl={
                <TrashBinMenu
                    isLoading={deleteFromTrashBin.isLoading}
                    onEmptyTrashBin={handleEmptyTrashBin}
                    disabled={deletedQuery.isLoading || !deletedDocuments?.length}
                />
            }
            onClose={props.closeSidebar}
        />
    );

    return (
        <Sidebar header={header} border="right">
            <PortionedDocsGroupContent
                isInitialLoading={deletedQuery.isLoading}
                docs={deletedDocuments}
                isLoadingMore={deletedQuery.isFetchingNextPage}
                onClickLoadMoreButton={deletedQuery.fetchNextPage}
                hasNextPage={deletedQuery.hasNextPage}
                disabled={deleteFromTrashBin.isLoading}
                DocStatusIconComponent={DocumentStatusIcon}
            />
        </Sidebar>
    );
}
