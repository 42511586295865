import {Controls, ControlsItem, Switch, SwitchProps, Typography} from '@genestack/ui';
import React from 'react';

import {UiPermission} from '../../../../typings/user';

type PermissionSettingsKeys = 'label' | 'description';

interface Props extends Omit<SwitchProps, 'onCheckedChange'> {
    value: UiPermission;
    onCheckedChange: (permission: UiPermission, checked: boolean) => void;
}

const PERMISSION_INFO: Record<UiPermission, Record<PermissionSettingsKeys, string>> = {
    [UiPermission.manageUsers]: {
        label: 'Manage users',
        description: 'Create new accounts, assign permissions and deactivate users'
    },
    [UiPermission.manageQueries]: {
        label: 'Manage queries',
        description: 'Access to query management panel, stop and rank queries'
    },
    [UiPermission.createGlobalVersions]: {
        label: 'Create KBGV',
        description: 'Create Global Versions'
    }
};

/** Switcher for user permission */
export function PermissionSwitcher({value, onCheckedChange, ...restProps}: Props) {
    const {label, description} = PERMISSION_INFO[value];

    const handleSwitchChange = (checked: boolean) => {
        onCheckedChange(value, checked);
    };

    return (
        <Controls gap={1} align="baseline">
            <ControlsItem>
                <Switch {...restProps} value={value} onCheckedChange={handleSwitchChange} />
            </ControlsItem>
            <ControlsItem shrink>
                <Typography>{label}</Typography>
                <Typography variant="caption" intent="quiet" ellipsis>
                    {description}
                </Typography>
            </ControlsItem>
        </Controls>
    );
}
