/* tslint:disable completed-docs */
import {DateTime} from 'luxon';

/** OT coordinates of a Genestack file */
export interface FileOTCoordinates {
    entityId: string; // this is a Genestack File Accession
    url: string;
}

interface DocTypeParams {
    title: string;
    label: string;
}

export const docTypeToParams = new Map<DocType, DocTypeParams>([
    [
        'BTB',
        {
            title: 'BTB Document',
            label: 'BTB'
        }
    ],
    [
        'IMPORTS',
        {
            title: 'Ontology import',
            label: 'OI'
        }
    ],
    [
        'ONTOLOGY',
        {
            title: 'Ontology library',
            label: 'OL'
        }
    ],
    [
        'KINDS',
        {
            title: 'Process kinds',
            label: 'PK'
        }
    ],
    [
        'PROCESSES',
        {
            title: 'Process library',
            label: 'PL'
        }
    ],
    [
        'RELATIONS',
        {
            title: 'Relations library',
            label: 'RL'
        }
    ],
    [
        'MODIFIERS',
        {
            title: 'Modifiers library',
            label: 'ML'
        }
    ],
    [
        'PROPERTIES',
        {
            title: 'Properties library',
            label: 'PPL'
        }
    ],
    [
        'NOTIONS',
        {
            title: 'Notions library',
            label: 'NL'
        }
    ],
    [
        'LINKS',
        {
            title: 'Links library',
            label: 'LL'
        }
    ],
    [
        'QUERY',
        {
            title: 'Query library',
            label: 'QL'
        }
    ],
    [
        'BLACKLIST',
        {
            title: 'Query Parameters Black List',
            label: 'BL'
        }
    ],
    [
        'FUNCTIONS',
        {
            title: 'Functions library',
            label: 'FL'
        }
    ],
    [
        'EXTENSIONS',
        {
            title: 'Extensions library',
            label: 'EL'
        }
    ],
    [
        'SOURCES',
        {
            title: 'Published knowledge sources',
            label: 'PS'
        }
    ]
]);

export const getDocTypeTitleByType = (type: DocType): string | undefined =>
    docTypeToParams.get(type)?.title;

export const getDocTypeLabelByType = (type: DocType): string | undefined =>
    docTypeToParams.get(type)?.label;

export const generateNewTitle = (type: DocType): string => {
    const docTitle = getDocTypeTitleByType(type) ?? 'Document';

    return `${docTitle} ${DateTime.local().toFormat('LLL dd, HH:mm')}`;
};

const isEditableDocument = (doc: DocInfo): doc is EditableDocument => doc.status === 'EDITABLE';
export const isDocumentDraft = (doc: DocInfo): doc is DraftDocument => doc.status === 'DRAFT';
export const isDeletedDocument = (doc: DocInfo): doc is DeletedDocument => doc.status === 'DELETED';

export const isDocumentBeingEdited = (doc: DocInfo) =>
    isEditableDocument(doc) || isDocumentDraft(doc);
