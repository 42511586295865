import {StructuredGraph} from './structured-graph';
import {StructuredGraphNode} from '../../interface';

function findConnectionToUnhidden(
    structuredGraph: StructuredGraph,
    nodeId: number,
    hiddenNodes: number[],
    direction: 'forward' | 'backward'
) {
    const startNode = structuredGraph.getNode(nodeId);

    const result = new Set<number>();
    const currentPath: StructuredGraphNode[] = [startNode];
    const handledNodes = new Set<number>([nodeId]);

    while (currentPath.length) {
        const handledNode = currentPath[currentPath.length - 1];
        handledNodes.add(handledNode.id);

        if (!hiddenNodes.includes(handledNode.id)) {
            currentPath.pop();
            currentPath.forEach((node) => result.add(node.id));
            continue;
        }

        const nextStepNodes =
            direction === 'forward'
                ? handledNode.outgoingEdges.map(({targetNode}) => targetNode)
                : handledNode.incomingEdges.map(({sourceNode}) => sourceNode);
        if (nextStepNodes.some((node) => result.has(node.id))) {
            currentPath.forEach((node) => result.add(node.id));
        }

        const nonHandledNextStepNode = nextStepNodes.find((node) => !handledNodes.has(node.id));

        if (nonHandledNextStepNode) {
            currentPath.push(nonHandledNextStepNode);
        } else {
            currentPath.pop();
        }
    }

    return Array.from(result);
}

export function connectToUnhidden(
    structuredGraph: StructuredGraph,
    nodeId: number,
    hiddenNodes: number[]
) {
    const forwardResult = findConnectionToUnhidden(structuredGraph, nodeId, hiddenNodes, 'forward');
    const backwardResult = findConnectionToUnhidden(
        structuredGraph,
        nodeId,
        hiddenNodes,
        'backward'
    );

    return Array.from(new Set([...forwardResult, ...backwardResult]));
}
