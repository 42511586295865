import React from 'react';

import {assert} from '../utils/assert';

import {
    EditModeTab,
    QueriesTab,
    QueryRightPanelTab,
    ViewModeTab
} from '../components/layout/page-layout/layout-typings';
import {SearchData} from '../application/document-workflow/common/sidebar-search-field/interface';

export const LS_PREFIX = 'JBKBv1';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OntologyTreeState<T extends object = any> = Record<string, T>;

export interface Variables {
    'global.version': number | null;

    'documents.explore.lastLocation': string;
    'documents.explore.layoutSize': number;
    'documents.explore.neighborhoodGraph.layoutSize': number;
    'documents.explore.neighborhoodGraph.factsetPanel.layoutSize': number;
    'documents.explore.lastTab': ViewModeTab;
    'documents.explore.searchData': SearchData | undefined;
    'documents.explore.sidebarExpanded': boolean;
    'documents.explore.sidebarClosed': boolean;

    'documents.edit.lastLocation': string;
    'documents.edit.layoutSize': number;
    'documents.edit.sidebarClosed': boolean;
    'documents.edit.lastTab': EditModeTab;
    'documents.edit.ontologyTree': OntologyTreeState;
    'documents.edit.onlyMyDraftsShown': boolean;
    'documents.edit.searchData': SearchData | undefined;
    'documents.edit.ontologySidebar.layoutSize': number;

    'queries.lastLocation': string;
    'queries.layoutSize': number;
    'queries.results.chain.layoutSize': number;
    'queries.lastTab': QueriesTab;
    'queries.sidebarClosed': boolean;
    'queries.factsDockedDown': boolean;
    'queries.legendCoordinates': {top: number; left: number};
    'queries.rightPanel.layoutSize': number;
    'queries.rightPanel.selectedTab': QueryRightPanelTab;

    'users.manager.layoutSize': number;
}

interface ContextValue {
    state: Partial<Variables>;
    setVariable: <K extends keyof Variables>(key: K, value: Variables[K]) => void;
}

const GlobalStateContext = React.createContext<ContextValue | null>(null);

interface Action {
    type: 'set';
    payload: {
        [K in keyof Variables]?: Variables[K];
    };
}

const reducer = (prevState: Partial<Variables>, action: Action) => ({
    ...prevState,
    ...action.payload
});

const initialValue: Partial<Variables> = {
    'global.version': null,

    'documents.explore.layoutSize': undefined,
    'documents.explore.lastLocation': undefined,
    'documents.explore.lastTab': undefined,
    'documents.explore.searchData': undefined,
    'documents.explore.sidebarExpanded': false,
    'documents.explore.sidebarClosed': false,
    'documents.explore.neighborhoodGraph.layoutSize': undefined,
    'documents.explore.neighborhoodGraph.factsetPanel.layoutSize': undefined,

    'documents.edit.layoutSize': undefined,
    'documents.edit.sidebarClosed': false,
    'documents.edit.lastLocation': undefined,
    'documents.edit.lastTab': undefined,
    'documents.edit.ontologyTree': {},
    'documents.edit.onlyMyDraftsShown': true,
    'documents.edit.searchData': undefined,
    'documents.edit.ontologySidebar.layoutSize': undefined,

    'queries.layoutSize': undefined,
    'queries.results.chain.layoutSize': undefined,
    'queries.lastLocation': undefined,
    'queries.lastTab': undefined,
    'queries.sidebarClosed': false,
    'queries.factsDockedDown': false,
    'queries.legendCoordinates': {top: 400, left: 500},
    'queries.rightPanel.layoutSize': undefined,
    'queries.rightPanel.selectedTab': QueryRightPanelTab.STATISTICS,

    'users.manager.layoutSize': undefined
};

// not only initializes a state value, but also iterates over
// object keys and tries to extract values from LocalStorage
const initializer = <S extends Partial<Variables>>(rawInitialValue: S) => {
    return Object.keys(rawInitialValue).reduce<Partial<Variables>>((acc, key) => {
        const value = rawInitialValue[key as keyof S];

        const lsKey = `${LS_PREFIX}.${key}`;

        try {
            const localStorageValue = localStorage.getItem(lsKey);
            if (localStorageValue !== null) {
                return {...acc, [key]: JSON.parse(localStorageValue)};
            }

            if (value) {
                localStorage.setItem(lsKey, JSON.stringify(value));
            }

            return {...acc, [key]: value};
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw. JSON.parse and JSON.stringify
            // can throw, too.
            // eslint-disable-next-line no-console
            console.warn(`Couldn't access LocalStorage with key "${lsKey}"`);

            return {...acc, [key]: value};
        }
    }, {});
};

export const GlobalStateProvider = (props: {children?: React.ReactNode}) => {
    const [state, dispatch] = React.useReducer(reducer, initialValue, initializer);

    // FIXME subscribe for LS changes and trigger `setVariable` call to keep in-memory state updated

    const setVariable: ContextValue['setVariable'] = React.useCallback((key, value) => {
        dispatch({type: 'set', payload: {[key]: value}});

        const lsKey = `${LS_PREFIX}.${key}`;
        try {
            localStorage.setItem(lsKey, JSON.stringify(value));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`Couldn't access LocalStorage with key "${lsKey}"`);
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const contextValue = React.useMemo(() => ({state, setVariable}), [state]);

    return (
        <GlobalStateContext.Provider value={contextValue}>
            {props.children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => {
    const value = React.useContext(GlobalStateContext);
    assert(value, 'Can only be used under `GlobalStateProvider`');

    return value;
};

type UseGlobalStateVariableValue<K extends keyof Variables> = [
    Variables[K] | NonNullable<Partial<Variables>[K]>,
    (value: Variables[K]) => void
];

export const useGlobalStateVariable = <K extends keyof Variables>(
    key: K | undefined,
    defaultValue: Variables[K]
): UseGlobalStateVariableValue<K> => {
    const globalState = useGlobalState();

    const setValue = React.useCallback(
        (variableValue: Variables[K]) => {
            if (key) {
                globalState.setVariable(key, variableValue);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [key]
    );

    const value = (function () {
        if (!key || globalState.state[key] === undefined) {
            return defaultValue;
        }

        return globalState.state[key] as Variables[K];
    })();

    return [value, setValue];
};
