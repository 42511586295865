import React from 'react';
import {ScrollView, Divider, Typography, Button} from '@genestack/ui/dist';
import classNames from 'classnames';
import {WindowTitle} from '../../components/window-title';
import {ManagementPanelHeader} from '../../components/management-panel/management-panel-header';
import {ManagementPanel} from '../../components/management-panel/management-panel';
import commonMPStyles from '../../components/management-panel/management-panel.module.css';
import gvMPStyles from './gv-management-panel.module.css';
import {CreateVersionDialog} from './create-version-dialog/create-version-dialog';
import {GlobalVersionDataResponseItem} from '../../hooks/global-version/use-global-version-query';
import {GVItem} from './gv-item';
import {useCanCreateGV} from '../../hooks/user/use-has-permission';

interface Props {
    globalVersions: GlobalVersionDataResponseItem[] | null;
}

export function GVManagementPanel(props: Props) {
    const [createVersionDialogOpen, setCreateVersionDialogOpen] = React.useState(false);
    const isCanCreateGV = useCanCreateGV();

    const toggleCreateVersionDialog = React.useCallback(() => {
        setCreateVersionDialogOpen((prev) => !prev);
    }, []);

    return (
        <WindowTitle text="Global Versions">
            <ManagementPanel>
                <ScrollView>
                    <ManagementPanelHeader title="Global versions management panel">
                        {isCanCreateGV && (
                            <Button onClick={toggleCreateVersionDialog}>Create new version</Button>
                        )}
                    </ManagementPanelHeader>

                    <div className={classNames(gvMPStyles.gvRow, commonMPStyles.tableRow)}>
                        <Typography variant="section">Global versions</Typography>

                        <Typography intent="quiet" variant="body">
                            Status
                        </Typography>

                        <Typography intent="quiet" variant="body">
                            Description
                        </Typography>

                        <Typography intent="quiet" variant="body">
                            Created by
                        </Typography>

                        <Typography intent="quiet" variant="body">
                            Creation date
                        </Typography>

                        <Typography intent="quiet" variant="body">
                            Invalid documents
                        </Typography>
                    </div>

                    <Divider gap={0} />

                    {(function () {
                        if (!props.globalVersions) {
                            return null;
                        }

                        return props.globalVersions.map((gv) => (
                            <GVItem globalVersionInfo={gv} key={gv.id} />
                        ));
                    })()}
                </ScrollView>
            </ManagementPanel>

            <CreateVersionDialog
                open={createVersionDialogOpen}
                onClose={toggleCreateVersionDialog}
            />
        </WindowTitle>
    );
}
