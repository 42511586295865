import React from 'react';

import {DocumentNodes} from '../../../common/document-nodes';
import {logError} from '../../../../../utils/log-error';

import {convertNodes, UsageNodesType} from './hooks/use-find-usages-query';

interface NodesState {
    hasNextPage: boolean;
    data: DocumentNode[];
}

interface Props {
    docAccession: string;
    nodesData: UsageNodesType;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export function EditModeDocumentNodes({docAccession, nodesData, getDocumentLink}: Props) {
    const [isLoading, setLoadingState] = React.useState<boolean>(false);
    const [nodes, setNodes] = React.useState<NodesState>({
        hasNextPage: nodesData.hasNextPage,
        data: nodesData.data
    });

    const onLoadNextPage = React.useCallback(async () => {
        setLoadingState(true);

        try {
            const usageData = await nodesData.nextPage();
            const nodeList = await convertNodes(docAccession, usageData.data);

            setNodes({
                hasNextPage: usageData.hasNextPage,
                data: [...nodes.data, ...nodeList]
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            logError(err);
        }

        setLoadingState(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docAccession, nodes]);

    return (
        <DocumentNodes
            isLoading={isLoading}
            docAccession={docAccession}
            nodes={nodes.data}
            hasNextPage={nodes.hasNextPage}
            onLoadNextPage={onLoadNextPage}
            getDocumentLink={getDocumentLink}
        />
    );
}
