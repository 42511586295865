import React from 'react';
import {matchPath, useLocation} from 'react-router-dom';

import {useGlobalStateVariable} from '../providers/global-state';

import {useUserEditingPermissions} from './user/use-has-permission';
import {LayoutType} from '../components/layout/page-layout/layout-typings';

interface MatchPathParams {
    app: 'queries' | 'documents';
    view?: 'explore' | 'edit';
}

export function useNavigationInfo() {
    const {pathname} = useLocation();
    const {editingPermitted} = useUserEditingPermissions();

    const [viewModeLastLocation] = useGlobalStateVariable('documents.explore.lastLocation', '');
    const [editModeLastLocation] = useGlobalStateVariable('documents.edit.lastLocation', '');
    const [queriesLastLocation] = useGlobalStateVariable('queries.lastLocation', '');

    const routerMatch = matchPath<MatchPathParams>(pathname, {
        path: '/:app(queries|documents)/:view(explore|edit)?'
    });

    const activeItem = React.useMemo((): LayoutType | undefined => {
        if (routerMatch?.params.app === 'queries') {
            return 'queries';
        }

        if (routerMatch?.params.view === 'edit') {
            return 'edit';
        }

        if (routerMatch?.params.view === 'explore') {
            return 'explore';
        }

        return undefined;
    }, [routerMatch?.params.app, routerMatch?.params.view]);

    return {
        activeItem,
        list: [
            {
                title: 'Explore mode',
                label: 'Explore',
                value: 'explore',
                path: viewModeLastLocation
                    ? `/documents/explore/${viewModeLastLocation}`
                    : '/documents/explore'
            },
            ...(editingPermitted
                ? [
                      {
                          title: 'Edit mode',
                          label: 'Edit',
                          value: 'edit',
                          path: editModeLastLocation
                              ? `/documents/edit/${editModeLastLocation}`
                              : '/documents/edit'
                      }
                  ]
                : []),
            {
                title: 'Queries',
                label: 'Queries',
                value: 'queries',
                path: queriesLastLocation ? `/queries/${queriesLastLocation}` : '/queries'
            }
        ]
    };
}
