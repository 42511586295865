import React from 'react';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

import {showNotification} from '../../providers/notifications-center';
import {LinkCopiedNotification} from './link-copied-notification';

interface Props {
    pathname: string;
    searchParams?: Record<string, string>;
}

function getSearchString(searchParams: Record<string, string> = {}) {
    const currentSearchParams = new URLSearchParams(document.location.search);

    for (const param of Object.keys(searchParams)) {
        const value = searchParams[param];
        currentSearchParams.set(param, value);
    }

    return currentSearchParams.toString() ? `?${currentSearchParams.toString()}` : '';
}

/** A hook returning a function that, when invoked, copies current location to clipboard */
export function useCopyLink(props: Props) {
    const [copyToClipboardState, copyToClipboard] = useCopyToClipboard();

    React.useEffect(() => {
        if (copyToClipboardState.value && !copyToClipboardState.error) {
            showNotification(<LinkCopiedNotification />);
        }
    }, [copyToClipboardState]);

    return React.useCallback(() => {
        const searchString = getSearchString(props.searchParams);

        copyToClipboard(`${document.location.origin}${props.pathname}${searchString}`);
    }, [copyToClipboard, props.searchParams, props.pathname]);
}
