import {
    Tooltip,
    TooltipHandler,
    IconProps,
    TrashIcon,
    WorkingCopyOwnIcon,
    WorkingCopyEdited
} from '@genestack/ui';
import React from 'react';

import {EyeIcon} from '../../../../../components/icons/eye-icon';

import styles from './tooltip-icons.module.css';

export const OwnWorkingCopyTooltipIcon = (props: IconProps) => (
    <TooltipHandler tooltip={<Tooltip portalContainer={document.body}>My draft</Tooltip>}>
        <span className={styles.iconWrapper}>
            <WorkingCopyOwnIcon data-qa="own-working-copy-icon" {...props} />
        </span>
    </TooltipHandler>
);

export const WorkingCopyTooltipIcon = (props: IconProps) => (
    <TooltipHandler tooltip={<Tooltip portalContainer={document.body}>Someone is editing</Tooltip>}>
        <span className={styles.iconWrapper}>
            <WorkingCopyEdited data-qa="working-copy-icon" {...props} />
        </span>
    </TooltipHandler>
);

export const ReadOnlyTooltipIcon = (props: IconProps) => (
    <TooltipHandler tooltip={<Tooltip portalContainer={document.body}>Read-only</Tooltip>}>
        <span className={styles.iconWrapper}>
            <EyeIcon data-qa="read-only-icon" {...props} />
        </span>
    </TooltipHandler>
);

export const DeletedTooltipIcon = (props: IconProps) => (
    <TooltipHandler tooltip={<Tooltip portalContainer={document.body}>Deleted</Tooltip>}>
        <span className={styles.iconWrapper}>
            <TrashIcon data-qa="bin-icon" {...props} />
        </span>
    </TooltipHandler>
);
