import React, {PropsWithChildren} from 'react';
import {Controls, ControlsItem, Divider, Typography} from '@genestack/ui';
import styles from './management-panel.module.css';

interface Props {
    title: string;
}

export function ManagementPanelHeader(props: PropsWithChildren<Props>) {
    return (
        <React.Fragment>
            <Controls justify="space-between" className={styles.pageHeader}>
                <ControlsItem>
                    <Typography intent="quiet" variant="title">
                        {props.title}
                    </Typography>
                </ControlsItem>

                {props.children && <ControlsItem>{props.children}</ControlsItem>}
            </Controls>

            {/* tslint:disable-next-line: no-magic-numbers */}
            <Divider variant="transparent" gap={4} />
        </React.Fragment>
    );
}
