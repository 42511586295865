import React from 'react';
import {GraphNode} from './interface';

interface Props {
    targetNodeIds: number[];
    setTargetNodeIds: (nodes: number[]) => void;
}

function mapNodesByNodeId(nodes: GraphNode[]) {
    const nodesMap: {[key: number]: GraphNode} = {};
    nodes.forEach((node) => {
        nodesMap[node.id] = node;
    });
    return nodesMap;
}

function mapNodesByRawNodeId(nodes: GraphNode[]) {
    const nodesMap: {[key: number]: GraphNode} = {};
    nodes.forEach((node) => {
        node.rawNodeIds.forEach((rawNodeId) => {
            nodesMap[rawNodeId] = node;
        });
    });
    return nodesMap;
}

/* this code saves some data abt nodes (eg current selection)
     and persists it after new nodes array has been loaded.
     it is mostly needed for changing view kind bc node ids differ
     between view kinds, so we have to map nodes by rawNodeId here.
      it also fires on change max path length, but does nothing there */

export function usePersistNodesOnChangeViewKind({targetNodeIds, setTargetNodeIds}: Props) {
    const previousLoadedNodesRef = React.useRef<GraphNode[]>();

    return React.useCallback(
        function onNodesLoaded(loadedNodes: GraphNode[]) {
            if (previousLoadedNodesRef.current) {
                const oldNodesByIdMap = mapNodesByNodeId(previousLoadedNodesRef.current);
                const persistedRawIds: number[] = [];
                targetNodeIds.forEach((nodeId) => {
                    persistedRawIds.splice(
                        persistedRawIds.length,
                        0,
                        ...oldNodesByIdMap[nodeId].rawNodeIds
                    );
                });

                const newNodesByRawIdMap = mapNodesByRawNodeId(loadedNodes);
                const matchingNewNodes = new Set<number>(
                    persistedRawIds
                        .filter((rawId) => newNodesByRawIdMap[rawId])
                        .map((rawId) => newNodesByRawIdMap[rawId].id)
                );

                setTargetNodeIds(Array.from(matchingNewNodes));
            }

            previousLoadedNodesRef.current = loadedNodes;
        },
        [targetNodeIds, setTargetNodeIds]
    );
}
