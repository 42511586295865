import React from 'react';
import {HiddenNodesActionsProps} from './interface';
import {connectNodes} from '../connect-nodes';
import {showWarningNotification} from '../../../../../providers/notifications-center/notifications-store';
import {getPathsThroughNodes} from '../get-paths-through-nodes';

interface Props extends HiddenNodesActionsProps {
    setHiddenNodesAndMovePositions: (newHiddenNodes: number[]) => void;
    nonHiddenSelectedNodes: number[];
}

export function useBuildPaths(props: Props) {
    const {hiddenNodes, structuredGraph, setHiddenNodesAndMovePositions, nonHiddenSelectedNodes} =
        props;

    const connectSelectedNodes = React.useCallback(() => {
        const pathNodes = connectNodes(structuredGraph!, nonHiddenSelectedNodes, hiddenNodes);

        if (!pathNodes.length) {
            showWarningNotification(
                'Impossible to build a path that goes through all nodes selected.'
            );
            return;
        }

        const newHiddenNodes = structuredGraph!.allNodes
            .filter((node) => !pathNodes.includes(node.id))
            .map(({id}) => id);

        setHiddenNodesAndMovePositions(newHiddenNodes);
    }, [structuredGraph, nonHiddenSelectedNodes, hiddenNodes, setHiddenNodesAndMovePositions]);

    const buildPathsThroughSelected = React.useCallback(() => {
        const pathNodes = getPathsThroughNodes(
            structuredGraph!,
            nonHiddenSelectedNodes,
            hiddenNodes
        );

        if (!pathNodes.length) {
            showWarningNotification(
                'Impossible to build a path that goes through all nodes selected.'
            );
            return;
        }

        const newHiddenNodes = structuredGraph!.allNodes
            .filter((node) => !pathNodes.includes(node.id))
            .map(({id}) => id);

        setHiddenNodesAndMovePositions(newHiddenNodes);
    }, [structuredGraph, nonHiddenSelectedNodes, hiddenNodes, setHiddenNodesAndMovePositions]);

    return {
        connectSelectedNodes,
        buildPathsThroughSelected
    };
}
