import React from 'react';
import {chain} from '@genestack/ui';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {GraphType, GraphViewType} from '../../../../../components/graph/interface';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';

interface Props {
    queryId: number;
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
    maxPathLengthOptions: MaxPathLengthOptionResult;
    handlePresentationEdited: () => void;
}

export function useGraphSettings({
    queryId,
    selectedPresentation,
    selectedPresentationDetails,
    maxPathLengthOptions,
    handlePresentationEdited
}: Props) {
    const [graphType, setGraphType, clearGraphType] = useLsValue<GraphType, [number, number], []>({
        changeEntityParams: [queryId, selectedPresentation.id],
        getKey: ([qId, pId]) => `${LS_PREFIX}.queryGraph${qId}.presentation${pId}.graphType`,
        defaultValue: selectedPresentationDetails.graphType,
        validateValue: (value) => Object.values(GraphType).includes(value as GraphType),
        rewriteKeyParams: []
    });
    // todo: mb rename viewType to viewKind EVERYWHERE bc that's how it called on BE
    const [selectedViewType, setSelectedViewType, clearSelectedViewType, isDefaultViewType] =
        useLsValue<GraphViewType, [number, number], []>({
            changeEntityParams: [queryId, selectedPresentation.id],
            getKey: ([qId, pId]) => `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.viewType`,
            defaultValue: selectedPresentationDetails.viewKind,
            validateValue: (value) => Object.values(GraphViewType).includes(value as GraphViewType),
            rewriteKeyParams: []
        });

    const [maxPathLength, setMaxPathLength, clearMaxPathLength, isDefaultMPLength] = useLsValue<
        string,
        [number, number],
        [GraphType]
    >({
        rewriteKeyParams: [graphType as GraphType],
        changeEntityParams: [queryId, selectedPresentation.id],
        getKey: ([qId, pId], [gType]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.${gType}.maxPathLength`,
        defaultValue: String(selectedPresentationDetails.maxPathLength),
        validateValue: (value) => {
            return !!maxPathLengthOptions[selectedViewType as GraphViewType].find(
                (option) => option.pathLength === Number(value)
            );
        }
    });

    return {
        maxPathLength: Number(maxPathLength),
        setMaxPathLength: React.useCallback(
            (val: number) => {
                setMaxPathLength(String(val));
                handlePresentationEdited();
            },
            [setMaxPathLength, handlePresentationEdited]
        ),
        clearMaxPathLength,
        isDefaultMPLength,
        graphType,
        setGraphType: chain(setGraphType, handlePresentationEdited)!,
        clearGraphType,
        selectedViewType,
        setSelectedViewType,
        toggleSelectedViewType: React.useCallback(() => {
            setSelectedViewType(
                selectedViewType === GraphViewType.MERGED
                    ? GraphViewType.IDENTICAL
                    : GraphViewType.MERGED
            );
            handlePresentationEdited();
        }, [setSelectedViewType, handlePresentationEdited, selectedViewType]),
        clearSelectedViewType,
        isDefaultViewType
    };
}
