import React from 'react';

import {DocumentContent} from './document-content';
import {DocumentContentPreloader} from './document-content-preloader';
import {useConnectionsInfiniteQuery} from '../path-result-panel/use-connections-infinite-query';
import {extractInfiniteQueryItems} from '../../../pagination/infinite-query-items';
import {PaginatedList} from '../../../pagination/paginated-list';

interface Props {
    globalVersionId: number;
    queryId: number;
    connectionData: ConnectionDto;
}

const PAGE_SIZE = 7;

export function DocumentContentsList({queryId, globalVersionId, connectionData}: Props) {
    const connectionQuery = useConnectionsInfiniteQuery({
        queryId,
        connectionId: connectionData.id
    });

    const items = extractInfiniteQueryItems(
        connectionQuery,
        (result: PageResponse<PathEntryDto, number>) => result.data
    );

    const renderPathEntry = React.useCallback(
        (pathEntry: PathEntryDto, ndx: number) => {
            const element = (
                <DocumentContent
                    globalVersionId={globalVersionId}
                    entry={pathEntry}
                    includeCounter
                    indentation={0}
                />
            );

            return [ndx, element] as [React.Key, React.ReactElement];
        },
        [globalVersionId]
    );

    return (
        <DocumentContentPreloader
            show={connectionQuery.isLoading}
            count={connectionData.pathLength > PAGE_SIZE ? PAGE_SIZE : connectionData.pathLength}
        >
            <PaginatedList
                mode="manual"
                pageSize={PAGE_SIZE}
                items={items ?? []}
                isLoadingNextPage={connectionQuery.isFetchingNextPage}
                hasNextPage={connectionQuery.hasNextPage}
                renderItem={renderPathEntry}
                onLoadNext={connectionQuery.fetchNextPage}
            />
        </DocumentContentPreloader>
    );
}
