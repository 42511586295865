import {ListItemProps} from '@genestack/ui';
import React from 'react';

import {ListItemLink} from '../../../../../components/list-item-link';
import {DocumentUnit} from '../../../common/document-unit';

import styles from './document-link.module.css';
import {DocumentMeta} from './document-meta';

export interface Props {
    documentId: string;
    displayFavoriteState?: boolean;
    getDocumentLink: (documentId: string) => string;
    active: boolean;
    docInfo: ViewModeDocInfo;
}

const appendProps: ListItemProps['appendProps'] = {
    className: styles.append
};

// eslint-disable-next-line react/display-name
export const DocumentLink = React.memo(
    ({
        documentId,
        active = false,
        displayFavoriteState = true,
        getDocumentLink,
        docInfo,
        ...restProps
    }: Props) => {
        return (
            <ListItemLink
                append={
                    <DocumentMeta displayFavoriteState={displayFavoriteState} docInfo={docInfo} />
                }
                appendProps={appendProps}
                to={getDocumentLink(documentId)}
                active={active}
                {...restProps}
            >
                <DocumentUnit docInfo={docInfo} selected={active} />
            </ListItemLink>
        );
    }
);
