import {BookmarkBorderedIcon, chain, SearchIcon, TimeReverseIcon, WarningIcon} from '@genestack/ui';
import React from 'react';

import {ViewModeTab} from '../../../components/layout/page-layout/layout-typings';
import {useSidebarTabs} from '../../../hooks/use-sidebar-tabs';
import {usePageLayout} from '../../../components/layout/page-layout/use-page-layout';
import {VerticalBar, VerticalBarItem} from '../../../components/layout/vertical-bar/vertical-bar';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Search',
        value: ViewModeTab.SEARCH,
        prepend: <SearchIcon />
    },
    {
        tooltip: 'Show recent document',
        value: ViewModeTab.RECENT,
        prepend: <TimeReverseIcon />
    },
    {
        tooltip: 'Show my bookmarks',
        value: ViewModeTab.FAVORITES,
        prepend: <BookmarkBorderedIcon />
    },
    {
        tooltip: 'Show invalid documents',
        value: ViewModeTab.INVALID,
        prepend: <WarningIcon />
    }
];

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
    layoutSettings: ReturnType<typeof usePageLayout>;
}

export function ViewModeActivityBar({tabSettings, layoutSettings}: Props) {
    return (
        <VerticalBar
            tabs={SIDEBAR_TABS}
            value={layoutSettings.isSidebarClosed ? undefined : tabSettings.activityBarTab}
            onValueChange={chain(tabSettings.setActivityBarTab, layoutSettings.openSidebar)}
        />
    );
}
