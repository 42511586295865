import {Typography, PageContent} from '@genestack/ui';
import * as React from 'react';

import {UserIdentity} from '../../../../typings/user';
import {useLocationParams} from '../../../hooks/use-location';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';

import {UserItem, UserItemProps} from './user-item';

interface Props {
    users: UserIdentity[];
    lastAddedUserEmail: string | null;
    highLightedText: UserItemProps['highLightedText'];
}

/** The list of users */
export function UserList(props: Props) {
    const locationParams = useLocationParams();
    const currentUserQuery = useCurrentUserQuery();

    const [userToHighlight, setUserToHighlight] = React.useState(props.lastAddedUserEmail);
    const userRef = React.useRef(props.lastAddedUserEmail);

    React.useEffect(() => {
        if (props.lastAddedUserEmail !== userRef.current) {
            setUserToHighlight(props.lastAddedUserEmail);
        }

        userRef.current = props.lastAddedUserEmail;
    }, [props.lastAddedUserEmail]);

    React.useEffect(() => {
        if (userToHighlight) {
            setUserToHighlight(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users]);

    return props.users.length ? (
        <>
            {props.users.map((user) => (
                <UserItem
                    key={user.email}
                    user={user}
                    highlight={user.email === userToHighlight}
                    highLightedText={props.highLightedText}
                    isCurrentUser={user.id === currentUserQuery.data?.id}
                    active={locationParams.userId === user.id}
                />
            ))}
        </>
    ) : (
        <PageContent>
            <Typography intent="quiet">No such user</Typography>
        </PageContent>
    );
}
