import React from 'react';
import {useLsValue} from '../../../../../hooks/use-ls-value';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {GraphPresentation, GraphPresentationDetails} from './interface';
import {StructuredGraph} from '../../../../../components/graph/biokb-graph/structured-graph';

interface Props {
    queryId: number;
    selectedPresentation: GraphPresentation;
    selectedPresentationDetails: GraphPresentationDetails;
    handlePresentationEdited: () => void;
    selectedNodes: number[];
    setSelectedNodes: (nodes: number[]) => void;
    structuredGraph?: StructuredGraph;
}

export function deselectFullyHiddenNodes(
    hiddenNodes: number[],
    selectedNodes: number[],
    setSelectedNodes: (nodeIds: number[]) => void
) {
    const hiddenNodesSet = new Set([...hiddenNodes]);
    const notHiddenSelectedNodes = selectedNodes.filter((nodeId) => !hiddenNodesSet.has(nodeId));
    if (notHiddenSelectedNodes.length !== selectedNodes.length) {
        setSelectedNodes(notHiddenSelectedNodes);
    }
}

export function useHiddenNodes(props: Props) {
    const {
        queryId,
        selectedPresentation,
        selectedPresentationDetails,
        handlePresentationEdited,
        selectedNodes,
        setSelectedNodes,
        structuredGraph
    } = props;

    const [hiddenNodes, setHiddenNodes, clearHiddenNodes] = useLsValue<
        string,
        [number, number],
        []
    >({
        changeEntityParams: [queryId, selectedPresentation.id],
        getKey: ([qId, pId]) =>
            `${LS_PREFIX}.queryGraph.${qId}.presentation${pId}.hiddenExplicitlyNodes`,
        rewriteKeyParams: [],
        defaultValue: JSON.stringify(selectedPresentationDetails.hiddenNodes),
        validateValue: (value) => !JSON.parse(value).map(Number).some(Number.isNaN)
    });

    const hiddenNodesParsed = React.useMemo(() => {
        if (!structuredGraph) {
            return [];
        }

        const hiddenNodesRawIds = new Set(JSON.parse(hiddenNodes).map(Number));
        const result: number[] = [];
        structuredGraph.allNodes.forEach((node) => {
            if (node.rawNodeIds.every((rawNodeId) => hiddenNodesRawIds.has(rawNodeId))) {
                result.push(node.id);
            }
        });
        return result;
    }, [hiddenNodes, structuredGraph]);

    const [isHiddenNodesVisible, setIsHiddenNodesVisible] = React.useState(false);
    const toggleIsHiddenNodesVisible = React.useCallback(() => {
        setIsHiddenNodesVisible(!isHiddenNodesVisible);
        if (isHiddenNodesVisible) {
            deselectFullyHiddenNodes(hiddenNodesParsed, selectedNodes, setSelectedNodes);
        }
    }, [selectedNodes, hiddenNodesParsed, setSelectedNodes, isHiddenNodesVisible]);

    const isHiddenNode = React.useCallback(
        (nodeId: number) => new Set(hiddenNodesParsed).has(nodeId),
        [hiddenNodesParsed]
    );

    const isHiddenEdge = React.useCallback(
        (edgeId: number) => {
            // we assume that when you need this function, you already have your structuredGraph
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            const edge = structuredGraph?.getEdge(edgeId)!;
            return isHiddenNode(edge.sourceNode.id) || isHiddenNode(edge.targetNode.id);
        },
        [structuredGraph, isHiddenNode]
    );

    return {
        hiddenNodes: hiddenNodesParsed,
        hiddenRawNodeIds: React.useMemo(() => JSON.parse(hiddenNodes).map(Number), [hiddenNodes]),
        setHiddenRawNodeIds: React.useCallback(
            (arr: number[]) => {
                setHiddenNodes(arr.toString());
            },
            [setHiddenNodes]
        ),
        setHiddenNodes: React.useCallback(
            (arr: number[]) => {
                handlePresentationEdited();
                let rawIds: number[] = [];
                arr.forEach((nodeId) => {
                    rawIds = [...rawIds, ...structuredGraph!.getNode(nodeId)!.rawNodeIds];
                });
                setHiddenNodes(JSON.stringify(rawIds));
            },
            [setHiddenNodes, handlePresentationEdited, structuredGraph]
        ),
        clearHiddenNodes,
        isHiddenNodesVisible,
        toggleIsHiddenNodesVisible,
        isHiddenNode,
        isHiddenEdge
    };
}
