import {useInfiniteQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

const PAGE_SIZE = 100;

export function useListFavoritesInfiniteQuery(globalVersionId: number) {
    return useInfiniteQuery(
        ['favoritesViewMode', globalVersionId],
        () =>
            apiClient.callApi<PageResponse<ViewModeDocInfo>>({
                path: 'documents-revisions-service/api/user/documents/favorite',
                method: 'GET',
                query: {
                    limit: PAGE_SIZE,
                    'global-version': globalVersionId
                }
            }),

        {
            getNextPageParam: ({nextCursor, remaining}) => {
                const hasNextPage = remaining > 0;

                if (hasNextPage) {
                    return {
                        cursor: nextCursor,
                        limit: PAGE_SIZE
                    };
                }
            },
            keepPreviousData: true
        }
    );
}
