import React from 'react';
import {useQueryGraphProps} from './results-graph/use-query-graph-props';
import styles from '../query-layout.module.css';
import {CustomResizableLayout, ResizablePanel} from '../../../../components/resizable-layout';
import {QueryEditor} from '../query-editor';
import {QueryResultsHeader} from './query-results-header/query-results-header';
import {QueryResults} from './query-results';
import statsPanelStyles from './right-panel/right-panel.module.css';
import {RightPanel} from './right-panel/right-panel';
import {CompletedQueryInnerContentProps} from './completed-query-content-with-presentations';
import {GraphPresentationPropsLoaded} from './results-graph/use-graph-presentation-props';
import {useGraphPresentationActions} from './results-graph/use-graph-presentation-actions';
import {useIsCantDisplayGraph} from './results-graph/use-is-cant-display-graph';

interface Props extends Omit<CompletedQueryInnerContentProps, 'presentations'> {
    graphPresentationProps: GraphPresentationPropsLoaded;
}

export function CompletedQueryInnerContent({
    selectedQuery,
    globalVersions,
    queryLayoutProps,
    resultsListProps,
    selectionProps,
    maxPathLengthOptions,
    viewTypesOptions,
    graphPresentationProps,
    setMaxPathLength
}: Props) {
    const resultsGraphProps = useQueryGraphProps({
        selectedQuery,
        maxPathLengthOptions,
        viewTypesOptions,
        selectedPresentation: graphPresentationProps.selectedPresentation,
        selectedPresentationDetails: graphPresentationProps.presentationDetails,
        selectionProps
    });

    React.useEffect(() => {
        setMaxPathLength(resultsGraphProps.graphSettings.maxPathLength);
        return () => setMaxPathLength(undefined);
    }, [setMaxPathLength, resultsGraphProps.graphSettings.maxPathLength]);

    const presentationActionsProps = useGraphPresentationActions({
        graphPresentationProps,
        graphProps: resultsGraphProps,
        queryId: selectedQuery.id
    });

    const isCantDisplayGraphProps = useIsCantDisplayGraph({
        graphProps: resultsGraphProps,
        maxPathLengthOptions
    });

    return (
        <CustomResizableLayout
            localStorageKey="queries.rightPanel.layoutSize"
            settings={{initialFirstPanelShare: 0.6, minPermittedHeight: 200}}
            alignment="horizontal"
            className={styles.selectedQueryBody}
            fixedPanelSize={
                queryLayoutProps.isQueryRightPanelOpen ? undefined : {panelIndex: 1, size: 0}
            }
        >
            {(resizableLayoutProps) => (
                <React.Fragment>
                    <ResizablePanel
                        index={0}
                        {...resizableLayoutProps}
                        className={styles.queryResults}
                    >
                        <QueryEditor
                            isCollapsed={queryLayoutProps.isBodyCollapsed}
                            toggleIsCollapsed={queryLayoutProps.toggleIsBodyCollapsed}
                            selectedQuery={selectedQuery}
                            globalVersions={globalVersions}
                        />

                        <QueryResultsHeader
                            maxLengthOptions={maxPathLengthOptions}
                            layoutAlgorithm={resultsGraphProps.layoutAndCameraProps.layoutAlgorithm}
                            selectLayoutAlgorithm={
                                resultsGraphProps.layoutAndCameraProps.selectLayoutAlgorithm
                            }
                            canSelectViewTypes={resultsGraphProps.canSelectViewTypes}
                            isCustomLayout={resultsGraphProps.layoutAndCameraProps.isCustomLayout}
                            graphSettingsProps={resultsGraphProps.graphSettings}
                            graphPresentationProps={graphPresentationProps}
                            presentationEditedProps={resultsGraphProps.presentationEditedProps}
                            queryId={selectedQuery.id}
                            presentationActionsProps={presentationActionsProps}
                            isCalculatingLayout={
                                !resultsGraphProps.layoutAndCameraProps.visibleNodesPositions
                            }
                            clearUnsavedPresentationDetails={resultsGraphProps.clearUnsavedDetails}
                            isCantDisplayGraphProps={isCantDisplayGraphProps}
                        />

                        <QueryResults
                            graphProps={resultsGraphProps}
                            resultsListProps={resultsListProps}
                            selectionProps={selectionProps}
                            maxPathLengthOptions={maxPathLengthOptions}
                            isCantDisplayGraphProps={isCantDisplayGraphProps}
                        />
                    </ResizablePanel>

                    {queryLayoutProps.isQueryRightPanelOpen && (
                        <ResizablePanel
                            index={1}
                            {...resizableLayoutProps}
                            className={statsPanelStyles.root}
                        >
                            <RightPanel
                                closePanel={queryLayoutProps.closeQueryRightPanel}
                                queryId={selectedQuery.id}
                                viewType={resultsGraphProps.graphSettings.selectedViewType}
                                maxPathLength={resultsGraphProps.graphSettings.maxPathLength}
                                resultsListProps={resultsListProps}
                                selectedGraphNodes={selectionProps.selectedNodes}
                                selectGraphNodes={selectionProps.onNodesSelected}
                                setHoveredNodes={selectionProps.setHoveredNodes}
                            />
                        </ResizablePanel>
                    )}
                </React.Fragment>
            )}
        </CustomResizableLayout>
    );
}
