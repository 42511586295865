import {ControlsProps, Controls, ControlsItem, Spinner} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './list-preloader-item.module.css';

export function ListPreloaderItem({children, ...restProps}: ControlsProps) {
    return (
        <Controls {...restProps} className={classNames(styles.preloaderItem, restProps.className)}>
            <ControlsItem>
                <Spinner />
            </ControlsItem>
            <ControlsItem grow>{children}</ControlsItem>
        </Controls>
    );
}
