import {
    BookmarkBorderedIcon,
    Button,
    chain,
    MenuIcon,
    PlusIcon,
    ShareIcon,
    TrashIcon,
    DraftIcon,
    InProgressIcon
} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useCreateQueryDraftMutation} from './hooks/requests/use-create-query-mutation';

import {QueriesTab} from '../../components/layout/page-layout/layout-typings';
import {useSidebarTabs} from '../../hooks/use-sidebar-tabs';
import {usePageLayout} from '../../components/layout/page-layout/use-page-layout';
import {GlobalVersionData} from '../../hooks/global-version/use-global-version-query';
import {VerticalBar, VerticalBarItem} from '../../components/layout/vertical-bar/vertical-bar';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Show draft queries',
        value: QueriesTab.DRAFTS,
        prepend: <DraftIcon />
    },
    {
        tooltip: 'Show running queries',
        value: QueriesTab.RUNNING,
        prepend: <InProgressIcon />
    },
    {
        tooltip: 'Show my bookmarks',
        value: QueriesTab.FAVORITES,
        prepend: <BookmarkBorderedIcon />
    },
    {
        tooltip: 'Browse queries',
        value: QueriesTab.RECENT,
        prepend: <MenuIcon />
    },
    {
        tooltip: 'Shared with me',
        value: QueriesTab.SHARED,
        prepend: <ShareIcon />
    },
    {
        tooltip: 'Recycle bin',
        value: QueriesTab.BIN,
        prepend: <TrashIcon />,
        stickToBottom: true
    }
];

interface Props {
    layoutSettings: ReturnType<typeof usePageLayout>;
    tabSettings: ReturnType<typeof useSidebarTabs>;
    currentGlobalVersion: GlobalVersionData;
}

export function QueriesActivityBar({tabSettings, layoutSettings, currentGlobalVersion}: Props) {
    const createQueryDraft = useCreateQueryDraftMutation();
    const history = useHistory();

    const handleCreateNewDocument = async () => {
        const queryInfo = await createQueryDraft.mutateAsync({
            globalVersionId: currentGlobalVersion.id
        });
        layoutSettings.openSidebar();

        history.push(`/queries/${queryInfo.id}`);
    };

    return (
        <VerticalBar
            topControls={
                <Button
                    data-qa="create-query-button"
                    icon={<PlusIcon />}
                    onClick={handleCreateNewDocument}
                    disabled={createQueryDraft.isLoading}
                />
            }
            tabs={SIDEBAR_TABS}
            value={layoutSettings.isSidebarClosed ? undefined : tabSettings.activityBarTab}
            onValueChange={chain(layoutSettings.openSidebar, tabSettings.setActivityBarTab)}
        />
    );
}
