import React from 'react';

import {useGoToDeclarationHandler} from '../../../../../hooks/use-go-to-declaration-handler';
import {useLocationParams} from '../../../../../hooks/use-location';
import {isDocumentBeingEdited} from '../../../../../utils/document';
import {isReadOnlyOntology} from '../../../../../utils/ontology';
import {OntologyView} from '../../../common/ontology-view';
import {DocumentEditorHeader} from '../document-editor-header';

import {Editor} from './editor';
import {EditorWrapper} from './editor-wrapper';
import {FindUsagesEditorEvent} from '../../../../../../webeditor/biokb-editor-adapters';
import {OntologySidebarWrapper} from './ontology-sidebar-wrapper';

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    accession: string;
    onLoadingStateChange: (loading: boolean) => void;
    documentMetadata: DocInfo;
    findUsages: (event: FindUsagesEditorEvent) => void;
}

export function EditorLayout(props: Props) {
    const locationParams = useLocationParams();
    const goToDeclarationHandler = useGoToDeclarationHandler();

    const nodeId = locationParams.nodeId && decodeURIComponent(locationParams.nodeId);
    const isEditMode = isDocumentBeingEdited(props.documentMetadata) && !locationParams.revisionId;
    const readonlyOntology = isReadOnlyOntology(props.documentMetadata);

    const editor = (
        <Editor
            isEditMode={isEditMode}
            documentMetadata={props.documentMetadata}
            revisionId={locationParams.revisionId}
            nodeId={nodeId}
            onFindUsages={props.findUsages}
            onNavigate={goToDeclarationHandler}
            onLoadingStateChange={props.onLoadingStateChange}
        />
    );

    if (props.documentMetadata.type !== 'ONTOLOGY') {
        return editor;
    }

    if (readonlyOntology) {
        return (
            <EditorWrapper>
                <DocumentEditorHeader documentMetadata={props.documentMetadata} />

                <OntologyView
                    mode="edit"
                    documentMetadata={props.documentMetadata}
                    nodeId={nodeId}
                    onFindUsages={props.findUsages}
                    onNavigate={goToDeclarationHandler}
                />
            </EditorWrapper>
        );
    }

    return (
        <OntologySidebarWrapper docInfo={props.documentMetadata}>{editor}</OntologySidebarWrapper>
    );
}
