import React, {PropsWithChildren} from 'react';
import {OntologyIcon} from '@genestack/ui';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import styles from './editor.module.css';
import {
    VerticalBar,
    VerticalBarItem
} from '../../../../../components/layout/vertical-bar/vertical-bar';
import {EditRightPanelTab} from '../../../../../components/layout/page-layout/layout-typings';
import {
    CustomResizableLayout,
    ResizablePanel
} from '../../../../../components/layout/resizable-layout';
import {LS_PREFIX} from '../../../../../providers/global-state';
import {OntologyTreeSidebar} from '../right-sidebar/ontology-tree-sidebar';
import {useFoldingItemsState} from '../../../../../hooks/use-folding-items-state';

const SIDEBAR_TABS: VerticalBarItem[] = [
    {
        tooltip: 'Hierarchical tree',
        value: EditRightPanelTab.HIERARCHICAL_TREE,
        prepend: <OntologyIcon />
    }
];

interface Props {
    docInfo: DocInfo;
}

export function OntologySidebarWrapper(props: PropsWithChildren<Props>) {
    const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage(
        `${LS_PREFIX}.documents.edit.ontologySidebar.isOpen`,
        false
    );

    const openSidebar = React.useCallback(() => setIsSidebarOpen(true), [setIsSidebarOpen]);

    const closeSidebar = React.useCallback(() => setIsSidebarOpen(false), [setIsSidebarOpen]);

    const foldingItemsState = useFoldingItemsState();

    return (
        <div className={styles.ontologyContentContainer}>
            <CustomResizableLayout
                localStorageKey="documents.edit.ontologySidebar.layoutSize"
                settings={{initialFirstPanelShare: 0.6, minPermittedHeight: 200}}
                alignment="horizontal"
                className={styles.selectedQueryBody}
                fixedPanelSize={isSidebarOpen ? undefined : {panelIndex: 1, size: 0}}
            >
                {(resizableLayoutProps) => (
                    <React.Fragment>
                        <ResizablePanel index={0} {...resizableLayoutProps}>
                            {props.children}
                        </ResizablePanel>

                        {isSidebarOpen && (
                            <ResizablePanel index={1} {...resizableLayoutProps}>
                                <OntologyTreeSidebar
                                    onClose={closeSidebar}
                                    docInfo={props.docInfo}
                                    foldingItemsProps={foldingItemsState}
                                />
                            </ResizablePanel>
                        )}
                    </React.Fragment>
                )}
            </CustomResizableLayout>
            <VerticalBar
                tabs={SIDEBAR_TABS}
                value={isSidebarOpen ? EditRightPanelTab.HIERARCHICAL_TREE : undefined}
                onValueChange={openSidebar}
                placement="right"
            />
        </div>
    );
}
