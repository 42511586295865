import {
    HeaderItem,
    HeaderItemProps,
    MenuItem,
    HeaderItemText,
    Menu,
    RootRef,
    useMenuHandler,
    UserIcon,
    HeaderItemIcon,
    ArrowBottomThinIcon
} from '@genestack/ui';
import classNames from 'classnames';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {useCurrentUserQuery} from '../../../../hooks/user/use-current-user-query';

import styles from './user-menu.module.css';
import {LogoutItem} from '../../../logout-item/logout-item';

export function UserMenu(props: HeaderItemProps) {
    const headerItemElementRef = React.useRef<HTMLElement>(null);
    const menuHandler = useMenuHandler({
        referenceElement: headerItemElementRef.current
    });

    const currentUserQuery = useCurrentUserQuery();

    const renderHeaderItemText = () => {
        return currentUserQuery.data?.name || currentUserQuery.data?.email;
    };

    const history = useHistory();
    const handleAccountSettingsClick = () => {
        history.push('/profile');
    };

    return (
        <>
            <RootRef rootRef={headerItemElementRef}>
                <HeaderItem
                    shrink
                    active={menuHandler.isOpen}
                    {...menuHandler.getReferenceProps({
                        ...props,
                        className: classNames(props.className, styles.root)
                    })}
                >
                    <HeaderItemText ellipsis>{renderHeaderItemText()}</HeaderItemText>
                    <HeaderItemIcon>
                        <ArrowBottomThinIcon />
                    </HeaderItemIcon>
                </HeaderItem>
            </RootRef>
            <Menu {...menuHandler.getMenuProps()} className={styles.menu} placement="bottom-end">
                <MenuItem
                    as="a"
                    onClick={handleAccountSettingsClick}
                    prepend={<UserIcon />}
                    subtitle={currentUserQuery.data?.email}
                    subtitleProps={{className: styles.menuCaptionSubtitle}}
                >
                    Account settings
                </MenuItem>
                <LogoutItem>
                    <MenuItem>Sign out</MenuItem>
                </LogoutItem>
            </Menu>
        </>
    );
}
