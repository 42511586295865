import React from 'react';

import {assert} from '../../utils/assert';

import {SocketEvent} from './socket-events';
import {SocketOfflineDialog} from './socket-offline-dialog';
import {useWebSocket, CLOSE_CODE_PINGPONG_TIMEOUT} from './use-websocket';

type Unsubscribe = () => void;

type Handler = (data: SocketEvent) => void;

type ReadyState = 'connecting' | 'open' | 'closed' | 'offline';

interface JbkbSocketContextValue {
    subscribe: (handler: Handler) => Unsubscribe;
    readyState: ReadyState;
}

const RETRY_TIMEOUT = 5000;
const MAX_RETRY_ATTEMPTS = 5;
const isDebug = process.env.NODE_ENV === 'production';

const JbkbSocketContext = React.createContext<JbkbSocketContextValue | null>(null);

const baseWebSocketUrl = `${window.location.origin.replace('http', 'ws')}/queries-service/api/ws`;

/** A component providing JbkbSocket context value */
export function JbkbSocketContextProvider({children}: {children: React.ReactNode}) {
    const [readyState, setReadyState] = React.useState<ReadyState>('connecting');

    const handlers = React.useRef<Handler[]>([]);
    const [websocketUrl, setWebsocketUrl] = React.useState<string>(
        `${baseWebSocketUrl}?ts=${Date.now()}`
    );

    // number of consequent retry attempts
    const [offlineDialogOpen, setOfflineDialogOpen] = React.useState(false);
    const retryAttempts = React.useRef(0);
    const retryTimer = React.useRef<number>();
    const retry = React.useCallback(() => {
        setReadyState('connecting');

        if (retryAttempts.current < MAX_RETRY_ATTEMPTS) {
            retryAttempts.current += 1;

            window.clearTimeout(retryTimer.current);

            retryTimer.current = window.setTimeout(() => {
                setWebsocketUrl(`${baseWebSocketUrl}?ts=${Date.now()}`);
            }, RETRY_TIMEOUT);
        } else {
            setOfflineDialogOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        return () => {
            window.clearTimeout(retryTimer.current);
        };
    }, []);

    const webSocketOptions = React.useMemo(
        () => ({
            onMessage: (messageData: SocketEvent) => {
                handlers.current.forEach((handler) => {
                    handler(messageData);
                });
            },
            onOpen: () => {
                setReadyState('open');
                retryAttempts.current = 0;
            },
            onClose: (e: CloseEvent) => {
                setReadyState(retryAttempts.current >= MAX_RETRY_ATTEMPTS ? 'offline' : 'closed');

                // tslint:disable-next-line: no-magic-numbers
                if (e.code === CLOSE_CODE_PINGPONG_TIMEOUT || (e.code >= 1001 && e.code <= 1015)) {
                    retry();
                }
            },
            onError: () => {
                retry();
            }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useWebSocket(websocketUrl, webSocketOptions);

    const subscribe = React.useCallback((handler: Handler): Unsubscribe => {
        if (isDebug) {
            // eslint-disable-next-line no-console
            console.info('websocket subscribed');
        }
        handlers.current.push(handler);

        // unsubscribe function
        return () => {
            if (isDebug) {
                // eslint-disable-next-line no-console
                console.info('websocket unsubscribed');
            }
            handlers.current = handlers.current.filter((fn) => fn !== handler);
        };
    }, []);

    const handleCloseOfflineDialog = React.useCallback(() => {
        setOfflineDialogOpen(false);
    }, []);

    return (
        <JbkbSocketContext.Provider
            value={React.useMemo(() => ({subscribe, readyState}), [subscribe, readyState])}
        >
            {children}

            <SocketOfflineDialog open={offlineDialogOpen} onClose={handleCloseOfflineDialog} />
        </JbkbSocketContext.Provider>
    );
}

/** Extracts and returns subscribe method of JbkbSocket context */
export const useJbkbSocketEvent = (handler: Handler) => {
    const value = React.useContext(JbkbSocketContext);
    assert(value, 'Could not be used outside of JbkbSocketContextProvider');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => value.subscribe(handler), [value.subscribe, handler]);
};

/** Extracts and returns query socket's ready state */
export const useJbkbSocketReadyState = () => {
    const value = React.useContext(JbkbSocketContext);
    assert(value, 'Could not be used outside of JbkbSocketContextProvider');

    return value.readyState;
};

/** JbkbSocket prop */
export interface WithJbkbSocketProps {
    jbkbSocket: JbkbSocketContextValue;
}

/** A HOC which provides JbkbSocketContext value to the wrapped component */
export const withJbkbSocket = <P extends WithJbkbSocketProps>(
    Component: React.ComponentType<P>
) => {
    function WithJbkbSocket(props: Omit<P, keyof WithJbkbSocketProps>) {
        return (
            <JbkbSocketContext.Consumer>
                {(value) => (
                    <Component
                        // FIXME: remove "as P"
                        // this is a known TS bug
                        // see: https://git.io/fj7iZ
                        {...(props as P)}
                        jbkbSocket={value}
                    />
                )}
            </JbkbSocketContext.Consumer>
        );
    }

    WithJbkbSocket.displayName = `withJbkbSocket(${Component.displayName || Component.name})`;
    return WithJbkbSocket;
};
