import {Typography} from '@genestack/ui';
import {DateTime} from 'luxon';
import memoizeOne from 'memoize-one';
import React from 'react';

import styles from './query-history.module.css';

type QueriesByDay = Map<string, QueryInfo[]>;

const compareRecentQueries = (a: QueryInfo, b: QueryInfo) => {
    // sorted by finishedAt desc nulls first, id desc

    if (a.finishedAt !== null && b.finishedAt === null) return 1;
    if (a.finishedAt === null && b.finishedAt !== null) return -1;

    if (a.finishedAt !== b.finishedAt) {
        return b.finishedAt! - a.finishedAt!;
    }

    return b.id - a.id;
};

const notFinishedKey = 'NOT_FINISHED';

const getQueriesByDay = (queryInfos: QueryInfo[]) =>
    queryInfos.sort(compareRecentQueries).reduce<QueriesByDay>((map, queryInfo) => {
        const isoDate = queryInfo.finishedAt
            ? DateTime.fromMillis(queryInfo.finishedAt).toISODate()!
            : notFinishedKey;
        map.set(isoDate, [...(map.get(isoDate) || []), queryInfo]);

        return map;
    }, new Map());

const memoizedGetQueriesByDay = memoizeOne(getQueriesByDay);

const isToday = (dateTime: DateTime) => DateTime.utc().hasSame(dateTime, 'day');
const isYesterday = (dateTime: DateTime) => DateTime.utc().hasSame(dateTime.plus({days: 1}), 'day');

const DateTimeLabel = ({value}: {value: DateTime}) => (
    <Typography className={styles.datetime} intent="quiet" variant="caption">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isToday(value)
            ? 'Today'
            : isYesterday(value)
              ? 'Yesterday'
              : value.toFormat('LLLL dd, yyyy')}
    </Typography>
);

interface Props {
    children: (queryInfos: QueryInfo[]) => React.ReactNode;
    queryInfos: QueryInfo[];
}

/** A component rendering a date-ordered history of queries */
export const QueryHistory = ({children: renderQueries, queryInfos}: Props) => {
    const queriesByDay = memoizedGetQueriesByDay(queryInfos);

    return (
        <>
            {Array.from(queriesByDay.keys()).map((day) => {
                return (
                    <div key={day}>
                        {day !== notFinishedKey && (
                            <DateTimeLabel value={DateTime.fromISO(day, {zone: 'utc'})} />
                        )}
                        {renderQueries(queriesByDay.get(day) || [])}
                    </div>
                );
            })}
        </>
    );
};
