import React from 'react';
import {Notification, Typography} from '@genestack/ui';
import {FindUsagesEditorEvent} from '../../../../../../webeditor/biokb-editor-adapters';
import {showNotification} from '../../../../../providers/notifications-center';
import {
    EditModeTab,
    ViewModeTab
} from '../../../../../components/layout/page-layout/layout-typings';
import {useSearchSidebarContext} from './use-search-sidebar-context';
import {useGetNodeTypeMutation} from '../../../explore-view/hooks/requests/use-get-node-type-mutation';
import {useSidebarTabs} from '../../../../../hooks/use-sidebar-tabs';
import {GlobalVersionData} from '../../../../../hooks/global-version/use-global-version-query';

const EMPTY_NODE_NAME = '<empty>';

interface Props {
    tabSettings: ReturnType<typeof useSidebarTabs>;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    searchTab: ViewModeTab | EditModeTab;
    openSidebar: () => void;
    // search without GV is performed in Edit Mode
    currentGlobalVersion?: GlobalVersionData;
}

export function useHandleFindUsages({
    tabSettings,
    currentGlobalVersion,
    searchSidebarContext,
    searchTab,
    openSidebar
}: Props) {
    const {activityBarTab, setActivityBarTab} = tabSettings;
    const {search} = searchSidebarContext;

    const getNodeType = useGetNodeTypeMutation();

    const findUsages = React.useCallback(
        async (event: FindUsagesEditorEvent, documentId: string, revisionId?: string) => {
            if (revisionId) {
                return showNotification(
                    <Notification>
                        <Typography intent="warning">
                            Find usages is not available in the Revision History
                        </Typography>
                    </Notification>
                );
            }

            const documentAccession = event.accession ?? documentId;
            const nodeName = event.name || EMPTY_NODE_NAME;

            openSidebar();
            if (activityBarTab !== searchTab) {
                setActivityBarTab(searchTab);
            }

            const type =
                event.type ||
                /**  Editor doesn't return a type of nodes from imported ontologies,
                 * need to get it from backend */
                (await getNodeType.mutateAsync({
                    accession: documentAccession,
                    nodeId: event.nodeId,
                    globalVersionId: currentGlobalVersion?.id
                }));

            search({
                mode: 'node',
                documentAccession,
                nodeId: event.nodeId,
                name: nodeName,
                type
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [search, currentGlobalVersion, activityBarTab, setActivityBarTab, openSidebar]
    );

    return {findUsages, loadingNodeType: getNodeType.isLoading};
}
