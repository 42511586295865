import {Tabs, TabsProps, Tab, CloneProps, TooltipHandler, Tooltip} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './vertical-bar.module.css';
import {ActivityBarTabs} from '../page-layout/layout-typings';

type TabProps = React.ComponentProps<typeof Tab>;

export interface VerticalBarItem {
    tooltip: string;
    value: ActivityBarTabs;
    prepend: React.ReactNode;
    stickToBottom?: boolean;
}

interface Props {
    topControls?: React.ReactNode;
    tabs: VerticalBarItem[];
    value?: ActivityBarTabs;
    onValueChange: TabsProps['onValueChange'];
    placement?: 'left' | 'right';
}

export function VerticalBar({tabs, value, onValueChange, topControls, placement = 'left'}: Props) {
    return (
        <div
            className={classNames(
                styles.root,
                placement === 'right' ? styles.leftBorder : styles.rightBorder
            )}
        >
            {topControls && <div className={styles.topControls}>{topControls}</div>}

            <Tabs
                indicatorPlacement={placement === 'left' ? 'right' : 'left'}
                value={value}
                orientation="vertical"
                onValueChange={onValueChange}
                className={classNames(styles.tabs)}
            >
                {tabs.map(({tooltip, value: tabValue, stickToBottom, ...restTabProps}) => (
                    <CloneProps key={tabValue} value={tabValue} {...restTabProps}>
                        {(tabProps: TabProps) => (
                            <TooltipHandler
                                disableFocusListener
                                tooltip={
                                    <Tooltip placement="right" className={styles.tooltip}>
                                        {tooltip}
                                    </Tooltip>
                                }
                            >
                                <Tab
                                    data-qa={`activitybar-${tabValue}`}
                                    {...tabProps}
                                    className={classNames(styles.tab, {
                                        [styles.stickToBottomTab]: stickToBottom
                                    })}
                                />
                            </TooltipHandler>
                        )}
                    </CloneProps>
                ))}
            </Tabs>
        </div>
    );
}
