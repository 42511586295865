import {StructuredGraphNode} from '../../interface';
import {StructuredGraph} from './structured-graph';
import {isEndNode, isStartNode} from '../utils';
import {connectNodes} from './connect-nodes';

export function getPathsThroughNodes(
    structuredGraph: StructuredGraph,
    nodeIds: number[],
    nodesToIgnore: number[] = []
) {
    const pathBetweenNodes =
        nodeIds.length === 1 ? nodeIds : connectNodes(structuredGraph, nodeIds, nodesToIgnore);

    if (!pathBetweenNodes.length) {
        return [];
    }

    const lastNode = structuredGraph.getNode(pathBetweenNodes[pathBetweenNodes.length - 1]);
    const forwardPathsNodes = new Set<number>([lastNode.id]);
    let currentStepNodes: StructuredGraphNode[] = [lastNode];

    do {
        const newStepsNodes: number[] = [];
        currentStepNodes.forEach((node) => {
            node.outgoingEdges.forEach(({targetNode}) => {
                if (
                    !forwardPathsNodes.has(targetNode.id) &&
                    !nodesToIgnore.includes(targetNode.id)
                ) {
                    newStepsNodes.push(targetNode.id);
                    forwardPathsNodes.add(targetNode.id);
                }
            });
        });

        currentStepNodes = newStepsNodes.map((id) => structuredGraph.getNode(id));
    } while (!currentStepNodes.every(isEndNode));

    const firstNode = structuredGraph.getNode(pathBetweenNodes[0]);
    const backwardPathsNodes = new Set<number>([firstNode.id]);
    currentStepNodes = [firstNode];

    do {
        const newStepsNodes: number[] = [];

        currentStepNodes.forEach((node) => {
            node.incomingEdges.forEach(({sourceNode}) => {
                if (
                    !backwardPathsNodes.has(sourceNode.id) &&
                    !nodesToIgnore.includes(sourceNode.id)
                ) {
                    newStepsNodes.push(sourceNode.id);
                    backwardPathsNodes.add(sourceNode.id);
                }
            });
        });

        currentStepNodes = newStepsNodes.map((id) => structuredGraph.getNode(id));
    } while (!currentStepNodes.every(isStartNode));

    return Array.from(
        new Set([
            ...Array.from(backwardPathsNodes).reverse(),
            ...pathBetweenNodes,
            ...Array.from(forwardPathsNodes)
        ])
    );
}
