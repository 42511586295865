import {
    Button,
    Controls,
    ControlsItem,
    Dialog,
    DialogBody,
    DialogHeader,
    DialogFooter,
    DialogProps,
    Divider,
    Notification,
    Typography
} from '@genestack/ui';
import * as React from 'react';
import {useQueryClient} from 'react-query';
import {useHistory, useLocation} from 'react-router-dom';

import {IdentityFormMessage} from '../../unauthenticated-app/identity-form-message';
import {getHiddenNodes} from '../../unauthenticated-app/utils';
import {
    ChangeUserDataResponse,
    ErrorResponse,
    FormNode,
    FormQueryResponse,
    isChangeUserDataResponse,
    isFormQueryResponse
} from '../../../hooks/user/auth-form-query';
import {useIdentityFormMutation} from '../../../hooks/user/use-identity-form-mutation';
import {buildFormData} from '../../../utils/build-form-data';
import {showNotification} from '../../../providers/notifications-center';
import {ValidatedInput} from '../../../components/validated-input';

interface Props extends DialogProps {
    onClose: () => void;
    data: FormQueryResponse;
}

const SUCCESS_STATE = 'success';

function getPasswordNode(nodes: FormNode[]) {
    return nodes.find((node) => node.attributes.name === 'password');
}

/** Dialog for changing a password */
export const ChangePasswordDialog = ({open, onClose, data, ...rest}: Props) => {
    const queryClient = useQueryClient();
    const passwordMutation = useIdentityFormMutation<
        ChangeUserDataResponse | FormQueryResponse | ErrorResponse
    >();

    const [passwordInputTouched, setPasswordInputTouched] = React.useState(false);
    const [confirmInputTouched, setConfirmInputTouched] = React.useState(false);

    const [passwordValue, setPasswordValue] = React.useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = React.useState('');

    const history = useHistory();
    const searchParams = new URLSearchParams(useLocation().search);
    const isPassRecoveryFlow = searchParams.has('flow');

    const handleNewPasswordValueChange = (nextValue: string) => {
        if (!passwordInputTouched) {
            setPasswordInputTouched(true);
        }

        if (passwordMutation.data) {
            passwordMutation.reset();
        }
        setPasswordValue(nextValue);
    };

    const closeDialog = () => {
        setPasswordInputTouched(false);
        setConfirmInputTouched(false);
        setPasswordValue('');
        setConfirmPasswordValue('');
        passwordMutation.reset();
        onClose();
    };

    const formRef = React.createRef<HTMLFormElement>();
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (formRef.current) {
            const jsonFormData = buildFormData(formRef.current);
            jsonFormData.method = 'password';

            try {
                const response = await passwordMutation.mutateAsync({
                    jsonFormData,
                    action: data.ui.action
                });

                const result = response.data;

                if (isFormQueryResponse(result)) {
                    queryClient.setQueryData(['/self-service/settings/browser'], result);
                }

                if (isChangeUserDataResponse(result) && result.state === SUCCESS_STATE) {
                    closeDialog();
                    queryClient.setQueryData(['/self-service/settings/browser'], result);
                    showNotification(<Notification>Your password has been changed</Notification>);

                    if (isPassRecoveryFlow) {
                        history.replace('/profile');
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    showNotification(<Notification>{error.message}</Notification>);
                }
            }
        }
    };

    const handleConfirmInputBlur = () => {
        setConfirmInputTouched(true);
    };

    const hiddenNodes = getHiddenNodes(data.ui.nodes);
    const passwordNode = getPasswordNode(data.ui.nodes);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {value, defaultValue, ...restAttributes} = passwordNode?.attributes || {};
    const mutationResult = passwordMutation.data?.data;

    return (
        <Dialog
            {...rest}
            open={open}
            size="small"
            onClose={closeDialog}
            hideCloseButton={isPassRecoveryFlow}
            overlayProps={{
                disableEscListener: true,
                disableClickListener: true
            }}
            style={{overflow: 'hidden'}}
        >
            <form ref={formRef} onSubmit={handleSubmit}>
                <DialogHeader>
                    <Typography variant="title">Change password</Typography>
                </DialogHeader>
                <DialogBody>
                    {hiddenNodes.map((node) => {
                        return <input key={node.attributes.name} {...node.attributes} />;
                    })}
                    <Typography box="paragraph" as="label" htmlFor="password">
                        Enter new password
                    </Typography>

                    <ValidatedInput
                        autoFocus
                        fullWidth
                        id="password"
                        value={passwordValue}
                        onValueChange={handleNewPasswordValueChange}
                        validationText={
                            (isFormQueryResponse(mutationResult) &&
                                getPasswordNode(mutationResult.ui.nodes)?.messages?.[0]?.text) ||
                            ''
                        }
                        {...restAttributes}
                    />

                    <Divider variant="transparent" gap={2} />
                    <Typography box="paragraph" as="label" htmlFor="confirm">
                        Confirm password
                    </Typography>

                    <ValidatedInput
                        fullWidth
                        id="confirm"
                        type="password"
                        value={confirmPasswordValue}
                        onValueChange={setConfirmPasswordValue}
                        onBlur={handleConfirmInputBlur}
                        validationText={
                            passwordValue !== confirmPasswordValue && confirmInputTouched
                                ? 'Passwords must match'
                                : undefined
                        }
                    />

                    <IdentityFormMessage response={passwordMutation.data?.data} />
                </DialogBody>
                <DialogFooter>
                    <Controls>
                        <ControlsItem>
                            <Button
                                type="submit"
                                intent="accent"
                                disabled={
                                    !passwordValue ||
                                    passwordValue !== confirmPasswordValue ||
                                    passwordMutation.isLoading
                                }
                            >
                                Update password
                            </Button>
                        </ControlsItem>
                        {!isPassRecoveryFlow && (
                            <ControlsItem>
                                <Button onClick={closeDialog}>Cancel</Button>
                            </ControlsItem>
                        )}
                    </Controls>
                </DialogFooter>
            </form>
        </Dialog>
    );
};
