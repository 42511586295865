import React from 'react';

import {NextPageExecutor, PaginationMode} from './next-page-executor';
import {PageDivider as Divider} from './page-divider';

interface Props<T> {
    /** Pagination mode - default = "auto" */
    mode?: PaginationMode;
    items: T[];
    // pageSize as array can be used for the case when
    // the pages have different number of items in one list
    pageSize: number | number[];
    hasNextPage?: boolean;
    isLoadingNextPage?: boolean;
    renderItem: (item: T, ndx: number) => [React.Key, React.ReactElement];
    onLoadNext?: () => void;
}

function canUseIntersectionObserver() {
    return typeof IntersectionObserver === 'function';
}

function getDividerPositions(sizes: number[]) {
    let sum = 0;
    const results: number[] = [];
    sizes.forEach((size) => {
        sum += size;
        results.push(sum);
    });

    return results;
}

function getDividerPageNumber(position: number, pageSize: number | number[]) {
    if (typeof pageSize === 'number') {
        return position % pageSize === 0 ? position / pageSize + 1 : undefined;
    }

    const dividerPositions = getDividerPositions(pageSize);
    const dividerIndex = dividerPositions.indexOf(position);

    // The first divider should have a title of Page 2
    return dividerIndex !== -1 ? dividerIndex + 2 : undefined;
}

/** A generic pagination list. Encapsulates placement of dividers and "load next page" button */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const PaginatedList = <T extends unknown = unknown>({
    mode = 'auto',
    hasNextPage,
    isLoadingNextPage,
    items,
    pageSize,
    renderItem,
    onLoadNext
}: Props<T>) => {
    const paginationMode = mode === 'auto' && canUseIntersectionObserver() ? 'auto' : 'manual';

    React.useEffect(() => {
        if (paginationMode === 'manual') {
            const nodeList = document.querySelectorAll('[data-list-page-divider]');

            if (nodeList.length > 0) {
                nodeList.item(nodeList.length - 1).scrollIntoView({behavior: 'smooth'});
            }
        }
    }, [items, paginationMode]);

    return (
        <>
            {items.map((item, i, arr) => {
                const [key, itemElement] = renderItem(item, i);
                const position = i + 1;

                const isLastItem = position === arr.length;
                const hasPageAccessor = hasNextPage && isLastItem;

                const dividerPageNumber = getDividerPageNumber(position, pageSize);

                return (
                    <React.Fragment key={key}>
                        {itemElement}

                        {dividerPageNumber !== undefined &&
                            (position < arr.length || (isLastItem && isLoadingNextPage)) && (
                                <>
                                    <div data-list-page-divider />
                                    <Divider page={dividerPageNumber} />
                                </>
                            )}

                        {hasPageAccessor && onLoadNext && (
                            <NextPageExecutor
                                mode={paginationMode}
                                isLoadingNextPage={isLoadingNextPage}
                                onLoadNext={onLoadNext}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};
