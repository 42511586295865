import {Typography, Controls, ControlsItem} from '@genestack/ui';
import React from 'react';

import {UserBadge} from '../../../../components/user-badge/user-badge';

interface Props {
    username: string;
}

/** A universal component for rendering stylized user badge and name */
export const UserUnit = ({username}: Props) => {
    return (
        <Controls gap={1}>
            <ControlsItem>
                <UserBadge fullName={username} />
            </ControlsItem>
            <ControlsItem>
                <Typography>{username}</Typography>
            </ControlsItem>
        </Controls>
    );
};
