import * as React from 'react';
import cytoscape, {Core, NodeDefinition, NodeSingular, Position} from 'cytoscape';
import {GraphLayout, GraphNode, GraphEdge} from '../../interface';
import {getConstantStyles} from './get-constant-styles';
import {isSelectMultipleMouseEvent} from '../../../../utils/get-client-os';
import {createEdgeElement, createNodeElement} from './create-elements';
import {useHandleBoxSelection} from './use-handle-box-selection';

interface Props {
    nodes: GraphNode[];
    edges: GraphEdge[];
    csRef: React.MutableRefObject<Core | null>;
    nodePositions: GraphLayout;
    handleNodeMoved: (value: GraphLayout) => void;
    zoom: number;
    setZoom: (val: number) => void;
    pan: Position;
    setPan: (val: Position) => void;
    setHoveredNodes: (nodeIds: number[]) => void;
    selectNodes: (nodeId: number[], isSelectMultiple: boolean) => void;
    isPresentationEdited?: boolean;
    getIsNodePartiallyHidden: (nodeId: number) => boolean;
    getIsNodeFullyHidden: (nodeId: number) => boolean;
    getIsEdgePartiallyHidden: (edgeId: number) => boolean;
    getIsEdgeFullyHidden: (edgeId: number) => boolean;
    isHiddenNodesVisible: boolean;
}

const defaultMinZoom = 0.1;
export const graphElementId = 'cytoscape-graph';

export function useCytoscapeInitialization(props: Props) {
    const {
        csRef,
        nodes,
        edges,
        nodePositions,
        handleNodeMoved,
        zoom,
        setZoom,
        pan,
        setPan,
        setHoveredNodes,
        selectNodes,
        isPresentationEdited,
        getIsNodePartiallyHidden,
        getIsNodeFullyHidden,
        getIsEdgePartiallyHidden,
        getIsEdgeFullyHidden
    } = props;

    const handleBoxSelection = useHandleBoxSelection(selectNodes);

    const zoomRef = React.useRef<number>(0);
    const panRef = React.useRef({x: 0, y: 0});
    const selectNodesRef = React.useRef(selectNodes);
    const getIsNodePartiallyHiddenRef = React.useRef(getIsNodePartiallyHidden);
    const getIsNodeFullyHiddenRef = React.useRef(getIsNodeFullyHidden);
    const getIsEdgePartiallyHiddenRef = React.useRef(getIsEdgePartiallyHidden);
    const getIsEdgeFullyHiddenRef = React.useRef(getIsEdgeFullyHidden);
    const handleBoxSelectionRef = React.useRef(handleBoxSelection);

    zoomRef.current = zoom;
    panRef.current = pan;
    selectNodesRef.current = selectNodes;
    getIsNodePartiallyHiddenRef.current = getIsNodePartiallyHidden;
    getIsNodeFullyHiddenRef.current = getIsNodeFullyHidden;
    getIsEdgePartiallyHiddenRef.current = getIsEdgePartiallyHidden;
    getIsEdgeFullyHiddenRef.current = getIsEdgeFullyHidden;
    handleBoxSelectionRef.current = handleBoxSelection;

    React.useEffect(() => {
        csRef.current = cytoscape({
            container: document.getElementById(graphElementId),
            elements: {
                nodes: nodes
                    .filter((node) => !getIsNodeFullyHiddenRef.current(node.id))
                    .map((node) =>
                        createNodeElement(node, nodePositions, getIsNodePartiallyHiddenRef.current)
                    ),
                edges: edges
                    .filter((edge) => !getIsEdgeFullyHiddenRef.current(edge.id))
                    .map((edge) => createEdgeElement(edge, getIsEdgePartiallyHiddenRef.current))
            },
            style: [...getConstantStyles()],
            layout: {
                name: 'preset'
            }
        });

        csRef.current?.on('grab', () => {
            setHoveredNodes([]);
        });

        csRef.current?.on('dragfree', () => {
            const result: GraphLayout = {};

            // @ts-ignore
            const elements = csRef.current.json().elements.nodes;
            elements.forEach((node: NodeDefinition) => {
                result[node.data.id!] = node.position!;
            });

            handleNodeMoved(result);
        });

        csRef.current?.on('zoom', () => setZoom(csRef.current!.zoom()));
        csRef.current?.on('pan', () => setPan(csRef.current!.pan()));

        csRef.current?.fit();

        csRef.current?.maxZoom(1);
        csRef.current?.minZoom(Math.min(csRef.current?.zoom(), defaultMinZoom));

        if (zoomRef.current) {
            csRef.current?.zoom(zoomRef.current);
        }

        if (panRef.current.x || panRef.current.y) {
            csRef.current?.pan(panRef.current);
        } else {
            // when user changes graph settings (like max path length) their zoom setting
            // stays as before, but their pan setting changes so that they have at least
            // one node in their view port, so they don’t feel lost
            const firstNodePos = (
                csRef.current?.elements('node').first() as NodeSingular
            ).renderedPosition();
            if (firstNodePos) {
                const newPan = {x: csRef.current!.width() - 300, y: csRef.current!.height() / 2};
                csRef.current?.panBy({x: newPan.x - firstNodePos.x, y: newPan.y - firstNodePos.y});
            }
        }

        csRef.current?.on('mouseover', 'node', (e) => {
            const node = e.target;

            const {id} = node.data();

            setHoveredNodes([id]);
        });

        csRef.current?.on('mouseout', 'node', () => {
            setHoveredNodes([]);
        });

        csRef.current?.on('click', 'node', (e) => {
            const isSelectMultiple = isSelectMultipleMouseEvent(e.originalEvent);

            // eslint-disable-next-line no-underscore-dangle
            selectNodesRef.current([Number(e.target._private.data.id)], isSelectMultiple);
        });

        csRef.current?.on('boxselect', 'node', (e) => {
            // eslint-disable-next-line no-underscore-dangle
            handleBoxSelectionRef.current(Number(e.target._private.data.id));
        });

        csRef.current?.on('click', 'edge', (e) => {
            const isSelectMultiple = isSelectMultipleMouseEvent(e.originalEvent);
            // eslint-disable-next-line no-underscore-dangle
            const {source, target} = e.target._private.data;

            selectNodesRef.current([Number(source), Number(target)], isSelectMultiple);
        });
        // isPresentationEdited in hook dependencies to redraw graph when...
        // ...user discards presentation changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, edges, handleNodeMoved, setZoom, isPresentationEdited]);
}
