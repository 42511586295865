import {GraphResponse, QueryNodeType, StructuredGraphEdge, StructuredGraphNode} from '../interface';

/** The class that constructs the graph,
 *  and provides helper functions */
export class StructuredGraph {
    public readonly startNodes: StructuredGraphNode[];
    public readonly allNodes: StructuredGraphNode[];
    public readonly allEdges: StructuredGraphEdge[];
    private readonly nodesMap: {[id: number]: StructuredGraphNode};
    private readonly nodesByRawIdMap: {[id: number]: StructuredGraphNode};
    private readonly edgesMap: {[id: number]: StructuredGraphEdge};

    constructor(graphData: GraphResponse) {
        const {startNodes, allNodes, allEdges} = StructuredGraph.constructGraph(graphData);
        this.startNodes = startNodes;
        this.allEdges = allEdges;
        this.allNodes = allNodes;
        const nodesMap: {[id: number]: StructuredGraphNode} = {};
        allNodes.forEach((node) => {
            nodesMap[node.id] = node;
        });
        this.nodesMap = nodesMap;
        const nodesByRawIdMap: {[id: number]: StructuredGraphNode} = {};
        allNodes.forEach((node) => {
            node.rawNodeIds.forEach((rawId) => {
                nodesByRawIdMap[rawId] = node;
            });
        });
        this.nodesByRawIdMap = nodesByRawIdMap;
        const edgesMap: {[id: number]: StructuredGraphEdge} = {};
        allEdges.forEach((edge) => {
            edgesMap[edge.id] = edge;
        });
        this.edgesMap = edgesMap;
    }

    public getNode(nodeId: number) {
        return this.nodesMap[nodeId];
    }

    public getNodeByRawId(rawNodeId: number) {
        return this.nodesByRawIdMap[rawNodeId];
    }

    public getEdge(edgeId: number) {
        return this.edgesMap[edgeId];
    }

    private static constructGraph = (graphData: GraphResponse) => {
        const nodes: StructuredGraphNode[] = graphData.nodes.map((node) => ({
            id: node.id,
            // we use biokb graph only in queries
            nodeType: node.nodeType as QueryNodeType,
            incomingEdges: [],
            outgoingEdges: [],
            rawNodeIds: node.rawNodeIds
        }));
        const edges: StructuredGraphEdge[] = graphData.edges.map((edge) => ({
            id: edge.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sourceNode: nodes.find((node) => node.id === edge.startViewNodeId)!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            targetNode: nodes.find((node) => node.id === edge.endViewNodeId)!,
            presentation: edge.presentation
        }));

        nodes.forEach((node) => {
            edges.forEach((edge) => {
                if (edge.targetNode.id === node.id) {
                    node.incomingEdges.push(edge);
                }

                if (edge.sourceNode.id === node.id) {
                    node.outgoingEdges.push(edge);
                }
            });
        });

        return {
            startNodes: nodes.filter((node) => node.nodeType === QueryNodeType.START),
            allNodes: nodes,
            allEdges: edges
        };
    };
}
