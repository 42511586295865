import {Controls, ControlsItem} from '@genestack/ui';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {EditableDocumentStatus} from '../../../common/doc-status-icon';
import {DocumentTypeLabel} from '../../../../../components/document-type-label';
import {EditableTitleApi} from '../../../../../components/editable-title';
import {useLocationParams} from '../../../../../hooks/use-location';
import {useDocRevisionsContext, CommitDto} from '../../../../../providers/version';
import {isDeletedDocument, isDocumentBeingEdited} from '../../../../../utils/document';
import {VersionLabel} from '../../../common/version-history/version-label';
import {TitleSlotView} from '../../../explore-view/title-slot-view';
import {useRenameWorkingCopyMutation} from '../../hooks/use-rename-working-copy-mutation';

import {DocumentControl} from './document-control';
import styles from './document-editor-header.module.css';
import {EditViewEditorMenu} from './edit-view-menu';
import {PublishDialog} from './publish-dialog';
import {useStartEditingDocument} from './use-start-editing-document';
import {EditorHeader} from '../../../../../components/editor/editor-header/editor-header';

interface Props {
    documentMetadata: DocInfo;
    workingCopyName?: string;
    lastAction?: CommitDto;
    onPublishSubmit?: (commitMessage?: string) => void;
}

export function useDocumentTitle(documentMetadata: DocInfo, workingCopyName?: string) {
    const {selectedRevision} = useDocRevisionsContext();

    if (selectedRevision) {
        return selectedRevision.documentName;
    }

    if (isDocumentBeingEdited(documentMetadata) && workingCopyName) {
        return workingCopyName;
    }

    return documentMetadata.name;
}

export function DocumentEditorHeader(props: Props) {
    const history = useHistory();

    const titleRef = React.useRef<EditableTitleApi>(null);
    const [openDialog, setOpenDialog] = React.useState(false);

    const {selectedRevision} = useDocRevisionsContext();
    const locationParams = useLocationParams();
    const renameWorkingCopy = useRenameWorkingCopyMutation();

    const handleClickRename = () => {
        titleRef.current?.rename();
    };

    const handleRename = (name: string) => {
        renameWorkingCopy.mutate({
            accession: props.documentMetadata.accession,
            type: props.documentMetadata.type,
            name
        });
    };

    const isVersion = locationParams.mode === 'revisions';

    const handleOpenPublishDialog = () => {
        setOpenDialog(true);
    };

    const handleClosePublishDialog = () => {
        setOpenDialog(false);
    };

    const documentTitle = useDocumentTitle(props.documentMetadata, props.workingCopyName);

    const {startEditing, isDocEditable, editingDisabledReason} = useStartEditingDocument({
        documentMetadata: props.documentMetadata,
        revisionId: selectedRevision?.revision
    });

    const onCopyToNewDraft = async () => {
        await startEditing();

        history.push(`/documents/${locationParams.documentsViewMode}/${locationParams.documentId}`);
    };

    const creatingWorkingCopyDisabledReason = (function () {
        if (!isDocEditable) {
            return editingDisabledReason;
        }

        if (isDeletedDocument(props.documentMetadata)) {
            return 'Document is in the Recycle bin. Restore it to update its content.';
        }

        if (isDocumentBeingEdited(props.documentMetadata)) {
            return 'Cannot copy if not published draft is present. Publish or discard draft first.';
        }

        return undefined;
    })();

    return (
        <>
            <EditorHeader
                ref={titleRef}
                data-qa="doc-header"
                className={
                    (!isVersion && isDeletedDocument(props.documentMetadata)) ||
                    (selectedRevision && selectedRevision.action === 'TRASH_BIN')
                        ? styles.warning
                        : undefined
                }
                disableEditingTitle={!isDocumentBeingEdited(props.documentMetadata)}
                titleProps={{
                    append: <EditableDocumentStatus document={props.documentMetadata} />,
                    'data-qa': 'doc-title'
                }}
                name={documentTitle}
                onRename={handleRename}
                menu={
                    isVersion ? null : (
                        <EditViewEditorMenu
                            data-qa="doc-header-menu-handler"
                            documentMetadata={props.documentMetadata}
                            lastAction={props.lastAction}
                            onClickPublish={handleOpenPublishDialog}
                            onClickRename={handleClickRename}
                        />
                    )
                }
                prepend={
                    <DocumentTypeLabel
                        type={props.documentMetadata.type}
                        readOnly={props.documentMetadata.readOnly}
                    />
                }
                append={
                    <Controls gap={1}>
                        <ControlsItem>
                            {selectedRevision ? (
                                <VersionLabel
                                    selectedVersion={selectedRevision}
                                    copyToDraftBtnProps={{
                                        onClick: onCopyToNewDraft,
                                        disabledReason: creatingWorkingCopyDisabledReason
                                    }}
                                />
                            ) : null}
                        </ControlsItem>

                        <ControlsItem>
                            <TitleSlotView.Slot />
                        </ControlsItem>
                    </Controls>
                }
            >
                {!isVersion && (
                    <DocumentControl
                        className={styles.documentControl}
                        documentMetadata={props.documentMetadata}
                        onClickPublish={handleOpenPublishDialog}
                    />
                )}
            </EditorHeader>

            {props.onPublishSubmit ? (
                <PublishDialog
                    open={openDialog}
                    documentMetadata={props.documentMetadata}
                    onSubmit={props.onPublishSubmit}
                    onClose={handleClosePublishDialog}
                />
            ) : null}
        </>
    );
}
