import React from 'react';
import {HiddenNodesActionsProps} from './interface';
import {getPathsThroughNodes} from '../get-paths-through-nodes';
import {connectToUnhidden} from '../connect-to-unhidden';
import {showWarningNotification} from '../../../../../providers/notifications-center/notifications-store';

interface Props extends HiddenNodesActionsProps {
    setHiddenNodesAndMovePositions: (newHiddenNodes: number[]) => void;
    hiddenSelectedNodes: number[];
}

export function useUnhideNodes(props: Props) {
    const {
        hiddenNodes,
        selectedNodes,
        structuredGraph,
        setHiddenNodesAndMovePositions,
        hiddenSelectedNodes
    } = props;

    const unhideAllNodes = React.useCallback(() => {
        setHiddenNodesAndMovePositions([]);
    }, [setHiddenNodesAndMovePositions]);

    const unhideSelectedNodes = React.useCallback(() => {
        const selectedNodesSet = new Set([...selectedNodes]);

        setHiddenNodesAndMovePositions(
            hiddenNodes.filter((nodeId) => !selectedNodesSet.has(nodeId))
        );
    }, [hiddenNodes, setHiddenNodesAndMovePositions, selectedNodes]);

    const unhideAllPathsThroughSelected = React.useCallback(() => {
        const pathsThroughSelectedSet = new Set<number>([]);

        hiddenSelectedNodes.forEach((nodeId) => {
            const pathsThroughNode = getPathsThroughNodes(structuredGraph!, [nodeId]);
            pathsThroughNode.forEach(pathsThroughSelectedSet.add, pathsThroughSelectedSet);
        });

        setHiddenNodesAndMovePositions(
            hiddenNodes.filter((nodeId) => !pathsThroughSelectedSet.has(nodeId))
        );
    }, [hiddenNodes, setHiddenNodesAndMovePositions, hiddenSelectedNodes, structuredGraph]);

    const connectSelectedToUnhidden = React.useCallback(() => {
        const allNodesToUnhide = new Set<number>();
        let unableToConnectSome = false;

        hiddenSelectedNodes.forEach((nodeId) => {
            const nodesToUnhide = connectToUnhidden(structuredGraph!, nodeId, hiddenNodes);
            nodesToUnhide.forEach(allNodesToUnhide.add, allNodesToUnhide);

            if (!nodesToUnhide.length) {
                unableToConnectSome = true;
            }
        });

        if (!allNodesToUnhide.size) {
            showWarningNotification('Selected nodes cannot be connected to the current subgraph.');
            return;
        }

        if (unableToConnectSome) {
            showWarningNotification('Some nodes cannot be connected to the current subgraph.');
            return;
        }

        setHiddenNodesAndMovePositions(
            hiddenNodes.filter((nodeId) => !allNodesToUnhide.has(nodeId))
        );
    }, [hiddenNodes, setHiddenNodesAndMovePositions, hiddenSelectedNodes, structuredGraph]);

    return {
        unhideAllNodes,
        unhideSelectedNodes,
        unhideAllPathsThroughSelected,
        connectSelectedToUnhidden
    };
}
