import {
    Controls,
    ControlsItem,
    Typography,
    Switch,
    Divider,
    CrossIcon,
    Button,
    ListItem,
    Notification
} from '@genestack/ui';
import React, {ChangeEvent} from 'react';

import {UiPermission, UserIdentity, UserRole} from '../../../../typings/user';
import {DateText} from '../../document-workflow/common/date-text/date-text';
import {useUpdateUserMutation} from '../../../hooks/manage-users/use-update-user-mutation';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {useSessionQuery} from '../../../hooks/user/use-session-query';
import {showNotification} from '../../../providers/notifications-center';
import {Preloader} from '../../../components/preloader/preloader';

import {PermissionSwitcher} from './permission-switcher';
import styles from './user-settings.module.css';

interface Props {
    user: UserIdentity;
    onClose: () => void;
}

const BLOCK_GAP = 3;

/** Component for settings of user */
export function UserSettings({user, onClose}: Props) {
    const currentUserQuery = useCurrentUserQuery();
    const updateUser = useUpdateUserMutation();
    const isCurrentUser = user.id === currentUserQuery.data?.id;

    const showRequestErrorNotification = () => {
        showNotification(
            // tslint:disable-next-line no-magic-numbers
            <Notification countdownDuration={6000}>
                <Typography>Error requesting last login time, try again later.</Typography>
            </Notification>
        );
    };

    const sessionQuery = useSessionQuery(user.id, {onError: showRequestErrorNotification});

    const handleSwitchChange = () => {
        updateUser.mutate({
            id: user.id,
            active: !user.active
        });
    };

    const handlePermissionsChange = (permission: UiPermission, checked: boolean) => {
        const permissions = checked
            ? [...user.permissions, permission]
            : user.permissions.filter((p) => p !== permission);

        updateUser.mutate({id: user.id, permissions});
    };

    const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateUser.mutate({
            id: user.id,
            role: e.target.value as UserRole
        });
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Button
                    ghost
                    className={styles.closeBtn}
                    data-qa="close-user-settings"
                    onClick={onClose}
                    title="Close"
                    icon={<CrossIcon />}
                />

                <Typography variant="title" intent="quiet">
                    {user.name}
                </Typography>
                <Typography intent="quiet">{user.email}</Typography>

                <Divider variant="transparent" />

                {sessionQuery.isLoading || sessionQuery.isError ? (
                    <Preloader
                        show
                        wrapAll="div"
                        wrapEach="div"
                        count={1}
                        className={styles.lastLoginPreloader}
                    />
                ) : (
                    <Typography>
                        Last login:{' '}
                        {sessionQuery.data ? (
                            <DateText
                                timestamp={sessionQuery.data.authenticatedAt}
                                writeIfTodayOrYesterday
                            />
                        ) : (
                            'never logged in'
                        )}
                    </Typography>
                )}

                <ControlsItem>
                    <Controls gap={1}>
                        <ControlsItem>
                            <Typography as="span">Status:</Typography>
                        </ControlsItem>
                        <ControlsItem>
                            <Switch
                                onCheckedChange={handleSwitchChange}
                                checked={user.active}
                                disabled={isCurrentUser}
                            />
                        </ControlsItem>
                        <ControlsItem>
                            <Typography as="span">
                                {user.active ? 'Active' : 'Deactivated'}
                            </Typography>
                        </ControlsItem>
                    </Controls>
                </ControlsItem>
            </div>

            <Divider variant="transparent" gap={BLOCK_GAP} />

            <Typography variant="header" intent="quiet">
                Assigned permissions
            </Typography>

            <Divider variant="transparent" gap={2} />

            {Object.values(UiPermission).map((permission) => (
                <React.Fragment key={permission}>
                    <PermissionSwitcher
                        value={permission}
                        onCheckedChange={handlePermissionsChange}
                        checked={user.permissions.includes(permission)}
                        disabled={permission === UiPermission.manageUsers && isCurrentUser}
                    />

                    <Divider variant="transparent" />
                </React.Fragment>
            ))}

            <Typography variant="section">Create and edit documents</Typography>

            <div className={styles.radioButtonsList}>
                <ListItem
                    as="label"
                    interactive
                    prepend={
                        <input
                            type="radio"
                            name="user-editor-role-input"
                            value={UserRole.EXPLORER}
                            checked={user.role === UserRole.EXPLORER}
                            onChange={handleRoleChange}
                        />
                    }
                >
                    None (explorer-only access)
                </ListItem>

                <ListItem
                    as="label"
                    interactive
                    prepend={
                        <input
                            type="radio"
                            name="user-editor-role-input"
                            value={UserRole.BTB_EDITOR}
                            checked={user.role === UserRole.BTB_EDITOR}
                            onChange={handleRoleChange}
                        />
                    }
                >
                    BTB-documents only
                </ListItem>

                <ListItem
                    as="label"
                    interactive
                    prepend={
                        <input
                            type="radio"
                            name="user-editor-role-input"
                            value={UserRole.EDITOR}
                            checked={user.role === UserRole.EDITOR}
                            onChange={handleRoleChange}
                        />
                    }
                >
                    BTB-documents, assets and libraries
                </ListItem>
            </div>
        </div>
    );
}
