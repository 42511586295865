import {GraphLayout, GraphNode, GraphType} from '../../../../../components/graph/interface';
import {MaxPathLengthOptionResult} from './requests/use-query-result-max-length-options';

const problematicBiokbGraphSize = 200;
const unmanageableBiokbGraphSize = 500;
const problematicCytoscapeGraphSize = 500;
const unmanageableSizeCytoscapeGraphSize = 4000;

// Determines if number of nodes is big enough to cause some problems in application performance
export function isGraphProblematic(nodesNumber: number, graphType: GraphType) {
    const problematicGraphSize =
        graphType === GraphType.cytoscape
            ? problematicCytoscapeGraphSize
            : problematicBiokbGraphSize;
    return nodesNumber > problematicGraphSize;
}

// Determines if num of nodes is big enough to cause major performance problems and break the app
export function isGraphUnmanageable(nodesNumber: number, graphType: GraphType) {
    const unmanageableGraphSize =
        graphType === GraphType.cytoscape
            ? unmanageableSizeCytoscapeGraphSize
            : unmanageableBiokbGraphSize;
    return nodesNumber > unmanageableGraphSize;
}

export function isQueryResultUnmanageable(
    graphType: GraphType,
    maxPathLengthOptions: MaxPathLengthOptionResult
) {
    return (
        maxPathLengthOptions.merged[0] &&
        isGraphUnmanageable(maxPathLengthOptions.merged[0].amountOfNodes, graphType)
    );
}

export function isQueryResultIncomplete(maxPathLengthOptions: MaxPathLengthOptionResult) {
    return !maxPathLengthOptions.merged.length;
}

export function parsePresDetailsLayout(
    layout: string,
    isHiddenNodesVisible: boolean,
    isHiddenNode: (id: number) => boolean
): {visibleNodesPositions: string; invisibleNodesPositions: string} {
    const layoutJson = layout ? JSON.parse(layout) : {};

    const visibleNodesPositionsMap: GraphLayout = {};
    const invisibleNodesPositionsMap: GraphLayout = {};

    Object.keys(layoutJson).forEach((key) => {
        if (isHiddenNodesVisible || !isHiddenNode(Number(key))) {
            visibleNodesPositionsMap[key] = layoutJson[Number(key)];
        } else {
            invisibleNodesPositionsMap[key] = layoutJson[Number(key)];
        }
    });
    const visibleNodesPositions = Object.keys(visibleNodesPositionsMap).length
        ? JSON.stringify(visibleNodesPositionsMap)
        : '';
    const invisibleNodesPositions = Object.keys(invisibleNodesPositionsMap).length
        ? JSON.stringify(invisibleNodesPositionsMap)
        : '';

    return {visibleNodesPositions, invisibleNodesPositions};
}

export function persistPositionsByRawNodeId(
    prevNodes: GraphNode[],
    newNodes: GraphNode[],
    previousNodesPositions: string
) {
    const newPositions: GraphLayout = {};
    const positionsByRawNodeId: GraphLayout = {};
    const previousNodesPositionsParsed: GraphLayout = JSON.parse(previousNodesPositions);

    prevNodes.forEach((node) =>
        node.rawNodeIds.forEach((rawNodeId) => {
            positionsByRawNodeId[rawNodeId] = previousNodesPositionsParsed[node.id];
        })
    );

    newNodes.forEach((node) => {
        newPositions[node.id] = positionsByRawNodeId[node.rawNodeIds[0]];
    });

    return newPositions;
}
