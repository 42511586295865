import {
    Button,
    Divider,
    Input,
    SearchIcon,
    PlusUserIcon,
    Preloader as UIPreloader
} from '@genestack/ui';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {UserIdentity} from '../../../typings/user';
import {LocalBackdrop} from '../../components/local-backdrop';
import {Preloader} from '../../components/preloader/preloader';
import {CustomResizableLayout, ResizablePanel} from '../../components/layout/resizable-layout';
import {Sidebar} from '../../components/layout/sidebar';
import {UserList} from './user-list/user-list';
import {WindowTitle} from '../../components/window-title';
import {useUserListQuery} from '../../hooks/manage-users/use-user-list-query';
import {useLocationParams} from '../../hooks/use-location';
import {pluralize} from '../../utils/pluralize';

import {AddUserDialog} from './add-user-dialog';
import styles from './manage-users.module.css';
import {SidebarHeader} from '../../components/layout/sidebar/sidebar-header/sidebar-header';
import {UserSettings} from './user-settings/user-settings';

const escapeString = (str: string) => str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
const strToRegexp = (str: string) => new RegExp(`(${escapeString(str)})`, 'ig');

function getFilteredValues(filter: string, users: UserIdentity[]) {
    return filter !== ''
        ? users.filter(
              (user) =>
                  user.name?.match(strToRegexp(filter)) || user.email.match(strToRegexp(filter))
          )
        : users;
}

export function ManageUsersPage() {
    const history = useHistory();
    const userListQuery = useUserListQuery();
    const {userId} = useLocationParams();

    const [searchValue, setSearchValue] = React.useState('');
    const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);
    const [lastAddedUserEmail, setLastAddedUserEmail] = React.useState<string | null>(null);

    const openAddUserDialog = React.useCallback(() => {
        setAddUserDialogOpen(true);
    }, []);

    const closeAddUserDialog = React.useCallback(() => {
        setAddUserDialogOpen(false);
    }, []);

    const handleInputClearButtonClick = () => {
        setSearchValue('');
    };

    const handleCloseSettings = () => {
        history.push('/users');
    };

    const filteredUsers = React.useMemo(
        () => getFilteredValues(searchValue.trim(), userListQuery.data || []),
        [userListQuery.data, searchValue]
    );

    const selectedUser = userListQuery.data?.find(({id}) => userId === id);
    const totalCount = userListQuery.data?.length ?? 0;

    return (
        <WindowTitle text="Users">
            <LocalBackdrop open variant="zerostate" className={styles.backdrop} />
            <CustomResizableLayout
                settings={{initialFirstPanelShare: 0.53, minPermittedHeight: 300}}
                localStorageKey="users.manager.layoutSize"
                className={styles.layout}
                alignment="horizontal"
            >
                {(props) => (
                    <React.Fragment>
                        <ResizablePanel index={0} {...props}>
                            <Sidebar
                                className={styles.sidebar}
                                border="right"
                                header={
                                    <SidebarHeader
                                        title="Manage users and permissions"
                                        additionalControl={
                                            <Button
                                                onClick={openAddUserDialog}
                                                icon={<PlusUserIcon />}
                                                ghost
                                            >
                                                Create new user
                                            </Button>
                                        }
                                    >
                                        <Input
                                            clearable={!!searchValue}
                                            autoFocus
                                            fullWidth
                                            value={searchValue}
                                            onValueChange={setSearchValue}
                                            placeholder="Filter users by name or email"
                                            prepend={<SearchIcon />}
                                            onClearButtonClick={handleInputClearButtonClick}
                                        />

                                        <Divider variant="transparent" gap={2} />

                                        <UIPreloader show={userListQuery.isLoading} count={1}>
                                            {filteredUsers.length === totalCount ? (
                                                <>
                                                    {filteredUsers.length}{' '}
                                                    {pluralize('user', filteredUsers.length)}
                                                </>
                                            ) : (
                                                <>
                                                    {filteredUsers.length} of
                                                    {totalCount} {pluralize('user', totalCount)}{' '}
                                                    filtered
                                                </>
                                            )}
                                        </UIPreloader>
                                    </SidebarHeader>
                                }
                            >
                                <Preloader show={userListQuery.isLoading}>
                                    <UserList
                                        users={filteredUsers}
                                        lastAddedUserEmail={lastAddedUserEmail}
                                        highLightedText={searchValue}
                                    />
                                </Preloader>
                            </Sidebar>
                        </ResizablePanel>

                        {selectedUser && (
                            <ResizablePanel index={1} {...props}>
                                <UserSettings user={selectedUser} onClose={handleCloseSettings} />
                            </ResizablePanel>
                        )}
                    </React.Fragment>
                )}
            </CustomResizableLayout>

            <AddUserDialog
                onUpdateLastAddedUserEmail={setLastAddedUserEmail}
                open={addUserDialogOpen}
                onClose={closeAddUserDialog}
            />
        </WindowTitle>
    );
}
