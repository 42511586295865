import {BookmarkBorderedIcon, WarningIcon} from '@genestack/ui';
import React from 'react';

import {EyeIcon} from '../../../../../components/icons/eye-icon';
import {useUserEditingPermissions} from '../../../../../hooks/user/use-has-permission';

interface Props {
    displayFavoriteState: boolean;
    docInfo?: ViewModeDocInfo;
}

/** Document meta info */
export function DocumentMeta({displayFavoriteState, docInfo}: Props) {
    const {editingPermitted} = useUserEditingPermissions();

    if (!docInfo) {
        return null;
    }

    const {readOnly, userFavorite, validationStatus} = docInfo;
    const hasMeta =
        readOnly || (displayFavoriteState && userFavorite) || validationStatus === 'INVALID';

    if (!hasMeta) {
        return null;
    }

    return (
        <React.Fragment>
            {readOnly && editingPermitted && (
                <div title="Read-only document">
                    <EyeIcon />
                </div>
            )}

            {validationStatus === 'INVALID' && (
                <div title="Invalid">
                    <WarningIcon />
                </div>
            )}

            {displayFavoriteState && userFavorite && (
                <div title="In your Bookmarks">
                    <BookmarkBorderedIcon />
                </div>
            )}
        </React.Fragment>
    );
}
