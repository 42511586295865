import React from 'react';
import {
    Button,
    Controls,
    ControlsItem,
    CrossIcon,
    ExpandVerticalIcon,
    CollapseVerticalIcon,
    Typography,
    Switch,
    Link,
    Menu,
    CheckMarkIcon,
    MenuItem,
    MenuHandler,
    KeyboardArrowBottomIcon
} from '@genestack/ui';
import style from './neighborhood-graph.module.css';
import {GraphLegend} from '../../../../../components/graph/graph-legend/graph-legend';
import {GraphViewType} from '../../../../../components/graph/interface';
import {NeighborhoodGraphDirection} from './interface';

interface Props {
    closeGraph: () => void;
    isCollapsedPanel: boolean;
    toggleIsCollapsedPanel: () => void;
    selectedViewType: GraphViewType;
    toggleSelectedViewType: () => void;
    maxDistance: number;
    setMaxDistance: (val: number) => void;
    direction: NeighborhoodGraphDirection;
    setDirection: (direction: NeighborhoodGraphDirection) => void;
}

export const neighborhoodGraphHeaderHeight = 43;

export function NeighborhoodGraphHeader(props: Props) {
    const distanceMenu = (
        <Menu onValueSelect={props.setMaxDistance}>
            {[1, 2, 3, 4].map((distance) => (
                <MenuItem
                    value={distance}
                    key={distance}
                    prepend={distance === props.maxDistance && <CheckMarkIcon />}
                >
                    {`${distance} edges`}
                </MenuItem>
            ))}
        </Menu>
    );

    const directionMenu = (
        <Menu onValueSelect={props.setDirection}>
            {[
                NeighborhoodGraphDirection.OUTGOING,
                NeighborhoodGraphDirection.INCOMING,
                NeighborhoodGraphDirection.BOTH
            ].map((direction) => (
                <MenuItem
                    value={direction}
                    key={direction}
                    prepend={direction === props.direction && <CheckMarkIcon />}
                >
                    {direction === NeighborhoodGraphDirection.BOTH ? 'both directions' : direction}
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <div className={style.neighborhoodGraphHeader}>
            <Controls flexWrap="wrap" justify="end" align="center">
                {!props.isCollapsedPanel && (
                    <React.Fragment>
                        <ControlsItem>
                            <Typography as="label">
                                <Controls>
                                    <ControlsItem>
                                        <Switch
                                            checked={
                                                props.selectedViewType === GraphViewType.MERGED
                                            }
                                            onCheckedChange={props.toggleSelectedViewType}
                                        />
                                    </ControlsItem>
                                    <ControlsItem as={Link}>Collapse matched</ControlsItem>
                                </Controls>
                            </Typography>
                        </ControlsItem>

                        <ControlsItem>
                            <MenuHandler menu={directionMenu}>
                                <Button size="small" iconEnd={<KeyboardArrowBottomIcon />}>
                                    {props.direction}
                                </Button>
                            </MenuHandler>
                        </ControlsItem>

                        <ControlsItem>
                            <MenuHandler menu={distanceMenu}>
                                <Button size="small" iconEnd={<KeyboardArrowBottomIcon />}>
                                    {`Max. distance - ${props.maxDistance} edges`}
                                </Button>
                            </MenuHandler>
                        </ControlsItem>

                        <ControlsItem>
                            <GraphLegend />
                        </ControlsItem>
                    </React.Fragment>
                )}

                <ControlsItem>
                    <Button
                        size="small"
                        iconEnd={
                            props.isCollapsedPanel ? (
                                <ExpandVerticalIcon />
                            ) : (
                                <CollapseVerticalIcon />
                            )
                        }
                        onClick={props.toggleIsCollapsedPanel}
                        ghost
                    />
                </ControlsItem>

                <ControlsItem>
                    <Button size="small" iconEnd={<CrossIcon />} onClick={props.closeGraph} ghost />
                </ControlsItem>
            </Controls>
        </div>
    );
}
