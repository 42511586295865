import {Typography, Controls, ControlsItem, Switch, Link} from '@genestack/ui';
import React from 'react';

import {showNotification} from '../../../../../providers/notifications-center';
import {PublishAllStatusNotification} from '../../../../../providers/notifications-center/notifications/publish';
import {Sidebar} from '../../../../../components/layout/sidebar';
import {useGlobalStateVariable} from '../../../../../providers/global-state';
import {extractInfiniteQueryItems} from '../../../../../components/pagination/infinite-query-items';
import {usePublishAllMutation} from '../../hooks/use-publish-all-mutation';
import {useUnpublishedDocsQuery} from '../../hooks/use-unpublished-docs-query';
import {PortionedDocsGroupContent} from '../portioned-docs-group-content';

import {UnpublishedDocsMenu} from './unpublished-docs-menu';
import {DocumentStatusIcon} from '../../../common/doc-status-icon/document-status-icon';
import {EditViewSidebarProps} from '../interface';
import {SidebarHeader} from '../../../../../components/layout/sidebar/sidebar-header/sidebar-header';

export const UnpublishedDocuments = (props: EditViewSidebarProps) => {
    const unpublishedQuery = useUnpublishedDocsQuery();
    const publishAll = usePublishAllMutation();
    const [isOnlyMyDraftsShown, setOnlyMyDraftsShown] = useGlobalStateVariable(
        'documents.edit.onlyMyDraftsShown',
        true
    );

    const allUnpublishedDrafts = React.useMemo(
        () => extractInfiniteQueryItems(unpublishedQuery, (response) => response.data) || [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [unpublishedQuery.data]
    );
    const onlyMyDrafts = React.useMemo(
        () => allUnpublishedDrafts.filter((item) => item.editedByCurrentUser),
        [allUnpublishedDrafts]
    );
    const draftsList = isOnlyMyDraftsShown ? onlyMyDrafts : allUnpublishedDrafts;

    const handlePublishDrafts = async (publishOnlyMyDrafts: boolean) => {
        try {
            await publishAll.mutateAsync(publishOnlyMyDrafts);
            showNotification(<PublishAllStatusNotification />);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            showNotification(<PublishAllStatusNotification isError errorMessage={error.message} />);
        }
    };

    const switchDisabled = !allUnpublishedDrafts.length;

    const header = (
        <SidebarHeader
            title="To be published"
            onClose={props.closeSidebar}
            additionalControl={
                <UnpublishedDocsMenu
                    isDocsLoading={unpublishedQuery.isFetching}
                    isPublishAllPending={publishAll.isLoading}
                    onPublishDrafts={handlePublishDrafts}
                    allDraftsNumber={allUnpublishedDrafts.length}
                    myDraftsNumber={onlyMyDrafts.length}
                />
            }
        >
            <Controls justify="space-between">
                <ControlsItem>
                    {unpublishedQuery.data && (
                        <Typography intent="quiet">
                            {draftsList.length} docs{' '}
                            {isOnlyMyDraftsShown && !switchDisabled ? 'filtered' : ''}
                        </Typography>
                    )}
                </ControlsItem>

                <ControlsItem>
                    <Typography as="label" intent={switchDisabled ? 'quiet' : 'no-intent'}>
                        <Controls gap={2}>
                            <ControlsItem>
                                <Switch
                                    checked={switchDisabled ? false : isOnlyMyDraftsShown}
                                    onCheckedChange={setOnlyMyDraftsShown}
                                    disabled={switchDisabled}
                                />
                            </ControlsItem>
                            <ControlsItem as={switchDisabled ? undefined : Link}>
                                Only my drafts
                            </ControlsItem>
                        </Controls>
                    </Typography>
                </ControlsItem>
            </Controls>
        </SidebarHeader>
    );

    return (
        <Sidebar header={header} border="right">
            <PortionedDocsGroupContent
                docs={draftsList}
                isInitialLoading={unpublishedQuery.isLoading}
                isLoadingMore={unpublishedQuery.isFetchingNextPage}
                onClickLoadMoreButton={unpublishedQuery.fetchNextPage}
                hasNextPage={unpublishedQuery.hasNextPage}
                disabled={publishAll.isLoading}
                DocStatusIconComponent={DocumentStatusIcon}
            />
        </Sidebar>
    );
};
