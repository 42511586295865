import {useQuery} from 'react-query';

import {apiClient} from '../../../../utils/api-client';

import {useQueryListCache} from './update-query-list-cache';

const PAGE_LIMIT = 100;

function getFavoriteQueriesRequest() {
    return apiClient
        .post<PageResponse<RecentQuery, number>>({
            path: 'queries-service/api/queries/favorite',
            parameters: {limit: PAGE_LIMIT}
        })
        .then((res) => res.data);
}

/** Provides favorite queries list */
export function useFavoriteQueries() {
    return useQuery('getFavoriteQueriesRequest', getFavoriteQueriesRequest);
}

/** Provides cache control for favorite queries */
export function useFavoriteQueriesCache() {
    return useQueryListCache<RecentQuery>('getFavoriteQueriesRequest');
}
