import React from 'react';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

import {showNotification} from '../../providers/notifications-center';
import {LinkCopiedNotification} from './link-copied-notification';

/** A hook returning a function that, when invoked, copies current location to clipboard */
export const useCopyLinkToThisPage = () => {
    const [copyToClipboardState, copyToClipboard] = useCopyToClipboard();

    React.useEffect(() => {
        if (copyToClipboardState.value && !copyToClipboardState.error) {
            showNotification(<LinkCopiedNotification />);
        }
    }, [copyToClipboardState]);

    return React.useCallback(() => {
        copyToClipboard(document.location.href);
    }, [copyToClipboard]);
};
