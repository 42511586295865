import React from 'react';
import {ChevronDownIcon, ChevronRightIcon, Link, ListItem, Typography} from '@genestack/ui';
import classNames from 'classnames';
import {OntologyTreeElement, OntologyTreeElementId, OntologyTreeResponse} from './interface';
import styles from './ontology-tree-item.module.css';
import {getTreeItem} from './helpers';
import {useFoldingItemsState} from '../../../../../hooks/use-folding-items-state';
import {
    AdditionalChildrenPageResponse,
    useAdditionalChildrenQuery
} from './use-additional-children-query';
import {extractInfiniteQueryItems} from '../../../../../components/pagination/infinite-query-items';
import {LoadMoreControl} from '../../../../../components/pagination/load-more-control/load-more-control';
import {OntologyTreeInitialItem} from './ontology-tree-initial-item';

export interface OntologyTreeItemProps {
    id: OntologyTreeElementId;
    padding: number;
    foldingItemsProps: ReturnType<typeof useFoldingItemsState>;
    currentDocInfo: DocInfo;
    ontologyTreeData: OntologyTreeResponse;
    element: OntologyTreeElement;
}

interface InitialElementProps extends OntologyTreeItemProps {
    initialChildren: OntologyTreeElementId[];
    additionalChildrenNum: number;
    isAdditionalElement: false;
}

interface AdditionalElementProps extends OntologyTreeItemProps {
    additionalChildrenNum: number;
    initialChildren: undefined;
    isAdditionalElement: true;
}

function isAdditionalElementProps(
    props: AdditionalElementProps | InitialElementProps
): props is AdditionalElementProps {
    return props.isAdditionalElement;
}

export function OntologyTreeItem(props: InitialElementProps | AdditionalElementProps) {
    const isAdditionalElement = isAdditionalElementProps(props);

    const isElementFromCurrentDoc =
        props.element.id.documentAccession === props.currentDocInfo.accession;

    const additionalChildrenQuery = useAdditionalChildrenQuery({
        parentId: props.id,
        elementsNum: props.additionalChildrenNum,
        ignoreElementIds: isAdditionalElement ? [] : props.initialChildren
    });

    const additionalChildrenItems = extractInfiniteQueryItems(
        additionalChildrenQuery,
        (response: AdditionalChildrenPageResponse) => response.data
    );

    const hasNoChildren =
        !props.additionalChildrenNum && (isAdditionalElement || !props.initialChildren.length);

    const foldingStateKey = JSON.stringify(props.id);
    const foldingState = props.foldingItemsProps.foldingState[foldingStateKey];
    const isUnfoldedByDefault = !isAdditionalElement && !!props.initialChildren.length;
    const isUnfolded = isUnfoldedByDefault
        ? foldingState || foldingState === undefined
        : foldingState;

    const prependIcon = (function () {
        if (hasNoChildren) {
            return null;
        }

        if (isUnfolded) {
            return <ChevronDownIcon />;
        }

        return <ChevronRightIcon />;
    })();

    const onClick = React.useCallback(() => {
        if (hasNoChildren) {
            return;
        }

        if (isUnfolded) {
            props.foldingItemsProps.fold(foldingStateKey);
            return;
        }

        if (
            (!props.initialChildren || !props.initialChildren.length) &&
            !additionalChildrenItems &&
            props.additionalChildrenNum
        ) {
            additionalChildrenQuery.fetchNextPage();
        }

        props.foldingItemsProps.unfold(foldingStateKey);
    }, [
        props.initialChildren,
        foldingStateKey,
        props.foldingItemsProps,
        isUnfolded,
        additionalChildrenItems,
        props.additionalChildrenNum,
        additionalChildrenQuery,
        hasNoChildren
    ]);

    const padding = prependIcon ? props.padding : props.padding + 1;

    return (
        <div
            className={styles.itemContainer} // @ts-ignore
            style={{'--tree-item-padding': padding}}
        >
            <div className={classNames({[styles.itemWithVerticalLine]: isUnfolded})}>
                {isUnfolded && (
                    <div className={styles.verticalLineContainer}>
                        <div className={styles.verticalLine} />
                    </div>
                )}
                <ListItem
                    className={styles.ontologyTreeItem}
                    interactive
                    prepend={prependIcon}
                    onClick={onClick}
                >
                    <Typography as={isElementFromCurrentDoc ? Link : undefined}>
                        {props.element.name}
                    </Typography>
                </ListItem>
            </div>

            {isUnfolded &&
                !isAdditionalElement &&
                props.initialChildren.map((childId) => {
                    const element = getTreeItem(props.ontologyTreeData, childId);

                    return (
                        <OntologyTreeInitialItem
                            id={childId}
                            ontologyTreeData={props.ontologyTreeData}
                            key={JSON.stringify(childId)}
                            padding={padding + 1}
                            foldingItemsProps={props.foldingItemsProps}
                            currentDocInfo={props.currentDocInfo}
                            element={element}
                        />
                    );
                })}

            {isUnfolded &&
                additionalChildrenItems &&
                additionalChildrenItems.map((additionalChild) => (
                    <OntologyTreeItem
                        id={additionalChild.element.id}
                        ontologyTreeData={props.ontologyTreeData}
                        key={JSON.stringify(additionalChild.element.id)}
                        padding={padding + 1}
                        foldingItemsProps={props.foldingItemsProps}
                        currentDocInfo={props.currentDocInfo}
                        element={additionalChild.element}
                        additionalChildrenNum={additionalChild.childrenCount}
                        initialChildren={undefined}
                        isAdditionalElement
                    />
                ))}

            {isUnfolded && (additionalChildrenItems?.length || 0) < props.additionalChildrenNum && (
                <LoadMoreControl
                    onClick={additionalChildrenQuery.fetchNextPage}
                    className={classNames(styles.ontologyTreeItem, styles.withButton)}
                    isLoading={additionalChildrenQuery.isFetching}
                    // @ts-ignore
                    style={{'--tree-item-padding': padding + 2}}
                >
                    ...Load next elements
                </LoadMoreControl>
            )}
        </div>
    );
}
