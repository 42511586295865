import React, {PropsWithChildren} from 'react';
import {
    ListItem,
    Typography,
    TextLabel,
    BulletIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    Tooltip,
    TooltipHandler
} from '@genestack/ui';
import {useFoldingItemsState} from '../../../../../hooks/use-folding-items-state';
import styles from './folding-item.module.css';

export type FoldingItemProps = PropsWithChildren<{
    label: string;
    foldingStateProps: ReturnType<typeof useFoldingItemsState>;
    foldingStateKey: string;
    count?: number;
    padding: 1 | 2;
}>;

type AlwaysUnfoldedItemProps = Omit<FoldingItemProps, 'foldingStateProps' | 'foldingStateKey'> & {
    isAlwaysUnfolded: true;
};

function isAlwaysUnfoldedItemProps(
    props: FoldingItemProps | AlwaysUnfoldedItemProps
): props is AlwaysUnfoldedItemProps {
    return (props as AlwaysUnfoldedItemProps).isAlwaysUnfolded;
}

export function FoldingItem(props: FoldingItemProps | AlwaysUnfoldedItemProps) {
    const isAlwaysUnfoldedItem = isAlwaysUnfoldedItemProps(props);
    const isUnfolded =
        isAlwaysUnfoldedItem || props.foldingStateProps.foldingState[props.foldingStateKey];

    const onClick = React.useCallback(
        () =>
            !isAlwaysUnfoldedItem &&
            props.foldingStateProps.toggleFoldingState(props.foldingStateKey),
        [props, isAlwaysUnfoldedItem]
    );

    const prependIcon = (function () {
        if (isAlwaysUnfoldedItem) {
            return null;
        }

        if (isUnfolded) {
            return <ChevronDownIcon />;
        }

        return <ChevronRightIcon />;
    })();

    return (
        <React.Fragment>
            <ListItem
                interactive={!isAlwaysUnfoldedItem}
                prepend={prependIcon}
                onClick={onClick}
                className={props.padding === 2 && styles.statItemLevel2}
            >
                <TooltipHandler
                    tooltip={<Tooltip className={styles.labelTooltip}>{props.label}</Tooltip>}
                >
                    <TextLabel
                        caption={
                            props.count !== undefined ? (
                                <Typography intent="quiet">
                                    <BulletIcon />
                                    {props.count}
                                </Typography>
                            ) : undefined
                        }
                    >
                        {props.label}
                    </TextLabel>
                </TooltipHandler>
            </ListItem>

            {isUnfolded && props.children}
        </React.Fragment>
    );
}
