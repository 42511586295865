import {
    Button,
    ButtonProps,
    CheckMarkIcon,
    Divider,
    Menu,
    MenuCaption,
    MenuItem,
    MoreIcon,
    Typography,
    useMenuHandler
} from '@genestack/ui';
import React from 'react';

import {CreateDocumentSubmenu} from './create-documents-submenu';
import {EditModeTab} from '../../../../components/layout/page-layout/layout-typings';
import {useSidebarTabs} from '../../../../hooks/use-sidebar-tabs';

interface MenuItemType {
    label: string;
    value: EditModeTab;
    hotkey?: string;
}

interface Props extends ButtonProps {
    createNew: (docType: DocType) => void;
    tabSettings: ReturnType<typeof useSidebarTabs>;
}

const MENU_ITEMS: MenuItemType[] = [
    {
        label: 'Search',
        value: EditModeTab.SEARCH
    },
    {
        label: 'Recent documents',
        value: EditModeTab.RECENT
    },
    {
        label: 'To be published',
        value: EditModeTab.UNPUBLISHED
    },
    {
        label: 'Browse documents',
        value: EditModeTab.DOCUMENTS
    },
    {
        label: 'Recycle bin',
        value: EditModeTab.BIN
    }
];

/** Layout menu for edit mode */
export function LayoutMenu({tabSettings, createNew, ...restProps}: Props) {
    const referenceElement = React.useRef<HTMLButtonElement>(null);
    const menu = useMenuHandler({referenceElement: referenceElement.current});

    const handleMenuItem = (value: EditModeTab) => () => {
        tabSettings.setActivityBarTab(value);
    };

    const createDocumentSubmenu = React.useMemo(
        () => <CreateDocumentSubmenu createNew={createNew} />,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Button
                ghost
                data-qa="activitybar-menu-button"
                icon={<MoreIcon />}
                {...menu.getReferenceProps<React.ComponentPropsWithRef<typeof Button>>({
                    ref: referenceElement,
                    active: menu.isOpen
                })}
                {...restProps}
            />

            <Menu {...menu.getMenuProps()}>
                <MenuCaption>
                    <Typography variant="section">Edit Biology Knowledge Base</Typography>
                </MenuCaption>

                <MenuItem subMenu={createDocumentSubmenu}>Create new document</MenuItem>

                <Divider />

                {MENU_ITEMS.map(({label, value, hotkey}) => (
                    <MenuItem
                        key={value}
                        value={value}
                        prepend={tabSettings.activityBarTab === value ? <CheckMarkIcon /> : null}
                        append={
                            hotkey && (
                                <Typography variant="caption" intent="quiet" box="inline" as="span">
                                    {hotkey}
                                </Typography>
                            )
                        }
                        onClick={handleMenuItem(value)}
                    >
                        {label}
                    </MenuItem>
                ))}

                <Divider />

                <MenuItem disabled>Copy link to this view</MenuItem>
            </Menu>
        </>
    );
}
