import {ScrollView} from '@genestack/ui';
import classNames from 'classnames';
import React from 'react';

import styles from './dsl-view.module.css';
import {DSLPreloader} from '../../../../preloader/dsl-preloader';

interface Props {
    html: string | undefined;
}

export const DSLView = ({html}: Props) => {
    return (
        <DSLPreloader show={!html}>
            {html && (
                <ScrollView className={styles.root}>
                    <div
                        className={classNames(styles.dsl)}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{__html: html}}
                    />
                </ScrollView>
            )}
        </DSLPreloader>
    );
};
