import {
    Controls,
    ControlsItem,
    Divider,
    MenuItem,
    BookmarkBorderedIcon,
    ExpandVerticalIcon,
    CollapseVerticalIcon,
    Button
} from '@genestack/ui';
import React from 'react';

import {DocumentTypeLabel} from '../../../../../components/document-type-label';
import {RelativeLink} from '../../../../../components/relative-link';
import {useDocInfo} from '../../../../../providers/doc-info-store';
import {useDocRevisionsContext} from '../../../../../providers/version';
import {LastActionCaption} from '../../../common/last-action-caption';
import {TitleSlotView} from '../../title-slot-view';

import {DocumentMenu} from './document-menu';
import {useEditorModel} from '../model';
import {VersionLabel} from '../../../common/version-history/version-label';
import {EditorHeaderSkeleton} from '../../../../../components/editor/editor-header/editor-header-skeleton';
import {EditorHeader} from '../../../../../components/editor/editor-header/editor-header';

interface Props {
    documentId: string;
    revisionId?: string;
    editorState: ReturnType<typeof useEditorModel>;
    isBodyCollapsed: boolean;
    toggleIsBodyCollapsed: () => void;
    isGraphPanelOpen: boolean;
}

export const DocumentEditorHeader = ({
    documentId,
    revisionId,
    editorState,
    isBodyCollapsed,
    toggleIsBodyCollapsed,
    isGraphPanelOpen
}: Props) => {
    const {selectedRevision} = useDocRevisionsContext();
    const dynamicDocInfo = useDocInfo(documentId);

    const staticDocInfo = React.useMemo<Partial<ViewModeDocInfo> | undefined>(
        () => (selectedRevision ? {name: selectedRevision.documentName} : undefined),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedRevision?.documentName]
    );

    const docInfo = React.useMemo(
        () => (dynamicDocInfo ? {...dynamicDocInfo, ...staticDocInfo} : undefined),
        [dynamicDocInfo, staticDocInfo]
    );

    const docMenuCaption = React.useMemo(
        () =>
            !!editorState.revisionInfo && (
                <>
                    <Divider variant="transparent" />
                    <LastActionCaption lastAction={editorState.revisionInfo} />
                </>
            ),
        [editorState.revisionInfo]
    );

    const docTitleMenuItems = React.useMemo(() => {
        const illegalProps = {
            as: RelativeLink,
            to: `/${documentId}/revisions/${editorState.revisionInfo?.revision}`
        };

        return (
            <MenuItem disabled={!editorState.revisionInfo} {...illegalProps}>
                Show revisions
            </MenuItem>
        );
    }, [documentId, editorState.revisionInfo]);

    if (!docInfo) {
        return <EditorHeaderSkeleton />;
    }

    return (
        <EditorHeader
            disableEditingTitle
            data-qa="doc-header"
            titleProps={{
                'data-qa': 'doc-title'
            }}
            menu={
                revisionId ? null : (
                    <DocumentMenu
                        documentId={documentId}
                        docInfo={docInfo}
                        menuCaption={docMenuCaption}
                        menuItems={docTitleMenuItems}
                    />
                )
            }
            name={docInfo.name}
            prepend={
                <Controls gap={1}>
                    {docInfo.userFavorite && (
                        <ControlsItem>
                            <BookmarkBorderedIcon />
                        </ControlsItem>
                    )}
                    <ControlsItem>
                        <DocumentTypeLabel type={docInfo.type} readOnly={docInfo.readOnly} />
                    </ControlsItem>
                </Controls>
            }
            append={
                <Controls gap={1}>
                    {revisionId && selectedRevision ? (
                        <ControlsItem>
                            <VersionLabel selectedVersion={selectedRevision} />
                        </ControlsItem>
                    ) : null}

                    <ControlsItem>
                        <TitleSlotView.Slot />
                    </ControlsItem>
                </Controls>
            }
        >
            {isGraphPanelOpen && (
                <Controls justify="end">
                    <ControlsItem>
                        <Button
                            size="small"
                            ghost
                            iconEnd={
                                isBodyCollapsed ? <ExpandVerticalIcon /> : <CollapseVerticalIcon />
                            }
                            onClick={toggleIsBodyCollapsed}
                        />
                    </ControlsItem>
                </Controls>
            )}
        </EditorHeader>
    );
};
