import {
    Button,
    Controls,
    ControlsItem,
    Dialog,
    DialogBody,
    DialogHeader,
    DialogFooter,
    DialogProps,
    Tooltip,
    TooltipHandler,
    Typography,
    Notification
} from '@genestack/ui';
import * as React from 'react';

import {useUpdateUserMutation} from '../../../hooks/manage-users/use-update-user-mutation';
import {useCurrentUserQuery} from '../../../hooks/user/use-current-user-query';
import {showNotification} from '../../../providers/notifications-center';
import {showErrorNotification} from '../../../providers/notifications-center/notifications-store';
import {ValidatedInput} from '../../../components/validated-input';

interface Props extends DialogProps {
    onClose: () => void;
}

/** Form for editing user info */
export const UserInfoDialog = ({open, onClose, ...restProps}: Props) => {
    const currentUserQuery = useCurrentUserQuery();
    const updateUser = useUpdateUserMutation();

    const initialUserName = currentUserQuery.data?.name ?? '';
    const [userNameValue, setUserNameValue] = React.useState(initialUserName);
    const trimmedUserName = userNameValue.trim();

    const handleDialogClosed = () => {
        setUserNameValue(initialUserName);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!currentUserQuery.data) {
            showErrorNotification('User is not found');

            return;
        }

        await updateUser.mutateAsync({
            id: currentUserQuery.data.id,
            name: trimmedUserName
        });

        showNotification(<Notification>Your changes have been saved</Notification>);
        onClose();
    };

    const isSameName = initialUserName === trimmedUserName;

    return (
        <Dialog
            as="form"
            {...restProps}
            open={open}
            onSubmit={handleSubmit}
            onClose={onClose}
            onClosed={handleDialogClosed}
        >
            <DialogHeader>
                <Typography variant="title">Personal information</Typography>
            </DialogHeader>
            <DialogBody>
                <Typography variant="section" box="paragraph" as="label" intent="quiet">
                    Enter new user name
                </Typography>

                <ValidatedInput
                    autoFocus
                    fullWidth
                    value={userNameValue}
                    onValueChange={setUserNameValue}
                />
            </DialogBody>
            <DialogFooter>
                <Controls>
                    <ControlsItem>
                        <TooltipHandler
                            tooltip={
                                !userNameValue.trim() ? (
                                    <Tooltip>Full name should not be empty</Tooltip>
                                ) : null
                            }
                        >
                            <Button
                                intent="accent"
                                type="submit"
                                disabled={isSameName || !trimmedUserName || updateUser.isLoading}
                            >
                                Update
                            </Button>
                        </TooltipHandler>
                    </ControlsItem>
                    <ControlsItem>
                        <Button onClick={onClose}>Cancel</Button>
                    </ControlsItem>
                </Controls>
            </DialogFooter>
        </Dialog>
    );
};
