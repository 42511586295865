import {useQuery} from 'react-query';
import {apiClient} from '../../../../../utils/api-client';
import {OntologyTreeResponse} from './interface';

function getTreeStructureRequest(accession: string, isCurrentDocOnly: boolean) {
    return apiClient.post<OntologyTreeResponse>({
        path: 'editor-documents-service/api/trees',
        parameters: {
            accession,
            includeExternal: !isCurrentDocOnly,
            externalChildrenLimit: 1
        }
    });
}

export function useTreeStructureQuery(
    accession: string,
    isCurrentDocOnly: boolean,
    shouldRefetchFrequently?: boolean
) {
    return useQuery({
        queryKey: ['ontology-tree-structure', accession, isCurrentDocOnly],
        queryFn: () => getTreeStructureRequest(accession, isCurrentDocOnly),
        refetchInterval: shouldRefetchFrequently ? 3000 : undefined
    });
}
