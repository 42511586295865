import React from 'react';

import {FindUsages} from './find-usages';
import {SidebarSearchResults} from './sidebar-search-results';
import {SidebarSearchField} from '../../../common/sidebar-search-field';
import {Sidebar} from '../../../../../components/layout/sidebar';
import {useSearchSidebarContext} from '../../../common/sidebar-search-field/hooks/use-search-sidebar-context';
import {useSuggestions} from '../../../common/sidebar-search-field/hooks/use-suggestions';
import {useHandleFindUsages} from '../../../common/sidebar-search-field/hooks/use-handle-find-usages';
import {CategorySearchResult} from './category-search-result';
import {EditViewSidebarProps} from '../interface';
import {SidebarHeader} from '../../../../../components/layout/sidebar/sidebar-header/sidebar-header';

interface Props extends EditViewSidebarProps {
    revisionId?: string;
    searchSidebarContext: ReturnType<typeof useSearchSidebarContext>;
    findUsagesProps: ReturnType<typeof useHandleFindUsages>;
    getDocumentLink: (documentId: string, nodeId?: string) => string;
}

export const SearchLayout = (props: Props) => {
    const suggestionsProps = useSuggestions({
        inputValue: props.searchSidebarContext.parsedInputValue
    });

    return (
        <Sidebar
            component="div"
            border="right"
            header={
                <SidebarHeader title="Search" onClose={props.closeSidebar}>
                    <SidebarSearchField
                        disabled={props.findUsagesProps.loadingNodeType || !!props.revisionId}
                        busy={suggestionsProps.isLoading || props.findUsagesProps.loadingNodeType}
                        suggestionsProps={suggestionsProps}
                        searchSidebarContext={props.searchSidebarContext}
                    />
                </SidebarHeader>
            }
        >
            {(function () {
                const {searchData} = props.searchSidebarContext;

                if (!searchData) {
                    return null;
                }

                if (searchData.mode === 'category') {
                    return <CategorySearchResult searchData={searchData} />;
                }

                if (searchData.mode === 'node') {
                    return (
                        <FindUsages
                            searchData={searchData}
                            getDocumentLink={props.getDocumentLink}
                        />
                    );
                }

                if (searchData.mode === 'doc') {
                    return <SidebarSearchResults searchData={searchData} />;
                }
            })()}
        </Sidebar>
    );
};
