import {InfiniteData, useInfiniteQuery, useQueryClient} from 'react-query';

import {StringSearch} from '../../hooks/requests/use-categories-query';
import {apiClient} from '../../../../../utils/api-client';
import {CategoriesSearch, getCategoriesSearch} from '../../../../../utils/get-categories-search';

export const SEARCH_DOCS_PAGE_SIZE = 60;

interface Props {
    searchText?: string;
    versionId: number;
    categories?: string[];
    validationStatus?: ValidationStatus;
}

interface ViewModeSearchDocumentsOptions {
    stringSearch?: StringSearch;
    types?: DocType[];
    categories?: string[];
    categoriesSearch?: CategoriesSearch;
    readOnly?: boolean;
    limit: number;
    cursor: DocumentCursor | null;
    validationStatus?: ValidationStatus;
}

export function useSearchDocumentsInfiniteQuery(props: Props) {
    const searchString = props.searchText?.trim();

    return useInfiniteQuery(
        [
            'searchDocsViewMode',
            searchString,
            props.versionId,
            props.categories,
            props.validationStatus
        ],
        async ({pageParam = {cursor: null, limit: SEARCH_DOCS_PAGE_SIZE}}) => {
            const requestBody: ViewModeSearchDocumentsOptions = {
                stringSearch: searchString ? {strict: false, value: searchString} : undefined,
                categoriesSearch: getCategoriesSearch(props.categories),
                validationStatus: props.validationStatus,
                cursor: pageParam.cursor,
                limit: pageParam.limit
            };

            return apiClient.post<PageResponse<ViewModeDocInfo>>({
                path: `documents-revisions-service/api/viewer/documents?global-version=${props.versionId}`,
                parameters: requestBody
            });
        },

        {
            getNextPageParam: ({nextCursor, remaining}) => {
                const hasNextPage = remaining > 0;
                if (hasNextPage) {
                    return {
                        cursor: nextCursor,
                        limit: SEARCH_DOCS_PAGE_SIZE
                    };
                }
            },
            keepPreviousData: true,
            cacheTime: 0
        }
    );
}

/** Docs search query cache controls */
export function useSearchDocsInfiniteQueryCache() {
    const queryClient = useQueryClient();

    function setIsDocumentFavorite(documentId: number, isFavorite: boolean) {
        const queriesData = queryClient.getQueriesData<InfiniteData<PageResponse<ViewModeDocInfo>>>(
            ['searchDocsViewMode']
        );

        for (const [, data] of queriesData) {
            data.pages.forEach((page) => {
                // eslint-disable-next-line no-param-reassign
                page.data = page.data.map((document) => {
                    if (document.id === documentId) {
                        return {
                            ...document,
                            userFavorite: isFavorite
                        };
                    }

                    return document;
                });
            });
        }
    }

    return {setIsDocumentFavorite};
}
