import {chain} from '@genestack/ui';
import React from 'react';
import {ErrorBoundary, FallbackProps, ErrorBoundaryPropsWithRender} from 'react-error-boundary';

import {CallApiError, CallApiUnauthorisedError} from '../../utils/api-client';
import {logError} from '../../utils/log-error';
import {NotificationsCenter, showNotification} from '../../providers/notifications-center';
import {UniversalBackdrop} from '../universal-backdrop';

import {getCallApiErrorNotification, getUnexpectedErrorNotification} from './error-notifications';
import {ReportErrorDialog} from './report-error-dialog';

interface Props extends Partial<ErrorBoundaryPropsWithRender> {
    children?: React.ReactNode;
}

interface GlobalError {
    date: Date;
    body: Error | ErrorEvent;
}

const handledErrors = new WeakSet<Error>();
const ignoredErrors = [
    "Uncaught TypeError: Cannot read properties of null (reading 'notify')",
    'ResizeObserver loop limit exceeded'
];

function handleError(error: Error | string) {
    const err = typeof error === 'string' ? new Error(error) : error;

    handledErrors.add(err);
    logError(err);
}

/**
 * `<GlobalErrorBoundary/>` component.
 *
 * This component already contains `<NotificationsCenter />` so you do not need
 * mount yet another. You must wrap your application with `<GlobalErrorBoundary />`
 * to properly catching errors. When fatal error happens it stops render children
 * so all the application is unmounted.
 */
export function GlobalErrorBoundary({onError, ...restProps}: Props) {
    const [reportErrorDialogOpen, setReportErrorDialogOpen] = React.useState<boolean>(false);
    const [globalError, setGlobalError] = React.useState<GlobalError | null>(null);

    const handleDialogClose = React.useCallback(() => {
        setReportErrorDialogOpen(false);
    }, []);

    const handleDialogClosed = React.useCallback(() => {
        setGlobalError(null);
    }, []);

    const createReportErrorClickHandler = React.useCallback((error: Error) => {
        setGlobalError({
            date: new Date(),
            body: error
        });

        setReportErrorDialogOpen(true);
    }, []);

    const handleRenderError = React.useCallback((error: CallApiError | Error) => {
        handleError(error);

        if (error instanceof CallApiUnauthorisedError) {
            return;
        }

        if (error instanceof CallApiError) {
            showNotification(
                getCallApiErrorNotification(error, createReportErrorClickHandler.bind(null, error))
            );
        } else {
            showNotification(
                getUnexpectedErrorNotification(createReportErrorClickHandler.bind(null, error))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWindowError = React.useCallback((event: ErrorEvent) => {
        if (process.env.production && ignoredErrors.includes(event.message)) {
            return;
        }

        // https://github.com/facebook/react/issues/10474
        if (handledErrors.has(event.error)) {
            return;
        }

        handleError(event.message);

        if (event.error instanceof CallApiUnauthorisedError) {
            return;
        }

        if (event.error instanceof CallApiError) {
            showNotification(
                getCallApiErrorNotification(
                    event.error,
                    createReportErrorClickHandler.bind(null, event.error)
                )
            );
        } else if (event.error) {
            showNotification(
                getUnexpectedErrorNotification(
                    createReportErrorClickHandler.bind(null, event.error)
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        window.addEventListener('error', handleWindowError);

        return () => {
            window.removeEventListener('error', handleWindowError);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFallbackRender = ({error}: FallbackProps) => {
        return <UniversalBackdrop open isLoading={error instanceof CallApiUnauthorisedError} />;
    };

    return (
        <>
            <NotificationsCenter />

            <ErrorBoundary
                fallbackRender={handleFallbackRender}
                {...restProps}
                onError={chain(handleRenderError, onError)}
            />

            {globalError && (
                <ReportErrorDialog
                    open={reportErrorDialogOpen}
                    onClose={handleDialogClose}
                    onReportSubmitted={handleDialogClose}
                    onClosed={handleDialogClosed}
                    error={globalError.body}
                    errorDateOccurred={globalError.date}
                />
            )}
        </>
    );
}
