import React from 'react';

import {Preloader} from '../../../../components/preloader/preloader';
import {Sidebar} from '../../../../components/layout/sidebar';
import {useRecentQueries} from '../hooks/use-recent-queries';
import {useQueryId} from '../../../../hooks/use-location';
import {QueryInfoItem} from '../query-info-item';

import {QueryHistory} from './query-history';
import {QueriesSidebarProps} from '../interface';
import {SidebarHeader} from '../../../../components/layout/sidebar/sidebar-header/sidebar-header';
import {EmptyList} from '../../../../components/empty-list/empty-list';

export const QueryHistorySidebar = (props: QueriesSidebarProps) => {
    const recentQueries = useRecentQueries();
    const queryId = useQueryId();

    const renderQueryInfos = React.useCallback(
        (queryInfos: QueryInfo[]) =>
            queryInfos.map((q) => (
                <QueryInfoItem
                    key={q.id}
                    queryInfo={q}
                    isSelected={q.id === queryId}
                    isSharedIcon={q.shared}
                    isBookmarkedIcon={q.favorite}
                />
            )),
        [queryId]
    );

    return (
        <Sidebar
            header={<SidebarHeader onClose={props.closeSidebar} title="My queries" />}
            border="right"
        >
            <Preloader show={recentQueries.isLoading}>
                {recentQueries.data && (
                    <>
                        {recentQueries.data.length === 0 && (
                            <EmptyList message="No queries found" />
                        )}

                        <QueryHistory queryInfos={recentQueries.data}>
                            {renderQueryInfos}
                        </QueryHistory>
                    </>
                )}
            </Preloader>
        </Sidebar>
    );
};
