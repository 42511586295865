import React from 'react';
import classNames from 'classnames';
import {
    CheckMarkIcon,
    CrossIcon,
    InProgressIcon,
    Link,
    MinusIcon,
    Typography,
    WarningIcon
} from '@genestack/ui';
import {GlobalVersionDataResponseItem} from '../../hooks/global-version/use-global-version-query';
import commonMPStyles from '../../components/management-panel/management-panel.module.css';
import gvMPStyles from './gv-management-panel.module.css';
import {getFormattedDateTimeShort} from '../../utils/common';
import {usePreselectedTab} from '../../components/layout/page-layout/use-preselected-tab';
import {ViewModeTab} from '../../components/layout/page-layout/layout-typings';
import {addGlobalVersionToUrl} from '../../hooks/use-search-params';

interface Props {
    globalVersionInfo: GlobalVersionDataResponseItem;
}

function getValidationStatusLabel(status: ValidationStatus) {
    if (status === 'INVALID') {
        return (
            <Typography intent="alarm">
                <WarningIcon /> Invalid
            </Typography>
        );
    }

    if (status === 'FAILED') {
        return (
            <Typography intent="alarm">
                <CrossIcon /> Validation failed
            </Typography>
        );
    }

    if (status === 'NOT_VALIDATED') {
        return (
            <Typography intent="quiet">
                <MinusIcon /> Not validated
            </Typography>
        );
    }

    if (status === 'VALID') {
        return (
            <Typography intent="success">
                <CheckMarkIcon /> Valid
            </Typography>
        );
    }

    return (
        <Typography intent="warning">
            <InProgressIcon /> Being validated
        </Typography>
    );
}

export function GVItem(props: Props) {
    const {getPreselectedTabURLParam} = usePreselectedTab();

    return (
        <div
            className={classNames(
                commonMPStyles.tableRow,
                gvMPStyles.gvRow,
                commonMPStyles.rowWithHover
            )}
        >
            <Typography>{props.globalVersionInfo.label}</Typography>
            <Typography>
                {getValidationStatusLabel(props.globalVersionInfo.validationStatus)}
            </Typography>
            <Typography>{props.globalVersionInfo.description}</Typography>
            <Typography>
                {props.globalVersionInfo.author.name || props.globalVersionInfo.author.email}
            </Typography>
            <Typography>{getFormattedDateTimeShort(props.globalVersionInfo.createdAt)}</Typography>
            <div>
                {(function () {
                    if (props.globalVersionInfo.validationStatus !== 'INVALID') {
                        return <Typography>—</Typography>;
                    }

                    return (
                        <Typography>
                            {props.globalVersionInfo.invalidDocsCount} (
                            <Link
                                href={`${addGlobalVersionToUrl('documents/explore/', props.globalVersionInfo.id)}&${getPreselectedTabURLParam(ViewModeTab.INVALID)}`}
                            >
                                View
                            </Link>
                            )
                        </Typography>
                    );
                })()}
            </div>
        </div>
    );
}
