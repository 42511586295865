import {Divider, Typography} from '@genestack/ui';
import React from 'react';
// tslint:disable-next-line: no-implicit-dependencies
import {Transition, TransitionGroup} from 'react-transition-group';

import classNames from 'classnames';
import {animationDuration} from './constants';
import queryMPStyles from './query-management-panel.module.css';
import commonMPStyles from '../../../components/management-panel/management-panel.module.css';
import {RecentQuery} from './recent-query';
import {useMPRecentQueries} from './use-mp-recent-queries';

interface Props {
    recentQueriesRequest: ReturnType<typeof useMPRecentQueries>;
}

export function RecentQueries(props: Props) {
    const {data, isLoading} = props.recentQueriesRequest;

    return (
        <>
            <div className={classNames(queryMPStyles.queryRow, commonMPStyles.tableRow)}>
                <Typography variant="section">Recently completed queries</Typography>

                <Typography intent="quiet" variant="body">
                    Author
                </Typography>

                <Typography intent="quiet" variant="body">
                    Calculation time / Found results / Status
                </Typography>

                <Typography intent="quiet" variant="body">
                    Completion timestamp
                </Typography>
            </div>

            <Divider gap={0} />

            {(function () {
                if (data?.length) {
                    return (
                        <TransitionGroup>
                            {data.map((query) => (
                                <Transition
                                    key={query.id}
                                    timeout={{appear: animationDuration, enter: animationDuration}}
                                >
                                    {(transitionStatus) => (
                                        <RecentQuery
                                            query={query}
                                            isHighlighted={transitionStatus === 'entering'}
                                        />
                                    )}
                                </Transition>
                            ))}
                        </TransitionGroup>
                    );
                }

                if (isLoading) {
                    return null;
                }

                return (
                    <div className={commonMPStyles.emptyTable}>
                        <Typography intent="quiet">No recent queries found</Typography>
                    </div>
                );
            })()}
        </>
    );
}
