import React from 'react';
import {GraphLayout} from '../../../interface';
import {HiddenNodesActionsProps} from './interface';
import {useHideNodes} from './use-hide-nodes';
import {useUnhideNodes} from './use-unhide-nodes';
import {useBuildPaths} from './use-build-path';

export function useHiddenNodesActions(props: HiddenNodesActionsProps) {
    const {
        hiddenNodes,
        setHiddenNodes,
        selectedNodes,
        structuredGraph,
        isHiddenNodesVisible,
        visibleNodesPositions,
        invisibleNodesPositions,
        setVisibleNodesPositions,
        setInvisibleNodesPositions,
        toggleIsHiddenNodesVisible
    } = props;

    // visible and invisible nodes positions are stored separately in two different structures,
    // so when node(s) become visible or invisible, we should move the coordinates from one
    // structure to the other
    const moveNodePosBtwVisibleInvisible = React.useCallback(
        (newVisibleNodes: number[], newInvisibleNodes: number[]) => {
            const newInvisibleNodesSet = new Set(newInvisibleNodes);
            const newVisibleNodesSet = new Set(newVisibleNodes);
            const newVisibleNodesPositions: GraphLayout = {};
            const newInvisibleNodesPositions: GraphLayout = {};
            if (visibleNodesPositions) {
                Object.keys(visibleNodesPositions).forEach((nodeId) => {
                    if (newInvisibleNodesSet.has(Number(nodeId))) {
                        newInvisibleNodesPositions[nodeId] = visibleNodesPositions[nodeId];
                    } else {
                        newVisibleNodesPositions[nodeId] = visibleNodesPositions[nodeId];
                    }
                });
            }

            if (invisibleNodesPositions) {
                Object.keys(invisibleNodesPositions).forEach((nodeId) => {
                    if (newVisibleNodesSet.has(Number(nodeId))) {
                        newVisibleNodesPositions[nodeId] = invisibleNodesPositions[nodeId];
                    } else {
                        newInvisibleNodesPositions[nodeId] = invisibleNodesPositions[nodeId];
                    }
                });
            }

            setVisibleNodesPositions(JSON.stringify(newVisibleNodesPositions));
            setInvisibleNodesPositions(JSON.stringify(newInvisibleNodesPositions));
        },
        [
            visibleNodesPositions,
            invisibleNodesPositions,
            setVisibleNodesPositions,
            setInvisibleNodesPositions
        ]
    );

    // sets new hidden nodes and moves positions btw visible and invisible structures
    const setHiddenNodesAndMovePositions = React.useCallback(
        (newHiddenNodes: number[]) => {
            setHiddenNodes(newHiddenNodes);
            if (!isHiddenNodesVisible) {
                const hiddenNodesSet = new Set(hiddenNodes);
                const newHiddenNodesSet = new Set(newHiddenNodes);
                const newInvisibleNodes = newHiddenNodes.filter(
                    (nodeId) => !hiddenNodesSet.has(nodeId)
                );
                const newVisibleNodes = hiddenNodes.filter(
                    (nodeId) => !newHiddenNodesSet.has(nodeId)
                );
                moveNodePosBtwVisibleInvisible(newVisibleNodes, newInvisibleNodes);
            }
        },
        [setHiddenNodes, hiddenNodes, isHiddenNodesVisible, moveNodePosBtwVisibleInvisible]
    );

    const [hiddenSelectedNodes, nonHiddenSelectedNodes] = React.useMemo(() => {
        const hiddenNodesSet = new Set([...hiddenNodes]);
        return [
            selectedNodes.filter((nodeId) => hiddenNodesSet.has(nodeId)),
            selectedNodes.filter((nodeId) => !hiddenNodesSet.has(nodeId))
        ];
    }, [selectedNodes, hiddenNodes]);

    const hideNodesProps = useHideNodes({
        ...props,
        setHiddenNodesAndMovePositions
    });

    const unhideNodesProps = useUnhideNodes({
        ...props,
        setHiddenNodesAndMovePositions,
        hiddenSelectedNodes
    });

    const buildPathProps = useBuildPaths({
        ...props,
        setHiddenNodesAndMovePositions,
        nonHiddenSelectedNodes
    });

    return {
        hideNodesProps,
        unhideNodesProps,
        buildPathProps,
        hasHiddenNodes: hiddenNodes.length > 0,
        isAllNodesHidden: hiddenNodes.length === structuredGraph?.allNodes.length,
        hiddenSelectedNodes,
        nonHiddenSelectedNodes,
        isHiddenNodesVisible,
        toggleIsHiddenNodesVisible: React.useCallback(() => {
            const isHiddenNodesVisibleNew = !isHiddenNodesVisible;
            if (!isHiddenNodesVisibleNew) {
                moveNodePosBtwVisibleInvisible([], hiddenNodes);
            } else {
                moveNodePosBtwVisibleInvisible(hiddenNodes, []);
            }
            toggleIsHiddenNodesVisible();
        }, [
            toggleIsHiddenNodesVisible,
            moveNodePosBtwVisibleInvisible,
            hiddenNodes,
            isHiddenNodesVisible
        ])
    };
}
